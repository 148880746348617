import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
///--- Queries & Actions
import { getUserForEdit, getStripeSubscription } from "api/user.js";
//--- Components
import DataLoader from "components/DataLoader.js";
import CancelledPage from "components/CancelledPage";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default withRouter(
  connect(mapStateToProps)(function Cancelled(props) {
    return (
      <PageWrapper>
        <DataLoader
          load={async () => {
            try {
              const user = await getUserForEdit(props.user.user_id);
              const stripeUser = await getStripeSubscription(
                props.user.user_id
              );
              return { user, stripeUser: { ...stripeUser.stripeUser } };
            } catch (e) {
              const res = await e.response.json();
              console.warn("errors", res); // { errors; [ ... ] }
            }
          }}
          fallback={() => {
            return <Loading />;
          }}
        >
          {(props) => {
            const user = props.data ? props.data.user : {};
            const stripeUser = props.data ? props.data.stripeUser : {};
            return (
              <CancelledPage user={user} stripeUser={stripeUser} {...props} />
            );
          }}
        </DataLoader>
      </PageWrapper>
    );
  })
);

function mapStateToProps(state) {
  return {
    ...state,
  };
}
