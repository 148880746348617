import React from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
//--- Queries & Actions
import { createHostUser } from "api/auth";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

export default function AddHost({ modalOpen, setLoading }) {
  return (
    <div className={cx(styles.event__modal, styles.event__modal__active)}>
      <div className={styles.event__modal__body__wrapper}>
        <div
          className={styles.event__modal__body}
          style={{ maxWidth: "450px" }}
        >
          <div
            className={styles.event__modal__close}
            onClick={() => modalOpen(false)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>Add Host</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
              }}
              onSubmit={async (values, actions) => {
                const { first_name, last_name, email } = values;
                setLoading(true);
                try {
                  const result = await createHostUser({
                    first_name,
                    last_name,
                    email,
                    password: `vP7H?hZLJ7Tk23`,
                    community: ``,
                  });
                  if (result) {
                    setLoading(false);
                    modalOpen(false);
                    return;
                  }

                  // window.location = `/event/${eventId}`;
                } catch (e) {
                  const res = await e.response.json();
                  setLoading(false);
                  actions.setSubmitting(false);
                }
              }}
              render={({ isSubmitting }) => (
                <Form>
                  <Input
                    label="First Name"
                    name="first_name"
                    placeholder="First Name"
                  />
                  <Input
                    label="Last Name"
                    name="last_name"
                    placeholder="Last Name"
                  />
                  <Input
                    label="Email Address"
                    name="email"
                    placeholder="Email Address"
                    type="email"
                  />
                  <FormButton label="Submit" disabled={isSubmitting} />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
