import React, { memo, useState } from "react";
import cx from "classnames";
// Styles
import styles from "components/Membership/Membership.module.scss";
// Utils
import parseSanityImage from "utils/parseSanityImage";

const MembershipQuotes = memo(function ({ quotes = [] }) {
  const [activeQuote, setActiveQuote] = useState(1);

  return (
    <div className={styles.membership__quotes}>
      <div className={styles.membership__quotes__wrapper}>
        <div className={styles.membership__quotes__section}>
          {quotes.map((quoteText, i) => {
            return (
              <div
                className={cx(styles.membership__quotes__quote, {
                  [styles.membership__quotes__quote__active]: activeQuote === i,
                })}
                key={i}
              >
                <svg
                  className={styles.membership__quotes__quote__quoteTop}
                  width="55"
                  height="49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M54.8 12.5c-3.6 1.2-7 2.533-10.2 4-3.133 1.467-5.867 3.133-8.2 5-2.267 1.8-4.1 3.767-5.5 5.9-1.333 2.133-2 4.433-2 6.9.667-3.2 2.067-5.867 4.2-8s5.067-3.2 8.8-3.2c4 0 7.1 1.3 9.3 3.9 2.2 2.533 3.3 5.467 3.3 8.8 0 3.8-1.2 6.9-3.6 9.3-2.4 2.4-5.5 3.6-9.3 3.6-4.267 0-7.6-1.333-10-4-2.333-2.667-3.5-6.2-3.5-10.6 0-5.8 1.1-11.567 3.3-17.3C33.6 11.067 37.3 5.633 42.5.5l12.3 12zm-27.5 0c-3.6 1.2-7 2.533-10.2 4-3.133 1.467-5.867 3.133-8.2 5-2.267 1.8-4.1 3.767-5.5 5.9-1.333 2.133-2 4.433-2 6.9.667-3.2 2.067-5.867 4.2-8s5.067-3.2 8.8-3.2c4 0 7.1 1.3 9.3 3.9 2.2 2.533 3.3 5.467 3.3 8.8 0 3.8-1.2 6.9-3.6 9.3-2.4 2.4-5.5 3.6-9.3 3.6-4.267 0-7.6-1.333-10-4C1.767 42.033.6 38.5.6 34.1c0-5.8 1.1-11.567 3.3-17.3C6.1 11.067 9.8 5.633 15 .5l12.3 12z"
                    fill="#DD5D39"
                  />
                </svg>
                <div className={styles.membership__quotes__quote__text}>
                  {quoteText.quote}
                </div>
                <svg
                  className={styles.membership__quotes__quote__quoteBottom}
                  width="55"
                  height="49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M54.8 12.5c-3.6 1.2-7 2.533-10.2 4-3.133 1.467-5.867 3.133-8.2 5-2.267 1.8-4.1 3.767-5.5 5.9-1.333 2.133-2 4.433-2 6.9.667-3.2 2.067-5.867 4.2-8s5.067-3.2 8.8-3.2c4 0 7.1 1.3 9.3 3.9 2.2 2.533 3.3 5.467 3.3 8.8 0 3.8-1.2 6.9-3.6 9.3-2.4 2.4-5.5 3.6-9.3 3.6-4.267 0-7.6-1.333-10-4-2.333-2.667-3.5-6.2-3.5-10.6 0-5.8 1.1-11.567 3.3-17.3C33.6 11.067 37.3 5.633 42.5.5l12.3 12zm-27.5 0c-3.6 1.2-7 2.533-10.2 4-3.133 1.467-5.867 3.133-8.2 5-2.267 1.8-4.1 3.767-5.5 5.9-1.333 2.133-2 4.433-2 6.9.667-3.2 2.067-5.867 4.2-8s5.067-3.2 8.8-3.2c4 0 7.1 1.3 9.3 3.9 2.2 2.533 3.3 5.467 3.3 8.8 0 3.8-1.2 6.9-3.6 9.3-2.4 2.4-5.5 3.6-9.3 3.6-4.267 0-7.6-1.333-10-4C1.767 42.033.6 38.5.6 34.1c0-5.8 1.1-11.567 3.3-17.3C6.1 11.067 9.8 5.633 15 .5l12.3 12z"
                    fill="#DD5D39"
                  />
                </svg>
              </div>
            );
          })}
        </div>
        <div className={styles.membership__quotes__toggle}>
          {quotes.map((quoteImage, index) => {
            let attributionImageUrl = parseSanityImage(
              quoteImage.quote_image,
              true
            )
              .width(150)
              .url();
            return (
              <div
                className={cx(styles.membership__quotes__toggle__item, {
                  [styles.membership__quotes__toggle__item__active]:
                    activeQuote === index,
                })}
                key={index}
                onClick={() => setActiveQuote(index)}
              >
                <div
                  className={cx(styles.membership__quotes__image, {
                    [styles.membership__quotes__image__active]:
                      activeQuote === index,
                  })}
                  style={{ backgroundImage: `url('${attributionImageUrl}')` }}
                />

                <p>{quoteImage.quote_attribution}</p>
                <p>{quoteImage.quote_attribution_location}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default MembershipQuotes;
