import React from "react";
import cx from "classnames";

import styles from "components/Shared/ErrorBanner/ErrorBanner.module.scss";

function ErrorBanner({ message, notAnError }) {
  return (
    <div
      className={cx(styles.errorBanner, {
        [styles.errorBanner__notError]: notAnError,
      })}
    >
      {message}
    </div>
  );
}

export default ErrorBanner;
