import React from "react";
import { connect } from "react-redux";
//--- Queries
import { getDashboardData } from "api/cms.js";
import { getStripeSubscription, updateUser } from "api/user.js";
import get from "utils/get";
//--- Components
import Dashboard from "components/Dashboard";
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

function DashboardWrapper({ user = {}, history }) {
  return (
    <PageWrapper headerColor="#F9F3EB" isMulti={true}>
      <DataLoader
        cache="dashboard"
        load={async () => {
          try {
            // get userId
            let currentUser = user._id ? user._id : user.user_id;
            // get pageData for dashboard
            const pageData = await getDashboardData(
              currentUser,
              user.community
            );

            // //--------------//--------------//--------------//--------------//--------------//
            // // Check subscription status from Stripe
            // const { stripeUser } = await getStripeSubscription(currentUser);
            // // Update value in Sanity if their sanity status is different than their stripe status
            // if (stripeUser && stripeUser.delinquent) {
            //   // set user status to delinquent
            //   const result = await updateUser(currentUser, {
            //     subscription_status: "delinquent",
            //   });
            //   await history.push({
            //     pathname: `/update-membership`,
            //   });
            // } else if (
            //   stripeUser &&
            //   stripeUser.subscriptions &&
            //   stripeUser.subscriptions.total_count === 0
            // ) {
            //   // set user status to cancelled
            //   const result = await updateUser(currentUser, {
            //     subscription_status: "cancelled",
            //   });
            //   await history.push({
            //     pathname: `/update-membership`,
            //   });
            //   // } else if (user.subscription_status !== "active") {
            //   //   // set user status to active
            //   //   const result = await updateUser(currentUser, {
            //   //     subscription_status: "active",
            //   //   });
            // }
            //--------------//--------------//--------------//--------------//--------------//
            // TODO: Set marked delinquent in redux store?
            // if (
            //   sanityUser &&
            //   sanityUser[0] &&
            //   sanityUser[0].subscription_status === "pending"
            // ) {
            //   await props.history.push({ pathname: `/signup` });
            // } else if (
            // Check if user is cancelled or delinquent and forward accordingly
            //   user.subscription_status === "cancelled" ||
            //   user.subscription_status === "delinquent"
            // ) {
            //   await props.history.push({
            //     pathname: `/update-membership`,
            //   });
            // }
            return { pageData };
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {({ data = {} }) => {
          const pageData = data.pageData ? data.pageData[0] : {};
          return (
            <Dashboard
              events={pageData.dashEvents}
              cmsData={pageData}
              user_id={user._id ? user._id : user.user_id}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(DashboardWrapper);
