import React, { useState } from "react";
import { cancelEvent, unpublishEvent } from "api/event";

import styles from "components/Events/Events.module.scss";

import CancelConfirm from "components/HostEvents/CancelConfirm";
import UnpublishConfirm from "components/HostEvents/UnpublishConfirm";

function HostEditBar({
  eventId,
  setLoading,
  setErrorMessage,
  canUnpublish = false,
}) {
  const [cancelModal, toggleCancelModel] = useState("");
  const [unpublishModal, toggleUnpublishModel] = useState("");

  async function cancelEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await cancelEvent({ event_id });
      toggleCancelModel("");
      setLoading(false);
      window.location = `/event/${event_id}`;
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      setErrorMessage("Error: " + (res.errors[0] && res.errors[0].details));
    }
  }

  async function unpublishEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await unpublishEvent({ event_id });
      toggleUnpublishModel("");
      setLoading(false);
      window.location = `/event/${event_id}`;
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      setErrorMessage("Error: " + (res.errors[0] && res.errors[0].details));
    }
  }

  return (
    <div className={styles.event__edit}>
      {cancelModal !== "" && (
        <CancelConfirm
          cancelEvent={cancelEventFunc}
          closeModal={toggleCancelModel}
          eventId={eventId}
        />
      )}
      {unpublishModal !== "" && (
        <UnpublishConfirm
          unpublishEvent={unpublishEventFunc}
          closeModal={toggleUnpublishModel}
          eventId={eventId}
        />
      )}
      <p>You're hosting this gathering!</p>
      <p>
        <a href={`/edit-event/${eventId}`}>Edit</a>,&nbsp;
        <span onClick={() => toggleCancelModel("open")}>Cancel</span>
        {canUnpublish && `, or `}
        {canUnpublish && (
          <span onClick={() => toggleUnpublishModel("open")}>Unpublish</span>
        )}
      </p>
    </div>
  );
}

export default HostEditBar;
