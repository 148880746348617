export default function userReducer (state = {}, action) {
  const { type, ...user } = action;

  switch (type) {
    case 'SET_CURRENT_USER':
      return user;
    case 'LOGOUT_USER':
      return {};
    case 'UPDATE_INFO':
      return user;
    default:
      return state;
  }
}
