import React, { memo } from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

import Button from "components/Button";

const PicksItem = ({ pick }) => {
  let description = pick.description.substring(0, 90);
  description = description.substring(
    0,
    Math.min(description.length, description.lastIndexOf(" "))
  );
  return (
    <div className={styles.dashboard__picks__item}>
      <div className={styles.dashboard__picks__item__image}>
        <img src={pick.eventImage} alt={pick.name} />
        <div className={styles.dashboard__picks__item__image__icon}>
          {pick.type === "Book club" && (
            <img src="/icons/bookclub-icon-peach.svg" alt="book club" />
          )}
          {pick.type === "Cookbook club" && (
            <img src="/icons/cookbook-icon-peach.svg" alt="cookbook club" />
          )}
          {pick.type !== "Book club" && pick.type !== "Cookbook club" && (
            <img src="/icons/othergathering-icon-peach.svg" alt="couch" />
          )}
        </div>
      </div>
      <div className={styles.dashboard__picks__item__info}>
        <h3>{pick.type}</h3>
        <h4>{pick.name}</h4>
        <p
          className={styles.dashboard__picks__item__info__host}
        >{`${pick.host.first_name} ${pick.host.last_name}`}</p>
        <p className={styles.dashboard__picks__item__info__description}>
          {description}...
        </p>
        <p className={styles.dashboard__picks__item__info__readmore}>
          <a href={`event/${pick._id}`}>Read More</a>
        </p>
      </div>
    </div>
  );
};

const Picks = ({
  buttonLink = "#",
  buttonText = "See all gatherings",
  items = [],
  text = "Join us for gatherings about this month’s selections!",
  title = "This Month’s Picks",
}) => {
  return (
    <section className={styles.dashboard__picks}>
      <div className={styles.dashboard__picks__wrapper}>
        <div className={styles.dashboard__picks__header}>
          <h2>{title}</h2>
          <p>{text}</p>
          <img
            src="/icons/Books_colors.png"
            alt="Pile of books"
            className={styles.dashboard__picks__header__icon}
          />
        </div>
        <div className={styles.dashboard__picks__items}>
          {items.map((pick, i) => (
            <PicksItem key={i} pick={pick} />
          ))}
        </div>
        <div className={styles.dashboard__picks__button}>
          <Button
            label={buttonText}
            link={buttonLink}
            additionalClass="button__transparent"
          />
        </div>
      </div>
    </section>
  );
};

export default memo(Picks);
