import React, { useEffect, useState } from "react";
//--- Utils
import parseDate from "utils/parseDate";
//--- Styles
import styles from "components/Article/Articles.module.scss";

export default function ArticleHeader({
  title = "",
  createdAt,
  author,
  type = "",
  image,
  preview = "",
}) {
  const [date, setDate] = useState("");

  useEffect(() => {
    let morphedDate = dateMorph(createdAt);
    setDate(morphedDate);
  }, [createdAt]);

  function dateMorph(date) {
    let formattedDate = parseDate(date);
    return `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year} `;
  }

  return (
    <div className={styles.article__header}>
      <div className={styles.article__header__wrapper}>
        <div className={styles.article__header__image}>
          {image && <img src={image + "?w=570&fix=max"} alt={title} />}
        </div>
        <div className={styles.article__header__text}>
          <h1>{type}</h1>
          <h2>{title}</h2>
          {author && (
            <p className={styles.article__header__meta}>
              By {author.first_name} {author.last_name} • {date}
            </p>
          )}
          <p className={styles.article__header__preview}>{preview}</p>
        </div>
      </div>
    </div>
  );
}
