import React, { useEffect } from "react";
import { connect } from "react-redux";
//--- Queries & Actions
import { getUser } from "api/user.js";
import { setCurrentUser } from "state/actions/userActions.js";
// import { initLoading, stopLoading } from "state/actions/loadingActions.js";
//--- Components
import Footer from "components/Footer";
import Header from "components/Header";
import MembershipHeader from "components/Header/MembershipHeader";
import SimpleHeader from "components/Header/SimpleHeader";

function PageWrapper({
  children,
  dispatch,
  headerColor = "#284C4A",
  isAuthenticated,
  isMembership = false,
  isMulti = false,
  isPublicPage,
  loading,
  location,
  progressNav,
  user,
}) {
  async function updateUserState(userState, user_id) {
    try {
      const userQuery = await getUser(user_id);
      return dispatch(
        setCurrentUser({
          ...userState,
          ...userQuery[0],
        })
      );
    } catch (e) {
      const res = await e.response.json();
      console.warn("ERROR: User update errors", res);
      return res;
    }
  }

  useEffect(() => {
    if (!user.first_name && user.user_id) {
      updateUserState(user, user.user_id);
    }
  }, []);
  return (
    <main>
      {(isPublicPage || isMembership) && (
        <MembershipHeader
          headerColor={headerColor}
          isAuthenticated={isAuthenticated}
          location={location}
        />
      )}
      {!progressNav && !isPublicPage && !loading && (
        <Header
          headerColor={headerColor}
          isMulti={isMulti}
          location={location}
        />
      )}
      {progressNav && !loading && <SimpleHeader />}
      {children}
      {!progressNav && !loading && (
        <Footer isLoggedIn={user.user_id ? true : false} />
      )}
    </main>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(PageWrapper);
