export function pageParams(pageNumber, perPage) {
  //--- Convert page number and per page to numbers for math
  let parsedPageNumber = parseInt(pageNumber);
  if (isNaN(parsedPageNumber)) parsedPageNumber = 1;
  let parsedPerPage = parseInt(perPage);
  if (isNaN(parsedPerPage)) parsedPerPage = 20;
  //--- Allow for default query for first page to get around multiplying 0
  if (parsedPageNumber === 1 || parsedPageNumber === 0) {
    return `0...${parsedPerPage}`;
  }

  const startingPageNumber = (parsedPageNumber - 1) * parsedPerPage;
  const endingPageNumber = parsedPageNumber * parsedPerPage;
  return `${startingPageNumber}...${endingPageNumber}`;
}

export function cityParams(cityAbbr) {
  //--- Returns the city name itself for the community query
  switch (cityAbbr) {
    case "all":
      return "All Cities";
    case "ATL":
      return "Atlanta";
    case "AUS":
      return "Austin";
    case "BOS":
      return "Boston";
    case "CHI":
      return "Chicago";
    case "LA":
      return "Los Angeles";
    case "NYC":
      return "New York City";
    case "PHI":
      return "Philadelphia";
    case "SF":
      return "San Francisco";
    case "TOR":
      return "Toronto";
    case "DC":
      return "Washington D.C.";
    default:
      return "All Cities";
  }
}

export function eventTypeParams(eventType) {
  //--- Accepts either "all", "bookclub", or "cookbookclub"
  //--- Defaults in the code to "all"
  switch (eventType) {
    case "all":
      return "All Gatherings";
    case "bookclub":
      return "Book club";
    case "cookbookclub":
      return "Cookbook club";
    default:
      return "All Gatherings";
  }
}

export function eventRangeParams(eventRange) {
  //--- Accepts either "upcoming" or "past"
  //--- Defaults in the code to "upcoming"
  if (eventRange) {
    return eventRange;
  } else {
    return "upcoming";
  }
}
