import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";

import styles from "components/Members/Members.module.scss";
// Taking styles from other carousel integration
import eventsStyles from "components/Events/Events.module.scss";

import MembersGroupCard from "components/Shared/MembersGroupCard";

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={`${eventsStyles.events__attending__button} ${eventsStyles.events__attending__button__prev}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
      <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
    </svg>
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={`${eventsStyles.events__attending__button} ${eventsStyles.events__attending__button__next}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
      <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
    </svg>
  </button>
);

export default function SuggestedUsers({ suggestedUsers, suggestedValues }) {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const emblaOptions = {
    align: "start",
    draggable: true,
    loop: false,
    slidesToScroll: 1,
    // containScroll: "trimSnaps",
  };
  const [emblaRef, embla] = useEmblaCarousel(emblaOptions);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const { bringingToTable, city, industry, neighborhood } = useMemo(() => {
    return {
      bringingToTable: suggestedValues.bringingToTable || null,
      city: suggestedValues.city || null,
      industry: suggestedValues.industry || null,
      neighborhood: suggestedValues.neighborhood || null,
    };
  }, [suggestedValues]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla]);

  return (
    <section className={styles.members__suggested}>
      <div className={eventsStyles.events__attending__wrapper} ref={emblaRef}>
        <div className={eventsStyles.events__attending__items}>
          {/* Industry */}
          {industry && (
            <MembersGroupCard
              text={"Works in " + industry.toLowerCase()}
              link={`/members?industry=${encodeURIComponent(industry)}`}
              count={suggestedUsers.suggested_industry_count}
            />
          )}
          {/* Neighborhood */}
          {neighborhood && (
            <MembersGroupCard
              text="Lives in your neighborhood"
              link={`/members?neighborhoods=${encodeURIComponent(
                neighborhood
              )}`}
              count={suggestedUsers.suggested_neighborhood}
            />
          )}
          {/* Bringing To Table */}
          {bringingToTable && bringingToTable.length > 0 && (
            <MembersGroupCard
              text={"Also here for " + bringingToTable[0].toLowerCase()}
              link={`/members?membership_offering=${encodeURIComponent(
                bringingToTable[0]
              )}`}
              count={suggestedUsers.suggested_bringing}
            />
          )}
          {/* City */}
          {city && (
            <MembersGroupCard
              text={"Lives in  " + city}
              link={`/members?community=${city}`}
              count={suggestedUsers.suggested_city}
            />
          )}
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </section>
  );
}
