import React, { useMemo } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
import { eventRSVP } from "api/event";

import styles from "components/Events/Events.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

function RSVPModal({
  openRSVPModal,
  toggleRSVPModal,
  eventData,
  toggleCongratsModal,
}) {
  const { host = {} } = useMemo(() => {
    const host = eventData.host || {};
    return {
      host,
    };
  }, [eventData]);

  return (
    <div
      className={cx(styles.event__modal, {
        [styles.event__modal__active]: openRSVPModal,
      })}
    >
      <div className={styles.event__modal__body__wrapper}>
        <div className={styles.event__modal__body}>
          <div
            className={styles.event__modal__close}
            onClick={() => toggleRSVPModal(false)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>{eventData.name}</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <div className={styles.event__modal__left}>
              <div
                className={styles.event__modal__left__image}
                style={{
                  backgroundImage: `url('${
                    host.hostImage
                      ? host.hostImage + "?w=150&h=150&fit=max"
                      : "/placeholder/placeholder-profile-image.png"
                  }')`,
                }}
              ></div>
              <h2>{host ? host.first_name : "The host"} wants to know...</h2>
            </div>
            <div className={styles.event__modal__right}>
              <Formik
                initialValues={{
                  attendee_answer: "",
                }}
                onSubmit={async (values, actions) => {
                  const { attendee_answer } = values;
                  try {
                    const result = await eventRSVP({
                      event_id: eventData._id,
                      attendee_answer,
                    });
                    toggleRSVPModal(false);
                    actions.setSubmitting(false);
                    return toggleCongratsModal(true);
                  } catch (e) {
                    const res = await e.response.json();
                    console.warn("errors", res);
                    actions.setSubmitting(false);
                  }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                  <Form>
                    <section>
                      <h3>{eventData.question_1}</h3>
                      <p>Optional — Your answer will be shown to the host.</p>
                      <Input name="attendee_answer" type="text" />
                    </section>
                    <FormButton label="Submit RSVP" disabled={isSubmitting} />
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RSVPModal;
