import React, { useState } from "react";
//--- Queries
// import { cancelAccount } from 'api/user.js';
//--- Styles
import styles from "components/EditAccount/EditAccount.module.scss";
// import modalstyles from 'components/HostEvents/HostEvents.module.scss';

//--- Components
import Button from "components/Button";

// function CancelAccountConfirm({cancelAccount, closeModal }) {
//   return (
//     <div className={modalstyles.cancel}>
//       <div className={modalstyles.cancel__body}>
//         <div className={modalstyles.cancel__close} onClick={() => closeModal(false)}>
//           X
//         </div>
//         <div className={modalstyles.cancel__body__wrapper}>
//           <h3>Are you sure you want to cancel your RSVP?</h3>
//           <div className={modalstyles.cancel__buttons}>
//             <Button label="Yes, Cancel" onclick={cancelAccount} onclickvalue={eventId} />
//             <Button label="No, Nevermind" onclick={closeModal} onclickvalue={''} additionalClass="button__transparent" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function CancelAccount(props) {
  // const [cancelOpen, toggleCancelOpen] = useState(false);

  // function cancelAccount() {
  //   //--- PUT CANCEL FUNCTIONALITY HERE
  // }
  return (
    <div>
      {/*
        {cancelOpen && <CancelAccountConfirm cancelAccount={cancelAccount} closeModal={toggleCancelOpen} />}
      */}
      <h3>Cancel Your Account</h3>
      <p>
        If you'd like to cancel your account, please email{" "}
        <a href="mailto:lounge@girlsnightin.co">lounge@girlsnightin.co</a> for
        assistance.
      </p>
      {/* <Button label="Cancel Account" onclick={toggleCancelOpen} onclickvalue={true} /> */}
    </div>
  );
}

export default CancelAccount;
