import React from "react";
import PropTypes from "prop-types";
// Styles
import styles from "components/Button/Button.module.scss";

function FilterButton({
  additionalClass,
  disabled,
  label,
  lightBackground,
  onclick,
  onclickvalue,
}) {
  return (
    <div
      className={`
        ${styles.buttonFilter}
        ${additionalClass && styles[additionalClass]}
        ${lightBackground && styles.buttonFilter__lightBg}
      `}
    >
      <button onClick={() => onclick(onclickvalue)} disabled={disabled}>
        {label}
        <svg
          width="16"
          height="9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.42 1.5l6.5 6 6.5-6" strokeWidth="2" />
        </svg>
      </button>
    </div>
  );
}

FilterButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.string,
  additionalClass: PropTypes.string,
  onclick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FilterButton;
