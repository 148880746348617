import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
//--- Queries & Actions
import { getAllUsers, getSuggestedUsers, getUser } from "api/user.js";
import { pageParams } from "utils/parseQueryParams";
import createBaseUrl from "utils/createBaseUrl";
//--- Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import Members from "components/Members";
import PageWrapper from "components/PageWrapper";

const perPageValue = "15";

function MembersContainer({ location, user }) {
  //--- Get Query Params
  const urlParams = new URLSearchParams(location.search);
  //--- Pagination
  const pageParam = urlParams.get("page") ? urlParams.get("page") : "1";
  //--- Available Params for Filtering
  const sortingParam = urlParams.get("sort_by");

  const page = pageParams(pageParam, perPageValue);
  const filterObj = {};

  urlParams.delete("page");
  urlParams.delete("sort_by");

  urlParams.forEach((param, index) => {
    filterObj[index] = param.split("+");
  });

  const baseUrl = createBaseUrl("/members", filterObj, [
    "community",
    "industry",
    "membership_offering",
    "user_roles",
    "neighborhoods",
  ]);

  /* Pre-sorted Membership Groups
  1. Same industry
  2. Same neighborhood
  3. Attended same events as you
  4. Bringing to the table
  5. Same city
  */
  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          let userData = await getUser(user.user_id);
          userData = userData ? userData[0] : {};
          const users = await getAllUsers(page, filterObj, sortingParam, {
            industry: userData.industry,
            neighborhood: userData.neighborhoods
              ? userData.neighborhoods[0]
              : null,
            events: userData.eventRSVPS ? userData.eventRSVPS : [],
            bringingToTable: userData.membership_offering,
            city: userData.community,
          });
          const suggestedValues = {
            industry: userData.industry,
            neighborhood: userData.neighborhoods
              ? userData.neighborhoods[0]
              : null,
            events: userData.eventRSVPS ? userData.eventRSVPS : [],
            bringingToTable: userData.membership_offering,
            city: userData.community,
          };
          const suggestedUsers = await getSuggestedUsers(suggestedValues);

          return { users, suggestedUsers, suggestedValues };
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {({ data }) => {
          return (
            <Members
              baseUrl={baseUrl}
              currentPage={pageParam}
              data={data.users || {}}
              isFiltering={filterObj !== {}}
              perPage={perPageValue}
              sortingParam={sortingParam}
              suggestedUsers={data.suggestedUsers || {}}
              suggestedValues={data.suggestedValues || {}}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(withRouter(MembersContainer));
