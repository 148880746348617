import ky from "ky";

//--- Event Queries
export async function getEvents(page = "0...8", filterObj) {
  const eventRange = filterObj.eventRange;
  // type: Books, Crafts
  // venue_type
  // community
  // event

  const filterObjToQueryString = (obj) => {
    let filterTypes = Object.keys(obj);
    filterTypes = filterTypes.filter((param) => param !== "eventRange");
    if (filterTypes.length === 0) return "";
    let output = "&& (";

    for (let i = 0; i < filterTypes.length; i++) {
      if (filterTypes[i] === "venue_type") {
        let filterVal = obj[filterTypes[i]][0];
        if (filterVal === "inperson") {
          if (i > 0) output += " &&";
          output += `venue_type != "Virtual"`;
        } else if (filterVal && filterVal !== "") {
          if (i > 0) output += " &&";
          output += `venue_type == "${filterVal}"`;
        }
      } else if (filterTypes[i] === "type") {
        let typeFilteringArray = "[";
        for (let j = 0; j < obj[filterTypes[i]].length; j++) {
          typeFilteringArray =
            typeFilteringArray + `"${obj[filterTypes[i]][j]}", `;
        }
        if (i > 0) output += "&&";
        output += ` type in ${typeFilteringArray}]`;
      } else if (filterTypes[i] === "community") {
        let communityFilteringArray = "[";
        for (let j = 0; j < obj[filterTypes[i]].length; j++) {
          communityFilteringArray =
            communityFilteringArray + `"${obj[filterTypes[i]][j]}", `;
        }
        if (i > 0) output += "&&";
        output += ` community in ${communityFilteringArray}]`;
      }
    }
    output += ")";
    return output;
  };

  const currentDate = new Date().toISOString();
  const dateQueryString =
    eventRange && eventRange[0] === "Past"
      ? `&& start_date < '${currentDate}'`
      : `&& start_date > '${currentDate}'`;
  const defaultOrder =
    eventRange && eventRange[0] === "Past"
      ? "start_date desc"
      : "start_date asc";

  const filterString = filterObjToQueryString(filterObj);

  const events = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `
          *[_type == "event" && published == true && cancelled != true ${filterString} ${dateQueryString}] | order(${defaultOrder}) {
            _id,
            attendees[]{
              attendee->{
                _id,
                "attendeeImage": avatar.asset->url,
                first_name,
                last_name,
                tagline
              },
              attendee_answer
            },
            attendees_limit,
            cancelled,
            community,
            description,
            end_date,
            "eventCount": count(*[_type == 'event' && published == true && cancelled != true ${filterString} ${dateQueryString}]),
            guest_limit,
            host->{
              "hostImage": avatar.asset->url,
              first_name
            },
            name,
            neighborhood,
            start_date,
            type,
            venue_type
          }[${page}]
        `,
      },
    })
    .json();
  return events;
}

export async function getEvent(event_id, user_id) {
  const event = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${event_id}"]{
          _id,
          address_1,
          address_2,
          amenities,
          attendees[]{
            attendee->{
              _id,
              "attendeeImage": avatar.asset->url,
              first_name,
              last_name,
              tagline
            },
            attendee_answer
          },
          attendees_limit,
          cancelled,
          "canInviteGuests": count(attendees[attendee->[of._ref match '${user_id}']]) < guest_limit,
          city,
          community,
          deleted,
          description,
          end_date,
          guest_limit,
          host->{
            _id,
            "hostImage": avatar.asset->url,
            first_name,
            last_name,
            tagline,
            community
          },
          "imageUrl": image.asset->url,
          name,
          neighborhood,
          notes,
          pets,
          published,
          question_1,
          start_date,
          state,
          type,
          url,
          venue_name,
          venue_type,
          waitlist,
          zip
        }`,
      },
    })
    .json();
  return event;
}

//--- Member Event Functions
export async function eventRSVP(fields) {
  const event = await ky
    .post("/.netlify/functions/eventRSVP", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function cancelRSVP(fields) {
  const event = await ky
    .post("/.netlify/functions/eventUnRSVP", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function hostCancelRSVP(fields) {
  const event = await ky
    .post("/.netlify/functions/eventHostUnRSVP", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function guestRSVP(fields) {
  const event = await ky
    .post("/.netlify/functions/eventGuestAdd", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

//--- Host Event Functions
export async function createEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventCreate", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function updateEventImage(event_id, asset_id) {
  const event = await ky
    .post("/.netlify/functions/eventUpdateImage", {
      json: {
        event_id,
        asset_id,
      },
    })
    .json();
  return event;
}

export async function updateEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventUpdate", {
      json: {
        event_id: fields.event_id.toString(),
        fields,
      },
    })
    .json();
  return event;
}

export async function cancelEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventCancel", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function deleteEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventDelete", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}

export async function publishEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventPublish", {
      json: {
        ...fields,
      },
    })
    .json();
  return event;
}

export async function unpublishEvent(fields) {
  const event = await ky
    .post("/.netlify/functions/eventUnpublish", {
      json: {
        ...fields,
      },
    })
    .json();

  return event;
}
