export default function cmsContentReducer (state = {}, action) {
  const { type, ...pageData } = action;

  switch (type) {
    case 'CMS_CREATE_ACCOUNT':
      return pageData;
    case 'CMS_ONBOARDING':
      return pageData;
    case 'CMS_EVENTS':
        return pageData;
    case 'CMS_PERKS':
      return pageData;
    default:
      return state;
  }
}
