import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import cx from "classnames";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import SelectBoxLarge from "components/FormElements/SelectBox/SelectBoxLarge";
import SideBanner from "components/Shared/SideBanner";

const EVENT_LOCATION = [
  {
    name: "At Home",
    text:
      "Hosting at home means cozy vibes, a guaranteed space, and an intimate setting.",
  },
  {
    name: "Public Space",
    text:
      "With a bit more planning, cafes, bookstores, restaurants, and other venues can be cozy.",
  },
  {
    name: "Virtual",
    text: "Gatherings that happen over the internet. Together, but apart!",
  },
];

function IRLURL(props) {
  const [eventLocation, selectEventLocation] = useState("");
  const cmsData = props.cmsData ? props.cmsData : {};
  useEffect(() => {
    props.event.eventLocation &&
      props.event.eventLocation !== "" &&
      selectEventLocation(props.event.eventLocation);
  }, []);

  return (
    <div className={styles.createEvent}>
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          cmsImage={cmsData ? cmsData.irlurl_side_image : null}
          title={`Hey ${props.user && props.user.first_name}!`}
          text={
            cmsData ? cmsData.irlurl_side_text : "Let's create your gathering"
          }
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                props.dispatch(
                  createEventAction({
                    ...props.event,
                    eventLocation,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, isSubmitting }) => (
                <Form>
                  <section>
                    <h2>{cmsData ? cmsData.irlurl_title : "IRL or URL"}</h2>
                    <p>
                      {cmsData
                        ? cmsData.irlurl_text
                        : "Where do you plan on holding you event?"}
                    </p>
                    <div
                      className={cx(
                        styles.createEvent__selectorCards,
                        styles.createEvent__selectorCards__irl
                      )}
                    >
                      {EVENT_LOCATION.map((type, i) => (
                        <div
                          className={styles.createEvent__selectorCards__item}
                          key={i}
                        >
                          <SelectBoxLarge
                            currentValue={eventLocation}
                            onclick={selectEventLocation}
                            text={type.text}
                            value={type.name}
                          />
                        </div>
                      ))}
                    </div>
                  </section>
                  <FormButton
                    label="Next up: The Topic"
                    disabled={isSubmitting || eventLocation === ""}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(IRLURL);
