import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";
import { updateUser } from "api/user.js";

import styles from "components/Onboarding/Onboarding.module.scss";

import FormButton from "components/Button/FormButton";
import SideBanner from "components/Shared/SideBanner";
import SelectBox from "components/FormElements/SelectBox";

const BringingItems = [
  "Self-care tips and tricks",
  "Mentorship",
  "Coffee/Activity buddy",
  "Hosting gatherings",
  "Creative collaboration",
  "Book conversation",
  "Cozy recs",
  "Event participation",
];

function BringToTable({
  cmsData = {},
  dispatch,
  user = {},
  updateFn,
  updateValue,
}) {
  const [bringingToTable, setBringing] = useState([]);
  const [forceUpdateValue, forceUpdate] = useState(1);

  useEffect(() => {
    if (user.bringingToTable) setBringing(user.bringingToTable);
  }, [user]);

  function bringingList(value) {
    let newBringing = bringingToTable;
    if (bringingToTable && bringingToTable.includes(value)) {
      let index = newBringing.indexOf(value);
      newBringing.splice(index, 1);
    } else {
      newBringing.push(value);
    }
    setBringing(newBringing);
    return forceUpdate(forceUpdateValue + 1);
  }

  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={cmsData.bringing_title}
          text={cmsData.bringing_subtitle}
          cmsImage={cmsData.bringing_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <div className={styles.profile__body__section}>
            <h3>{cmsData.bringing_input_title}</h3>
            <p>{cmsData.bringing_input_text}</p>
            <div className={styles.profile__body__section__rowTwo}>
              {BringingItems.map((item) => (
                <SelectBox
                  key={item}
                  value={item}
                  onclick={bringingList}
                  currentValue={bringingToTable.includes(item) ? item : ""}
                />
              ))}
            </div>
          </div>
          <Formik
            initialValues={{}}
            onSubmit={async (values, actions) => {
              const {
                address_1,
                address_2,
                bio,
                city,
                currently_reading,
                currently_watching,
                currently_listening,
                currently_instagram,
                currently_irl,
                industry,
                instagram,
                linkedin,
                membership_interests,
                membership_requests,
                pronouns,
                show_email,
                show_pronouns,
                sign,
                social_email,
                state,
                tagline,
                twitter,
                user_id,
                website,
                zip,
              } = user;
              //--- Update Sanity with Profile Information
              try {
                const result = await updateUser(user_id, {
                  address_1,
                  address_2,
                  bio,
                  city,
                  currently_reading,
                  currently_watching,
                  currently_listening,
                  currently_instagram,
                  currently_irl,
                  industry,
                  instagram,
                  linkedin,
                  membership_interests,
                  membership_offering: bringingToTable,
                  membership_requests,
                  onboarded: true,
                  pronouns,
                  show_email,
                  show_pronouns,
                  sign,
                  social_email,
                  state,
                  tagline,
                  twitter,
                  website,
                  zip,
                });
                if (result) {
                  await dispatch(updateInfo({ ...user, bringingToTable }));
                  return updateFn(updateValue);
                }
              } catch (e) {
                const res = await e.response.json();
                console.warn("errors", res);
                actions.setSubmitting(false);
              }
            }}
            initialValues={{}}
            render={({ isSubmitting }) => (
              <Form>
                <FormButton label="See Your Overview" disabled={isSubmitting} />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BringToTable);
