import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import merge from "lodash.merge";

import initialState from "state/initialState.js";
// import { localStateSaver, getInitialStateFromLocalStorage } from 'state/storeUtils/localStorageMiddleWare';
import {
  sessionStateSaver,
  getInitialStateFromSessionStorage,
} from "state/storeUtils/sessionStorageMiddleWare";

import user from "state/reducers/userReducer.js";
import loading from "state/reducers/loadingReducer.js";
import data from "state/reducers/dataReducer.js";
import event from "state/reducers/createEventReducer.js";
import cms from "state/reducers/cmsContentReducer.js";

//--- Local Storage is commented out for now in case we want to use it later
// const localSaveKeys = ['session'];
const sessionSaveKeys = ["user", "event", "cms"];

const middleware = [
  thunk,
  // localStateSaver(localSaveKeys),
  sessionStateSaver(sessionSaveKeys),
];

// const localStore = getInitialStateFromLocalStorage(localSaveKeys);
const sessionStore = getInitialStateFromSessionStorage(sessionSaveKeys);

const rootReducer = combineReducers({
  user,
  loading,
  data,
  event,
  cms,
});

const store = createStore(
  rootReducer,
  merge(
    initialState,
    // localStore,
    sessionStore
  ),
  composeWithDevTools(applyMiddleware(...middleware))
);

window.__store = store;

export default store;
