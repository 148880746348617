import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";

import { UpdateAccountAddressSchema } from "utils/validationSchema";
import { updateUser } from "api/user.js";

import styles from "components/EditAccount/EditAccount.module.scss";

import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import StateSelector from "components/FormElements/StateSelector";

function EditMailingAddress(props) {
  const [buttonText, setButtonText] = useState("Update Address");

  return (
    <Formik
      initialValues={{
        address_1: props.user.address_1 || "",
        address_2: props.user.address_2 || "",
        city: props.user.city || "",
        state: props.user.state || "",
        zip: props.user.zip || "",
      }}
      validationSchema={UpdateAccountAddressSchema}
      onSubmit={(values, actions) => {
        setButtonText("Updating...");
        updateUser(props.user.user_id, values)
          .then(async function onSuccess() {
            props.updateUserInfo(values);
            setButtonText("Updated!");

            setTimeout(() => {
              actions.setSubmitting(false);
              actions.resetForm();
              setButtonText("Update Again");
            }, 750);
          })
          .catch(async function (err) {
            console.error(err);
            actions.setSubmitting(false);
          });
      }}
      render={({ errors, isSubmitting, dirty }) => (
        <Form className={styles.form}>
          <section>
            <h3>Mailing Address</h3>
            <FormRow>
              <Input
                label="Street Address"
                name="address_1"
                placeholder="123 Main St"
                type="text"
              />
              <Input
                label="Apt / Unit"
                name="address_2"
                placeholder="Apt 102"
                type="text"
              />
            </FormRow>
            <Input
              label="City"
              name="city"
              placeholder="Washington D.C."
              type="text"
            />
            <FormRow>
              <StateSelector
                error={errors.state}
                label="State"
                name="state"
                type="text"
              />
              <Input
                label="Zip Code"
                name="zip"
                placeholder="11111"
                type="text"
              />
            </FormRow>
          </section>

          <FormButton label={buttonText} disabled={isSubmitting || !dirty} />
        </Form>
      )}
    />
  );
}

export default EditMailingAddress;
