import React from "react";
import cx from "classnames";

import styles from "components/Loading/Loading.module.scss";

function Spinner() {
  return (
    <div className={styles.loading}>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        style={{ display: "block" }}
      >
        <path
          fill="currentColor"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
}

export default class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ active: true });
    }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  render() {
    const { active } = this.state;

    return active ? (
      <div className={cx("loading")}>
        <Spinner />
      </div>
    ) : null;
  }
}
