import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { updateInfo } from "state/actions/userActions.js";
import { updateUserAvatar } from "api/user.js";
//--- Utils
import { onboardingBasic } from "utils/validationSchema";
//--- Styles
import styles from "components/Onboarding/Onboarding.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import Loading from "components/Loading";
import SideBanner from "components/Shared/SideBanner";
import SelectBox from "components/FormElements/SelectBox";
import Checkbox from "../FormElements/Checkbox";

function BasicInfo({
  cmsData = {},
  dispatch,
  updateFn,
  updateValue,
  user = {},
}) {
  const [pronouns, setPronouns] = useState("they/them");
  const [profileImageSource, setProfileImageSource] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log({ user });
  useEffect(() => {
    if (user.pronouns) setPronouns(user.pronouns);
    if (user.userImage) setProfileImagePreview(user.userImage);
  }, [user]);

  return (
    <div className={styles.profile}>
      {loading && <Loading />}
      <div className={styles.profile__wrapper}>
        <SideBanner
          setImageSource={setProfileImageSource}
          setImagePreview={setProfileImagePreview}
          imageSource={profileImageSource}
          imagePreview={profileImagePreview}
          setLoading={setLoading}
          text={cmsData.basic_subtitle}
          title={cmsData.basic_title}
        />
        <div className={styles.profile__body}>
          <Formik
            initialValues={{
              tagline: user.tagline || "",
              show_pronouns: user.show_pronouns || true,
            }}
            validationSchema={onboardingBasic}
            onSubmit={async (values, actions) => {
              const { tagline, show_pronouns } = values;
              try {
                dispatch(
                  updateInfo({
                    ...user,
                    tagline,
                    pronouns,
                    avatar: profileImageSource,
                    userImage: profileImagePreview,
                    show_pronouns,
                  })
                );

                if (profileImageSource) {
                  const result = await updateUserAvatar(
                    user.user_id,
                    profileImageSource
                  );
                }
                return updateFn(updateValue);
              } catch (e) {
                const res = await e.response.json();
                console.warn("errors", res);
                actions.setSubmitting(false);
              }
            }}
            render={({ errors, status, touched, isSubmitting }) => (
              <Form>
                <div className={styles.profile__body__section}>
                  <h3>{cmsData.basic_pronoun_title}</h3>
                  <div className={styles.profile__body__section__row}>
                    <SelectBox
                      value="they/them"
                      onclick={setPronouns}
                      currentValue={pronouns}
                    />
                    <SelectBox
                      value="she/her"
                      onclick={setPronouns}
                      currentValue={pronouns}
                    />
                    <SelectBox
                      value="he/him"
                      onclick={setPronouns}
                      currentValue={pronouns}
                    />
                  </div>
                  <Checkbox
                    name="show_pronouns"
                    label="Show pronouns on my profile (other members will see them)"
                  />
                </div>
                <div className={styles.profile__body__section}>
                  <h3>{cmsData.basic_tagline_title}</h3>
                  <p>{cmsData.basic_tagline_text}</p>
                  <Input
                    label=""
                    name="tagline"
                    placeholder="Ex. Flower power baker girl in DC."
                    type="text"
                  />
                </div>
                <FormButton
                  label="Next up: Snail Mail"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BasicInfo);
