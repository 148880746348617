import ky from "ky";

export async function getCMSData(pageId) {
  const page = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type == "${pageId}"]`,
      },
    })
    .json();
  return page;
}

export async function getEventsCMSData(pageId, user_id) {
  const currentDate = new Date().toISOString();
  const dateQueryString = `start_date > '${currentDate}'`;
  const page = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type == "${pageId}"] {
          ...,
          "eventRSVPS": *[_type=='event' && attendees[]._key == "${user_id}" && published == true && ${dateQueryString}] | order(start_date asc) {
            ...,
            "eventImage": image.asset->url,
            host->{
              "hostImage": avatar.asset->url,
              first_name
            },
          },
        }`,
      },
    })
    .json();
  return page;
}

export async function getPublicCMSData(pageId) {
  const page = await ky
    .post("/.netlify/functions/proxyPublic", {
      json: {
        query: `*[_type == "${pageId}"]`,
      },
    })
    .json();
  return page;
}

export async function getPerksData(pageId) {
  const page = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type in ["perksContent", "perks"]] {
          ...,
          "perkLogo": brand_logo.asset->url,
          "perkImage": perk_image.asset->url
        }`,
      },
    })
    .json();
  return page;
}

export async function getDashboardData(user_id) {
  const currentDate = new Date().toISOString();
  const dashEventsQuery = `&& published == true && start_date > '${currentDate}' && cancelled != true`;
  const page = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type in ["dashboardContent"]] {
          ...,
          "dashEvents": *[_type=='event' ${dashEventsQuery}]{
            ...,
            host->{
              _id,
              "hostImage": avatar.asset->url,
              first_name,
              last_name,
              tagline,
              community
            }
          }[0...2] | order(_createdAt asc),
          meetgreet_lounger->{
            "hostImage": avatar.asset->url,
            first_name,
            name,
            _id
          },
          "eventRSVPS": *[_type=='event' && attendees[]._key == "${user_id}" && published == true] | order(start_date asc) {
            _id
          },
          "picksEvents": picks_items[]-> {
            ...,
            "eventImage": image.asset->url,
            host->{
              name,
              first_name,
              last_name
            }
          },
          "latestItems": latest_items[]->{
            _id, 
            article_type, 
            article_title, 
            "articleImage": article_image.asset->url,
            announcement_title,
            "announcementImage": announcement_image.asset->url ,
            image
          },
        }`,
      },
    })
    .json();
  return page;
}
