import React, { useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
// Styles
import styles from "components/Shared/SideBanner/SideBanner.module.scss";
// Components
import EventImageUpload from "components/Shared/ImageUpload/EventImageUpload";
import ImageUpload from "components/Shared/ImageUpload";
// Utils
import parseSanityImage from "utils/parseSanityImage";

function SideBanner({
  cmsImage,
  cmsImageWidth = 600,
  dynamicText = false,
  title,
  text,
  image,
  setImageSource,
  imageSource,
  setImagePreview,
  imagePreview,
  setLoading,
  uploadOnly,
}) {
  const cmsImageBuild = useMemo(() => {
    if (cmsImage) {
      cmsImageBuild = parseSanityImage(cmsImage).width(cmsImageWidth).url();
    }
  }, [cmsImage]);

  console.log(imagePreview);
  return (
    <div
      className={cx(styles.sideBanner, {
        [styles.sideBanner__imageUpload]: setImageSource,
      })}
    >
      <div className={styles.sideBanner__wrapper}>
        <h1>{title}</h1>
        {image && <img src={image.url} alt={image.alt} />}
        {setImageSource && !uploadOnly && (
          <ImageUpload
            imagePreview={imagePreview}
            imageSource={imageSource}
            loading={setLoading}
            setImageSource={setImageSource}
            setImagePreview={setImagePreview}
          />
        )}
        {setImageSource && uploadOnly && (
          <EventImageUpload
            imagePreview={imagePreview}
            imageSource={imageSource}
            loading={setLoading}
            setImageSource={setImageSource}
            setImagePreview={setImagePreview}
          />
        )}
        {dynamicText && <BlockContent blocks={text} />}
        {text && !dynamicText && <p>{text}</p>}
        {cmsImage && (
          <img
            className={styles.sideBanner__cmsImage}
            src={cmsImageBuild}
            alt={title}
          />
        )}
      </div>
    </div>
  );
}

SideBanner.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string || PropTypes.array,
  setImageSource: PropTypes.func,
};

export default SideBanner;
