import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
///--- Queries & Actions
import { getAnnouncement } from "api/announcements.js";
//--- Components
import DataLoader from "components/DataLoader.js";
import AnnouncementArticle from "components/Announcements/AnnouncementArticle";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default withRouter(
  connect(mapStateToProps)(function AnnouncementListing(props) {
    const pageId = props.match.params.id;
    return (
      <PageWrapper>
        <DataLoader
          load={async () => {
            try {
              const announcement = await getAnnouncement(pageId);
              return announcement;
            } catch (e) {
              const res = await e.response.json();
              console.warn("errors", res, e); // { errors; [ ... ] }
            }
          }}
          fallback={() => {
            return <Loading />;
          }}
        >
          {(props) => {
            return <AnnouncementArticle article={props.data[0]} />;
          }}
        </DataLoader>
      </PageWrapper>
    );
  })
);

function mapStateToProps(state) {
  return {
    ...state,
  };
}
