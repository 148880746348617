import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { updateInfo } from "state/actions/userActions.js";
//--- Styles
import styles from "components/SignupFlow/SignupFlow.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import SelectBox from "components/FormElements/SelectBox";
import SideBanner from "components/Shared/SideBanner";
//--- Autocomplete Data
import DCNeigborhoods from "components/Shared/AutocompleteData/DCNeighborhoods";
import NYCCNeigborhoods from "components/Shared/AutocompleteData/NYC";
import Cities from "components/Shared/AutocompleteData/Cities";

function Location(props) {
  const [community, selectCommunity] = useState("");
  const [listValue, setListValue] = useState([]);

  function changeCommunity(val) {
    selectCommunity(val);
    if (val === "Washington D.C.") {
      return setListValue(DCNeigborhoods);
    } else if ((val = "New York City")) {
      return setListValue(NYCCNeigborhoods);
      //--- When more cities open up uncomment code below:
      // } else if (val = 'Los Angeles') {
      //   return setListValue(LANeigborhoods);
      // } else if (val = 'Chicago') {
      //   return setListValue(ChiNeigborhoods);
      // } else if (val = 'Toronto') {
      //   return setListValue(TorontoCNeigborhoods);
    } else {
      //--- Hide Neighborhood Input
      return setListValue([]);
    }
  }

  useEffect(() => {
    props.user.community && changeCommunity(props.user.community);
  }, []);

  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={props.cmsData.location_title && props.cmsData.location_title}
          text={
            props.cmsData.location_subtitle && props.cmsData.location_subtitle
          }
        />
        <div className={styles.signup__form}>
          <div className={styles.signup__locations}>
            <h2>
              {props.cmsData.location_question &&
                props.cmsData.location_question}
            </h2>
            <div className={styles.signup__locations__row}>
              {Cities.map((city, i) => (
                <SelectBox
                  currentValue={community}
                  key={i}
                  onclick={changeCommunity}
                  value={city}
                />
              ))}
            </div>
          </div>
          {community !== "" && (
            <div className={styles.signup__locations}>
              <Formik
                initialValues={{
                  neighborhood: props.user.neighborhoods
                    ? props.user.neighborhoods[0]
                    : "",
                  show_neighborhoods: props.user.show_neighborhoods
                    ? props.user.show_neighborhoods
                    : true,
                }}
                onSubmit={async (values, actions) => {
                  const { neighborhood, show_neighborhoods } = values;
                  props.dispatch(
                    updateInfo({
                      ...props.user,
                      community: community,
                      show_neighborhoods,
                    })
                  );
                  return props.updateFn(props.updateValue);
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                  <Form>
                    <FormButton label="Continue" disabled={isSubmitting} />
                  </Form>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Location);
