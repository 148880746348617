import React from "react";

import styles from "components/Events/Events.module.scss";

function CancelledMessage({ first_name = "The host" }) {
  return (
    <div className={styles.event__cancelled}>
      <p>{first_name} had to cancel this gathering. Until next time :-(</p>
    </div>
  );
}

export default CancelledMessage;
