import React from "react";
import { publishEvent } from "api/event";

import styles from "components/Events/Events.module.scss";

export default function UnpublishedEventBar({
  eventId,
  setLoading,
  setErrorMessage,
}) {
  async function publishEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await publishEvent({ event_id });
      setLoading(false);
      window.location = `/event/${event_id}`;
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      setErrorMessage("Error: " + (res.errors[0] && res.errors[0].details));
    }
  }

  return (
    <div className={styles.event__edit}>
      <p>This Event is a Draft. Ready to Publish?</p>
      <p onClick={() => publishEventFunc(eventId)}>
        <span>Publish this Event</span>
      </p>
    </div>
  );
}
