import isTokenExp from './isTokenExp';
import cookie from 'js-cookie';

export const isAuthenticated = () => {
  const user = cookie.getJSON('gni_user') || {};
  const token = user ? user.access_token : false;

  if (!token || isTokenExp(token) || token === 'undefined') {
    return false;
  } else {
    return true;
  }
}
