import React from "react";

import styles from "components/HostEvents/HostEvents.module.scss";

import Button from "components/Button";

export default function DeleteConfirm({ deleteEvent, closeModal, eventId }) {
  return (
    <div className={styles.cancel}>
      <div className={styles.cancel__body}>
        <div className={styles.cancel__close} onClick={() => closeModal("")}>
          X
        </div>
        <div className={styles.cancel__body__wrapper}>
          <h3>Are you sure you want to delete?</h3>
          <p>There's no going back...</p>
          <div className={styles.cancel__buttons}>
            <Button
              label="Yes, Delete"
              onclick={deleteEvent}
              onclickvalue={eventId}
            />
            <Button
              label="No, Nevermind"
              onclick={closeModal}
              onclickvalue={""}
              additionalClass="button__transparent"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
