export function createEventAction (event) {
  return {
    type: 'CREATE_EVENT',
    ...event
  }
}

export function setCurrentCreateEvent (event) {
  return {
    type: 'SET_CREATE_EVENT',
    ...event
  }
}

export function resetCreateEvent (event) {
  return {
    type: 'RESET_CREATE_EVENT',
    ...event
  }
}
