import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { updateUser, updateUserAvatar } from "api/user.js";
import { updateInfo } from "state/actions/userActions.js";
//--- Styles
import onboardingStyles from "components/Onboarding/Onboarding.module.scss";
import styles from "components/EditAccount/EditAccount.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import Checkbox from "components/FormElements/Checkbox";
import FormButton from "components/Button/FormButton";
import ImageUpload from "components/Shared/ImageUpload";
import Input from "components/FormElements/Input";
import Loading from "components/Loading";
import SelectBox from "components/FormElements/SelectBox";
import Selector from "components/FormElements/Selector";
//--- Autocomplete Data
import Industries from "components/Shared/AutocompleteData/Industries";

function ProfileDetails({ user, userData, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Update Info");
  const [pronouns, setPronouns] = useState("they/them");
  const [profileImageSource, setProfileImageSource] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState(false);
  const [bringingToTable, setBringing] = useState([]);
  const [updateValue, forceUpdate] = useState(1);

  const [userObject, setUserObject] = useState({
    ...user,
    ...userData,
  });

  function updateUserInfo(newInfo) {
    const updatedInfo = {
      ...userObject,
      ...newInfo,
    };

    dispatch(updateInfo(updatedInfo));
    setUserObject(updatedInfo);
  }

  useEffect(() => {
    userObject.membership_offering &&
      setBringing(userObject.membership_offering);
    userObject.pronouns && setPronouns(userObject.pronouns);
    userObject.userImage &&
      setProfileImagePreview(
        user.userImage ? user.userImage : userObject.userImage
      );
  }, [userObject]);

  function bringingList(value) {
    let newBringing = bringingToTable;
    if (bringingToTable.includes(value)) {
      let index = newBringing.indexOf(value);
      newBringing.splice(index, 1);
    } else {
      newBringing.push(value);
    }
    setBringing(newBringing);
    return forceUpdate(updateValue + 1);
  }

  return (
    <div className={styles.editAccount__body}>
      {loading && <Loading />}
      <h2>Profile Details</h2>
      <div className={styles.editAccount__body__imageUpload}>
        <h3>Profile Image</h3>
        <ImageUpload
          imagePreview={profileImagePreview}
          imageSource={profileImageSource}
          loading={setLoading}
          setImageSource={setProfileImageSource}
          setImagePreview={setProfileImagePreview}
        />
      </div>
      <Formik
        initialValues={{
          bio: userObject.bio,
          currently_reading: userObject.currently_reading,
          currently_watching: userObject.currently_watching,
          currently_listening: userObject.currently_listening,
          currently_instagram: userObject.currently_instagram,
          currently_irl: userObject.currently_irl,
          currently_eating: userObject.currently_eating,
          industry: userObject.industry,
          instagram: userObject.instagram,
          linkedin: userObject.linkedin,
          show_email: userObject.show_email || true,
          show_pronouns: userObject.show_pronouns || true,
          sign: userObject.sign,
          social_email: userObject.social_email,
          tagline: userObject.tagline,
          twitter: userObject.twitter,
          website: userObject.website,
        }}
        onSubmit={async (values, actions) => {
          const {
            bio,
            currently_reading,
            currently_watching,
            currently_listening,
            currently_instagram,
            currently_irl,
            currently_eating,
            industry,
            instagram,
            linkedin,
            show_email,
            show_pronouns,
            sign,
            social_email,
            tagline,
            twitter,
            website,
          } = values;
          setButtonText("Updating...");
          if (profileImageSource) {
            const result = await updateUserAvatar(
              userObject._id,
              profileImageSource
            );
          }
          updateUser(userObject._id, {
            bio,
            currently_reading,
            currently_watching,
            currently_listening,
            currently_instagram,
            currently_irl,
            currently_eating,
            industry,
            instagram,
            linkedin,
            membership_offering: bringingToTable,
            onboarded: true,
            pronouns,
            show_email,
            show_pronouns,
            sign,
            social_email,
            tagline,
            twitter,
            website,
          })
            .then(async function onSuccess() {
              updateUserInfo({
                userImage: profileImagePreview
                  ? profileImagePreview
                  : profileImageSource,
                ...values,
              });
              // actions.resetForm(values);
              setButtonText("Updated!");

              setTimeout(() => {
                actions.setSubmitting(false);
                setButtonText("Update Info Again");
              }, 750);
            })
            .catch(async function (err) {
              console.error(err);
              actions.setSubmitting(false);
            });
        }}
        render={({ errors, isSubmitting }) => (
          <Form className={styles.form}>
            <section className={onboardingStyles.profile__body__section}>
              <h3>What are your preferred pronouns?</h3>
              <div className={onboardingStyles.profile__body__section__row}>
                <SelectBox
                  value="they/them"
                  onclick={setPronouns}
                  currentValue={pronouns}
                />
                <SelectBox
                  value="she/her"
                  onclick={setPronouns}
                  currentValue={pronouns}
                />
                <SelectBox
                  value="he/him"
                  onclick={setPronouns}
                  currentValue={pronouns}
                />
              </div>
              <Checkbox
                name="show_pronouns"
                label="Show pronouns on my profile (other members will see them)"
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>Your tagline</h3>
              <Input
                label=""
                name="tagline"
                placeholder="Ex. Flower power baker girl in DC."
                type="text"
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>In what industry do you work?</h3>
              <AutocompleteInput
                initialValue={userObject.industry || ""}
                label=""
                listValues={Industries}
                limitValues
                name="industry"
                placeholder="Select Industry"
                type="text"
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>What's your star sign?</h3>
              <Selector
                label=""
                name="sign"
                placeholder="Select Horoscope"
                values={[
                  " Aries",
                  "Taurus",
                  "Gemini",
                  "Cancer",
                  "Leo",
                  "Virgo",
                  "Libra",
                  "Scorpio",
                  "Sagittarius",
                  "Capricorn",
                  "Aquarius",
                  "Pisces",
                ]}
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>What else should people know about you?</h3>
              <CharacterLimitTextBox
                limit={300}
                name="bio"
                placeholder="Ex. I'm a multi-hyphenate writer-baker-coder. I’m weirdly passionate about finding the perfect recipe for scones. Please let me know if you’ve found one."
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>What are you currently...</h3>
              <Input
                label="Reading?"
                name="currently_reading"
                placeholder="Re-reading Harry Potter"
                type="text"
              />
              <Input
                label="Watching?"
                name="currently_watching"
                placeholder="Big Little Lies"
                type="text"
              />
              <Input
                label="Listening to (i.e. podcasts, audiobooks, music)?"
                name="currently_listening"
                placeholder="Still Processing"
                type="text"
              />
              <Input
                label="Loving following on Instagram?"
                name="currently_instagram"
                placeholder="@mindykaling is amazing"
                type="text"
              />
              <Input
                label="Frequenting IRL (cafes, restaurants, bookstores)?"
                name="currently_irl"
                placeholder="Lucky Buns in AdMo!!!"
                type="text"
              />
              <Input
                label="Cooking or Eating"
                name="currently_eating"
                placeholder="A great new sandwich spot or a recipe obessesion"
                type="text"
              />
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>How can people get in touch?</h3>
              <Input
                label="Twitter"
                name="twitter"
                placeholder="@"
                type="text"
                withAtSymbol={true}
              />
              <Input
                label="Instagram"
                name="instagram"
                placeholder="@"
                type="text"
                withAtSymbol={true}
              />
              <Input
                label="LinkedIn"
                name="linkedin"
                placeholder="https://linkedin.com/in/username"
                type="text"
              />
              <Input
                label="Website"
                name="website"
                placeholder="http://"
                type="text"
              />
              <Input
                label="Email"
                name="social_email"
                placeholder="email address"
                type="email"
              />
              {/* <div style={{display: `${values.social_email !== '' ? 'block' : 'none'}`}}>
                <Checkbox
                  name="show_email"
                  label="Show my email on my profile (other members will see it)"
                />
              </div> */}
            </section>
            <section className={onboardingStyles.profile__body__section}>
              <h3>What will you be bringing to The Lounge?</h3>
              <div className={onboardingStyles.profile__body__section__row}>
                <SelectBox
                  value="Self-care tips and tricks"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Self-care tips and tricks")
                      ? "Self-care tips and tricks"
                      : ""
                  }
                />
                <SelectBox
                  value="Mentorship"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable && bringingToTable.includes("Mentorship")
                      ? "Mentorship"
                      : ""
                  }
                />
                <SelectBox
                  value="Coffee/Activity Buddy"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Coffee/Activity Buddy")
                      ? "Coffee/Activity Buddy"
                      : ""
                  }
                />
                <SelectBox
                  value="Hosting gatherings"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Hosting gatherings")
                      ? "Hosting gatherings"
                      : ""
                  }
                />
                <SelectBox
                  value="Creative Collaboration"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Creative Collaboration")
                      ? "Creative Collaboration"
                      : ""
                  }
                />
                <SelectBox
                  value="Book conversation"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Book conversation")
                      ? "Book conversation"
                      : ""
                  }
                />
                <SelectBox
                  value="Cozy recs"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable && bringingToTable.includes("Cozy recs")
                      ? "Cozy recs"
                      : ""
                  }
                />
                <SelectBox
                  value="Event participation"
                  onclick={bringingList}
                  currentValue={
                    bringingToTable &&
                    bringingToTable.includes("Event participation")
                      ? "Event participation"
                      : ""
                  }
                />
              </div>
            </section>
            <FormButton label={buttonText} disabled={isSubmitting} />
          </Form>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileDetails);
