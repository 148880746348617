import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import parseSanityImage from "utils/parseSanityImage";
import { updateInfo } from "state/actions/userActions.js";
import { shipping } from "utils/validationSchema";

import styles from "components/Onboarding/Onboarding.module.scss";

import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";
import StateSelector from "components/FormElements/StateSelector";

function Shipping(props) {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={props.cmsData.address_title && props.cmsData.address_title}
          text={
            props.cmsData.address_subtitle && props.cmsData.address_subtitle
          }
          cmsImage={props.cmsData.address_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <div className={styles.profile__body__section}>
            <h3>
              {props.cmsData.address_input_title &&
                props.cmsData.address_input_title}
            </h3>
            <Formik
              initialValues={{
                address_1: props.user.address_1 ? props.user.address_1 : "",
                address_2: props.user.address_2 ? props.user.address_2 : "",
                city: props.user.city ? props.user.city : "",
                state: props.user.state ? props.user.state : "",
                zip: props.user.zip ? props.user.zip : "",
              }}
              validationSchema={shipping}
              onSubmit={async (values, actions) => {
                const { address_1, address_2, city, state, zip } = values;
                props.dispatch(
                  updateInfo({
                    ...props.user,
                    address_1,
                    address_2,
                    city,
                    state,
                    zip,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <FormRow>
                    <Input
                      label="Street Address"
                      name="address_1"
                      type="text"
                    />
                    <Input label="Apt / Unit" name="address_2" type="text" />
                  </FormRow>
                  <Input label="City" name="city" type="text" />
                  <FormRow>
                    <StateSelector
                      error={errors.state && touched.state}
                      label="State"
                      name="state"
                      type="text"
                    />
                    <Input label="Zipcode" name="zip" type="text" />
                  </FormRow>
                  <FormButton
                    label="Next up: The Deets"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Shipping);
