import React, { useEffect, useState, createRef } from "react";
import cx from "classnames";
import { Field } from "formik";
//--- Styles
import styles from "components/FormElements/FormElements.module.scss";

function AutocompleteInput({
  initialValue,
  label,
  listValues,
  limitValues,
  name,
  placeholder,
  type,
}) {
  const [availableVals, setAvailableVals] = useState([]);
  const inputRef = createRef();
  const dropdownRef = createRef();

  useEffect(() => {
    if (initialValue) setInputValue(initialValue);
  }, [initialValue]);

  function setInputValue(val) {
    setAvailableVals([]);
    return (inputRef.current.value = val);
  }

  function isValueInList(input) {
    if (
      listValues.findIndex((listItem) =>
        listItem.toLowerCase().includes(input)
      ) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  function matchIt(input) {
    const reg = new RegExp(input.split("").join("\\w*").replace(/\W/, ""), "i");
    const availableValues = listValues.filter(
      (listItem) => !!listItem.match(reg)
    );
    return setAvailableVals(availableValues);
  }

  function autoComplete(e) {
    const minCharacters = 0;
    const currentInput = e.currentTarget;
    if (
      !isNaN(currentInput.value) ||
      currentInput.value.length < minCharacters
    ) {
      return;
    }
    return matchIt(currentInput.value);
  }
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];

        return (
          <div className={cx(styles.input, { [styles.input__error]: error })}>
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <input
              aria-label={name}
              autoComplete="off"
              type={type ? type : "text"}
              name={name}
              placeholder={placeholder}
              onChange={(e) => {
                if (limitValues) {
                  if (!isValueInList(e.currentTarget.value)) {
                    form.errors[field.name] = "Enter Valid Selection";
                    return;
                  }
                }
                form.setFieldValue(name, e.target.value);
                autoComplete(e);
              }}
              ref={inputRef}
              value={form.values[field.name]}
            />
            <div ref={dropdownRef} className={styles.autoComplete__results}>
              {availableVals.map((item, i) => (
                <p
                  key={i}
                  onClick={() => {
                    setInputValue(item);
                    return form.setFieldValue(name, item);
                  }}
                >
                  {item}
                </p>
              ))}
            </div>
            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
}

export default AutocompleteInput;
