import React, { memo, useRef, useState } from "react";
import cx from "classnames";
// Styles
import styles from "components/Members/Members.module.scss";
//--- Utils
import useOutsideClickListener from "utils/useOutsideClickListener";

const Sorting = memo(function ({
  baseUrl = "/members",
  isFiltering,
  sortingParam = "Latest Joined",
}) {
  const [sortingOpen, setSortingOpen] = useState(false);
  const baseUrlVal = baseUrl + (isFiltering ? "" : "?");
  const sortingRef = useRef(null);

  useOutsideClickListener(sortingRef, (clickedOutside) => {
    if (sortingOpen && clickedOutside) setSortingOpen(false);
  });

  return (
    <div className={styles.sorting} ref={sortingRef}>
      <div
        className={styles.sorting__toggle}
        onClick={() => setSortingOpen(!sortingOpen)}
      >
        <span>Sort:</span>
        {sortingParam}
        <svg
          width="16"
          height="9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.42 1.5l6.5 6 6.5-6" strokeWidth="2" />
        </svg>
      </div>
      <div
        className={cx(styles.sorting__dropdown, {
          [styles.sorting__dropdown__open]: sortingOpen,
        })}
      >
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "A-Z First Name",
          })}
          href={`${baseUrlVal}sort_by=A-Z First Name`}
        >
          A-Z First Name
        </a>
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "Z-A First Name",
          })}
          href={`${baseUrlVal}sort_by=Z-A First Name`}
        >
          Z-A First Name
        </a>
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "A-Z Last Name",
          })}
          href={`${baseUrlVal}sort_by=A-Z Last Name`}
        >
          A-Z Last Name
        </a>
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "Z-A Last Name",
          })}
          href={`${baseUrlVal}sort_by=Z-A Last Name`}
        >
          Z-A Last Name
        </a>
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "Earliest Joined",
          })}
          href={`${baseUrlVal}sort_by=Earliest Joined`}
        >
          Earliest Joined
        </a>
        <a
          className={cx(styles.sorting__dropdown__item, {
            [styles.sorting__dropdown__item__active]:
              sortingParam === "Latest Joined",
          })}
          href={`${baseUrlVal}sort_by=Latest Joined`}
        >
          Latest Joined
        </a>
      </div>
    </div>
  );
});

export default Sorting;
