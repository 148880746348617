import React from "react";
import styles from "components/Dashboard/Dashboard.module.scss";
import EventCard from "components/Shared/EventCard";
import Button from "components/Button/index.js";

function SiteEvents({ events = [], user_id, eventRSVPS = [] }) {
  return (
    <div className={styles.dashboard__siteEvents}>
      <h2>Gather &amp; Connect</h2>
      <div className={styles.dashboard__siteEvents__wrapper}>
        {events.map((event, i) => {
          const isRSVPd = eventRSVPS.find((ev) => ev._id === event._id);
          return <EventCard details={event} key={i} isRSVPd={isRSVPd} />;
        })}
      </div>
      <div className={styles.dashboard__siteEvents__buttons}>
        <Button
          label="My RSVPs"
          link={`/user/${user_id}#attended`}
          additionalClass="button__transparent"
        />
        <Button
          label="See more gatherings"
          link="/gatherings"
          additionalClass="button__transparent"
        />
      </div>
    </div>
  );
}

export default SiteEvents;
