import decode from 'jwt-decode'

const isTokenExp = token => {
  try {
    const { exp } = decode(token)
    if (exp < Date.now() / 1000) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export default isTokenExp
