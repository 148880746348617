import React from "react";
///--- Queries & Actions
import { getAnnouncements } from "api/announcements.js";
import { getArticles } from "api/articles.js";
//--- Utils
import { pageParams } from "utils/parseQueryParams";
//--- Components
import DataLoader from "components/DataLoader.js";
import Announcements from "components/Announcements";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

const perPage = 12;

export default function AnnouncementListing(props) {
  //--- Get Query Params
  const urlParams = new URLSearchParams(props.location.search);
  //--- Pagination
  const pageParam = urlParams.get("page") ? urlParams.get("page") : "1";
  const page = pageParams(pageParam, perPage);

  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            const announcements = await getAnnouncements(page);
            return announcements;
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          return (
            <Announcements
              announcements={props.data}
              currentPage={pageParam}
              perPage={perPage}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}
