import React, { memo, useState, useEffect } from "react";
import cx from "classnames";
// Components
import FilterButton from "components/Button/FilterButton";
import Button from "components/Button";
// Styles
import styles from "./Filter.module.scss";

const Filter = memo(function ({
  label,
  filterprop,
  filterArr,
  lightBackground,
  setWhichVisible,
  whichFilterAmI,
  whichVisible,
}) {
  const [whichFiltered, setWhichFiltered] = useState([]);
  const [urlToSubmit, setUrlToSubmit] = useState("");
  const [numberOfFilteredProperties, setNumberofFilteredProps] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const baseUrl = `${window.location.origin}${window.location.pathname}`;

  const changeVisibleFilterModal = () => {
    let temp = new Array(whichVisible.length).fill(false);
    if (!whichVisible[whichFilterAmI]) {
      temp[whichFilterAmI] = true;
    } else {
      temp[whichFilterAmI] = null;
    }
    setWhichVisible(temp);
  };

  // Sets which elements are filtered
  useEffect(() => {
    let propArr = urlParams.get(filterprop)
      ? urlParams.get(filterprop).split("+")
      : [];
    let filterArray = [];

    filterArr.map((element) => {
      if (!propArr.includes(element)) return null;
      return filterArray.push(element);
    });
    setWhichFiltered(filterArray);
  }, [filterArr]);

  // Set initial URL to submit
  useEffect(() => {
    setUrlToSubmit(window.location.href);
  }, []);

  // Set number of filtered properties
  useEffect(() => {
    let numProps = 0;
    whichFiltered.forEach((element) => {
      if (element) {
        numProps++;
      }
    });

    setNumberofFilteredProps(numProps);
  }, [whichFiltered]);

  // Update URL for submit
  function updateURLToSubmit(filterVals, filterPropVal) {
    let tempUrl = `${baseUrl}?`;

    //this determines if "other" is selected and sends the possible attributes to check
    //that it DOESNT match them.

    let filterValsString = filterVals.includes("Other")
      ? (() => {
          let output = "";
          filterVals.forEach((element, index) => {
            output +=
              element === "Other"
                ? "Other-" + filterArr.slice(0, filterArr.length - 1).join("-")
                : element;
            output += index < filterVals.length ? "+" : "";
          });
          return output;
        })()
      : (() => {
          let filVals = "";
          filterVals.map(
            (fil, i) => (filVals = filVals + (i > 0 ? `+${fil}` : fil))
          );
          return filVals.toString();
        })();
    // filterVals.toString();

    let keyArray = [];
    // Encodes string to be HTML compliant
    filterValsString = encodeURIComponent(filterValsString.trim());
    // Get all existing URL Params
    const urlParamEntries = urlParams.entries();
    for (const [key, value] of urlParamEntries) {
      // Build array of existing keys
      keyArray.push(key);
      // If the existing URL Params are already represented in the URL and there are filterVals
      if (key === filterPropVal && filterValsString !== "") {
        tempUrl = `${tempUrl}${key}=${filterValsString}&`;
        // If the existing URL Params are not there but there are filterVals...
      } else if (key !== filterPropVal) {
        tempUrl = `${tempUrl}${key}=${encodeURIComponent(value.trim())}&`;
      }
    }
    // If the filterprop value does NOT exist in the keyArray and filterVals aren't empty...
    if (
      !keyArray.find((element) => element === filterPropVal) &&
      filterValsString !== ""
    ) {
      // Update tempURL to have new filterVal
      tempUrl = `${tempUrl}${filterPropVal}=${filterValsString}&`;
    }
    setUrlToSubmit(tempUrl);
  }

  function setFilter(val) {
    let filterArray = [...whichFiltered];
    let filterValueExists = filterArray.find((element) => element === val);

    if (filterValueExists) {
      filterArray = filterArray.filter((element) => element !== val);
    } else {
      filterArray.push(val);
    }
    updateURLToSubmit(filterArray, filterprop);
    setWhichFiltered(filterArray);
  }

  return (
    <div className={styles.filter}>
      <FilterButton
        additionalClass={
          whichVisible[whichFilterAmI] || numberOfFilteredProperties > 0
            ? "buttonFilter__active"
            : ""
        }
        label={
          label +
          (numberOfFilteredProperties ? " • " + numberOfFilteredProperties : "")
        }
        lightBackground={lightBackground}
        onclick={() => {
          changeVisibleFilterModal();
        }}
      />
      <div
        className={cx(styles.filterModal, {
          [styles.filterModal__open]: whichVisible[whichFilterAmI],
        })}
      >
        <div className={styles.filterModal__entries}>
          {filterArr.map((value, i) => {
            return (
              <div
                className={cx(styles.filterModal__entries__entry, {
                  [styles.filterModal__entries__entry__active]: whichFiltered.includes(
                    value
                  ),
                })}
                key={i}
                onClick={() => {
                  setFilter(value);
                }}
              >
                <div
                  className={cx(styles.filterModal__box, {
                    [styles.filterModal__box__active]: whichFiltered.includes(
                      value
                    ),
                  })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                  </svg>
                </div>
                {value}
              </div>
            );
          })}
        </div>
        <div className={styles.filterModal__greyLine}></div>
        <div className={styles.filterModal__bottom}>
          <div
            className={styles.filterModal__clear}
            onClick={() => {
              updateURLToSubmit([], filterprop);
              setWhichFiltered([]);
            }}
          >
            Clear
          </div>
          <Button
            additionalClass="button__smaller"
            link={urlToSubmit}
            label="Apply"
          />
        </div>
      </div>
    </div>
  );
});

Filter.defaultProps = {
  extraStyles: "",
  label: "",
  filterprop: "",
  filterArr: [],
  whichFilterAmI: "",
  setWhichVisible: () => {},
  whichVisible: "",
};

export default Filter;
