import React from "react";
import { Redirect } from "react-router-dom";

import BasicInfo from "components/SignupFlow/BasicInfo";
import CreateAccount from "components/SignupFlow/CreateAccount";
import EmailEntry from "components/SignupFlow/EmailEntry";
// import Error from 'containers/Error';
import Loading from "components/Loading";
import Location from "components/SignupFlow/Location";
import Payment from "components/SignupFlow/Payment";
import ReviewInformation from "components/SignupFlow/ReviewInformation";
import Neighborhood from "../../components/SignupFlow/Neighborhood";

function ComponentHandler({
  signupStep = "email-entry",
  setCurrentStep,
  cmsData,
  emailParam,
  stripe,
  elements,
}) {
  switch (signupStep) {
    case "email-entry":
      return (
        <EmailEntry
          emailParam={emailParam}
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="create-account"
        />
      );
    case "create-account":
      return (
        <CreateAccount
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="basic-info"
        />
      );
    case "basic-info":
      return (
        <BasicInfo
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="location"
        />
      );
    case "location":
      return (
        <Location
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="neighborhood"
        />
      );
    case "neighborhood":
      return (
        <Neighborhood
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="payment"
        />
      );
    case "payment":
      return (
        <Payment
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="review-info"
          stripe={stripe}
          elements={elements}
        />
      );
    case "review-info":
      return (
        <ReviewInformation
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="process-account"
          stripe={stripe}
          elements={elements}
        />
      );
    case "process-account":
      return <Redirect to="/onboarding" />;
    default:
      return <Loading />;
  }
}

export default ComponentHandler;
