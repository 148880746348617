import React from "react";

import styles from "components/Button/Button.module.scss";

function GoogleCalButton({
  name,
  address_1,
  address_2,
  city,
  state,
  zip,
  eventId,
  startdateGMT,
  enddateGMT,
}) {
  const startDateFormat = `${startdateGMT.year}${startdateGMT.monthTwoDigits}${startdateGMT.dayTwoDigits}T${startdateGMT.rawHour}${startdateGMT.minutes}00Z`;
  const endDateFormat = `${enddateGMT.year}${enddateGMT.monthTwoDigits}${enddateGMT.dayTwoDigits}T${enddateGMT.rawHour}${enddateGMT.minutes}00Z`;

  return (
    <div
      className={`
        ${styles.button}
        ${styles.button__transparent}
        ${styles.button__googleCal}
      `}
    >
      <a
        aria-label="Add to Google Calendar"
        href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${name}&dates=${startDateFormat}%2F${endDateFormat}&location=${address_1}%20${address_2}%20${city}%20${state}%20${zip}&sprop=https://lounge.girlsnightin.co/event/${eventId}&ctz=America/New_York`}
        target="_blank"
        rel="noreferrer"
      >
        Add to Google Calendar
      </a>
    </div>
  );
}

export default GoogleCalButton;
