import ky from "ky";
import cookie from "js-cookie";

export async function isUserInBeta({ email }) {
  return ky
    .post("/.netlify/functions/utilUserInBeta", {
      json: {
        email,
      },
    })
    .json();
}

export async function signup(user) {
  const { userExists, valid } = await isUserInBeta(user);

  if (userExists) return { userExists };
  if (!valid) return { invalid: true };

  return ky
    .post("/.netlify/functions/userCreate", {
      json: user,
    })
    .json();
}

export async function createHostUser(user) {
  return ky
    .post("/.netlify/functions/userHostCreate", {
      json: user,
    })
    .json();
}

export async function login(user, rememberMe = true) {
  try {
    const res = await ky
      .post("/.netlify/functions/userLogin", {
        json: user,
      })
      .json();

    /*
     * NOTE: Remove this line of code to enable users other than admin to log in
     */
    if (res.roles.includes("admin")) {
      cookie.set("gni_user", res, rememberMe ? { expires: 7 } : {});
      return res;
    } else {
      return {
        errors: [
          {
            details: "Login Disabled",
          },
        ],
      };
    }
  } catch (e) {
    /**
     * If an error response was returned
     */
    if (e.response) {
      return e.response.json();
    }

    /**
     * Otherwise populate a default error
     */
    return {
      errors: [
        {
          details: "Something went wrong.",
        },
      ],
    };
  }
}

export async function logout() {
  sessionStorage.clear();
  cookie.remove("gni_user");
}

export async function forgotPasswordEmail({ email }) {
  return ky
    .post("/.netlify/functions/userTriggerForgotPassword", {
      json: {
        email,
      },
    })
    .json();
}
