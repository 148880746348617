import React, { useEffect, useState } from "react";
//--- Utils
import { compareTimePast, compareTimeUpcoming } from "utils/compareTime";
//--- Styles
import styles from "components/UserProfile/UserProfile.module.scss";

import EventCard from "components/Shared/EventCard";
import UpcomingPast from "components/Shared/UpcomingPast";

export default function EventsHosted({ first_name, eventHosted = [] }) {
  const [eventRange, setEventRange] = useState("upcoming");
  const [eventsDisplay, setEventsDisplay] = useState([]);

  useEffect(() => {
    eventHosted && changeEventView("upcoming");
  }, []);

  function changeEventView(range) {
    let filterEvents = eventHosted;
    if (range === "upcoming") {
      filterEvents = filterEvents.filter((event) =>
        compareTimeUpcoming(event.start_date)
      );
      filterEvents = filterEvents.filter((event) => event.published === true);
    } else if (range === "past") {
      filterEvents = filterEvents.filter((event) =>
        compareTimePast(event.start_date)
      );
    } else {
      console.warn("ERROR: Sorting Events Error - Invalid Range");
    }
    setEventRange(range);
    return setEventsDisplay(filterEvents);
  }

  return (
    <div className={styles.profile__hosted}>
      <h2>{first_name}'s Gatherings</h2>
      <UpcomingPast onclick={changeEventView} eventType={eventRange} />
      {eventsDisplay.map((event, i) => (
        <EventCard key={i} details={event} />
      ))}
      {eventsDisplay.length === 0 && (
        <div className={styles.profile__hosted__empty}>
          <h4>
            No gatherings &nbsp;
            {eventRange === "past" && "in the past"}
            {eventRange === "upcoming" && "coming up"}
          </h4>
        </div>
      )}
    </div>
  );
}
