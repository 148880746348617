import React, { useState, useRef } from "react";
import cx from "classnames";

import styles from "./Header.module.scss";

import useOutsideClickListener from "utils/useOutsideClickListener";

function ProfileNav({ user }) {
  const [profileOpen, setProfileOpen] = useState(false);
  const navRef = useRef(null);

  useOutsideClickListener(navRef, (clickedOutside) => {
    if (profileOpen && clickedOutside) setProfileOpen(false);
  });
  return (
    <div className={styles.profileNav} ref={navRef}>
      <div
        className={cx(styles.profileNav__image, {
          [styles.profileNav__image__active]: profileOpen,
        })}
        onClick={() => setProfileOpen(!profileOpen)}
        style={{
          backgroundImage: `url('${
            user.userImage
              ? user.userImage + "?w=120"
              : "/placeholder/placeholder-profile-image.png"
          }')`,
        }}
      />
      <div
        className={cx(styles.profileNav__dropdown, {
          [styles.profileNav__dropdown__open]: profileOpen,
        })}
      >
        <a
          aria-label={`view ${user.first_name}'s profile`}
          href={`/user/${user.user_id}`}
        >
          View Profile
        </a>
        <a aria-label="account settings" href="/edit-account">
          Account Settings
        </a>
        <a aria-label="support" href="/support">
          Support
        </a>
        <a aria-label="guidelines" href="/community-guidelines">
          Guidelines
        </a>
        {user.user_roles && user.user_roles.includes("host") && (
          <a aria-label="Manage My Gatherings" href="/hosting">
            Manage My Gatherings
          </a>
        )}
        {user.user_roles && !user.user_roles.includes("host") && (
          <a aria-label="Apply to Host" href="/hosting">
            Apply to Host
          </a>
        )}
        {user.user_roles && user.user_roles.includes("admin") && (
          <a aria-label="Admin" href="/admin">
            Admin Console
          </a>
        )}
        <a aria-label="logout" href="/logout">
          Log Out
        </a>
      </div>
    </div>
  );
}

export default ProfileNav;
