import React, { useState, createRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import cx from "classnames";
//--- Queries
import { uploadImage } from "api/image";
//--- Styles
import styles from "components/Shared/ImageUpload/ImageUpload.module.scss";

function EventImageUpload({
  imagePreview,
  imageSource,
  loading,
  setImagePreview,
  setImageSource,
}) {
  const inputRef = createRef();
  const canvasRef = createRef();

  const [imageCaptured, setImageCaptured] = useState(false);

  useEffect(() => {
    if (imagePreview) {
      handleImageUrl(imagePreview);
      setImageCaptured(true);
    } else {
      handleImageUrl("/placeholder/event-placeholder.jpg");
    }
  }, [imagePreview]);

  async function uploadImageBlob(base64Image) {
    loading(true);
    try {
      const result = await uploadImage(base64Image);
      setImagePreview(result.document.url);
      setImageSource(result.document._id);
      return loading(false);
    } catch (e) {
      const res = await e.response.json();
      loading(false);
      console.warn("errors", res);
    }
  }

  function handleImageUrl(url) {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      context.clearRect(0, 0, 500, 660);
      context.drawImage(img, 0, 0, 500, 660);
    };
    img.src = url;
  }

  function handleImage(file) {
    var reader = new FileReader();
    reader.onload = function (event) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, 500, 660);
      const img = new Image();

      img.onload = function () {
        if (isMobile) {
          if (!imageSource) {
            context.rotate(Math.PI / 2);
            context.translate(-50, -500);
          }
          let imageHeight = img.height;
          let imageWidth = img.width;
          let hRatio = 500 / imageWidth;
          let vRatio = 660 / imageHeight;
          let ratio = Math.max(hRatio, vRatio);
          let centerShift_x = (500 - imageWidth * ratio) / 2;
          let centerShift_y = (660 - imageHeight * ratio) / 2;
          context.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            imageWidth * ratio,
            imageHeight * ratio
          );
          uploadImageBlob(canvas.toDataURL());
          setImageCaptured(true);
        } else {
          let imageHeight = img.height;
          let imageWidth = img.width;
          let hRatio = 500 / imageWidth;
          let vRatio = 660 / imageHeight;
          let ratio = Math.max(hRatio, vRatio);
          let centerShift_x = (500 - imageWidth * ratio) / 2;
          let centerShift_y = (660 - imageHeight * ratio) / 2;
          context.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            imageWidth * ratio,
            imageHeight * ratio
          );
          uploadImageBlob(canvas.toDataURL());
          setImageCaptured(true);
        }
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  function imageChange(e) {
    let fileList = e.target.files;
    let file = null;

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      handleImage(file);
    }
  }

  function clearCanvas() {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, 500, 660);
    setImageCaptured(false);
  }

  function fileUploadToggle() {
    inputRef.current.click();
  }

  return (
    <div className={styles.imageUpload}>
      <div
        className={cx(
          styles.imageUpload__photo,
          styles.imageUpload__photo__event,
          {
            [styles.imageUpload__captured]: imageCaptured,
          }
        )}
      >
        {/* {!imageCaptured && <p className={styles.imageUpload__message} onClick={() => fileUploadToggle()}></p>} */}
        <input
          onChange={(e) => imageChange(e)}
          type="file"
          name="image"
          capture="user"
          accept="image/*"
          hidden
          ref={inputRef}
        />
        <canvas ref={canvasRef} width="500" height="660" />
      </div>
      <button
        className={styles.imageUpload__button}
        onClick={() => fileUploadToggle()}
      >
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="10.8334"
            y1="16"
            x2="10.8334"
            y2="2"
            stroke="#F5C38F"
            strokeWidth="2"
          />
          <path
            d="M6 7.0085L11 2.00293L16 7.0085"
            stroke="#F5C38F"
            strokeWidth="2"
          />
          <path
            d="M1 19.4829V22.0001H21V19.4829"
            stroke="#F5C38F"
            strokeWidth="2"
          />
        </svg>{" "}
        Upload a photo
      </button>
      {imageCaptured && (
        <button
          className={styles.imageUpload__button}
          onClick={() => clearCanvas()}
        >
          Clear Image
        </button>
      )}
    </div>
  );
}

export default EventImageUpload;
