import React, { useState } from "react";
import { Formik, Form } from "formik";
//--- Queries
import { forgotPasswordEmail } from "api/auth";
//--- Styles
import styles from "containers/Login/Login.module.scss";
//--- Components
import ErrorBanner from "components/Shared/ErrorBanner";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

function ForgotPassword({ triggerForgotPassword }) {
  const [respMessage, setRespMessage] = useState("");

  return (
    <div className={styles.forgotPass}>
      <div className={styles.forgotPass__wrapper}>
        <div
          className={styles.forgotPass__close}
          onClick={() => triggerForgotPassword(false)}
        >
          <svg
            viewBox="0 0 16 14"
            width="16"
            height="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#DD5D39"
              strokeWidth=".8"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="square"
            >
              <path d="M14.732 13.605L1.432.105M1.268 13.605l13.3-13.5" />
            </g>
          </svg>
        </div>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values, actions) => {
            const { email } = values;
            try {
              setRespMessage("Check your Email for Reset Link");
              const response = await forgotPasswordEmail({ email });
              await setTimeout(async () => {
                return triggerForgotPassword(false);
              }, 3000);
            } catch (e) {
              const res = await e.response.json();
              console.warn("errors", res); // { errors; [ ... ] }
              setRespMessage(res.errors[0].details);
              actions.setSubmitting(false);
            }
          }}
          render={({ errors, status, touched, isSubmitting }) => (
            <Form>
              {respMessage !== "" && <ErrorBanner message={respMessage} />}
              <Input type="email" label="Email Address" name="email" />
              <FormButton label="Reset Password" disabled={isSubmitting} />
            </Form>
          )}
        />
      </div>
    </div>
  );
}

export default ForgotPassword;
