import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "components/Header/Header.module.scss";

import NavItem from "components/Header/NavItem";
import ProfileNav from "components/Header/ProfileNav";

function Navigation({
  loggedIn = false,
  user = {},
  menuOpen,
  headerColor = "#284C4A",
  currentPath,
}) {
  return (
    <div
      className={cx(styles.navigation, {
        [styles.navigation__open]: menuOpen,
      })}
    >
      <div className={styles.navigation__wrapper}>
        {loggedIn && (
          <NavItem
            text="Home"
            link="/dashboard"
            color={headerColor}
            active={currentPath === "/dashboard"}
          />
        )}
        {loggedIn && (
          <NavItem
            text="Gatherings"
            link="/gatherings"
            color={headerColor}
            active={currentPath === "/gatherings"}
          />
        )}
        {loggedIn && (
          <NavItem
            text="Community"
            link="/members"
            color={headerColor}
            active={currentPath === "/members"}
          />
        )}
        {loggedIn && (
          <NavItem
            text="Perks"
            link="/perks"
            color={headerColor}
            active={currentPath === "/perks"}
          />
        )}
        {loggedIn && <ProfileNav user={user} />}
        {!loggedIn && (
          <NavItem
            type="button"
            text="Join"
            link="/signup"
            color={headerColor}
          />
        )}
        {!loggedIn && (
          <NavItem
            text="Sign In"
            link="/login"
            color={headerColor}
            active={currentPath === "/login"}
          />
        )}
      </div>
    </div>
  );
}

Navigation.propTypes = {
  loggedIn: PropTypes.bool,
};

export default Navigation;
