import React, { useState } from "react";
import { connect } from "react-redux";
//--- Queries & Actions
import { getUserForEdit, getStripeSubscription } from "api/user.js";
//--- Styles
import styles from "components/EditAccount/EditAccount.module.scss";
//--- Components
import Billing from "components/EditAccount/Billing";
import DataLoader from "components/DataLoader.js";
import EditAccount from "components/EditAccount";
import Loading from "components/Loading";
import Navigation from "components/EditAccount/Navigation";
import Notification from "components/EditAccount/Notification";
import PageWrapper from "components/PageWrapper";
import ProfileDetails from "components/EditAccount/ProfileDetails";

function EditAccountWrapper(props) {
  const [editPage, setEditPage] = useState("details");
  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            const user = await getUserForEdit(props.user.user_id);
            const stripeUser = await getStripeSubscription(props.user.user_id);
            return { user, stripeUser: { ...stripeUser.stripeUser } };
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
            return null;
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {({ data = {} }) => {
          const user = data.user || {};
          const stripeUser = data.stripeUser || {};
          return (
            <div className={styles.editAccount}>
              <Navigation activeItem={editPage} setActive={setEditPage} />
              {editPage === "details" && <ProfileDetails userData={user} />}
              {editPage === "account" && <EditAccount userData={user} />}
              {editPage === "membership" && (
                <Billing userData={user} stripeUser={stripeUser} />
              )}
              {editPage === "notification" && <Notification userData={user} />}
            </div>
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EditAccountWrapper);
