import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
import { createEventBasic } from "utils/validationSchema";
import { createEvent, updateEventImage, updateEvent } from "api/event.js";
//--- Utils
import getISODate from "utils/getISODate";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import DatePicker from "components/FormElements/DatePicker";
import ErrorBanner from "components/Shared/ErrorBanner";
import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import Loading from "components/Loading";
import SideBanner from "components/Shared/SideBanner";
import TimePicker from "components/FormElements/TimePicker";

function TheBasics(props) {
  const [eventImageSource, setEventImageSource] = useState(false);
  const [eventImagePreview, setEventImagePreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const cmsData = props.cmsData ? props.cmsData : {};

  useEffect(() => {
    props.event.eventImage && setEventImagePreview(props.event.eventImage);
  }, []);
  return (
    <div className={styles.createEvent}>
      {loading && <Loading />}

      <div className={styles.createEvent__wrapper}>
        <SideBanner
          title={cmsData ? cmsData.basics_side_title : "The Basics"}
          text={
            cmsData
              ? cmsData.basics_side_text
              : "Add a photo — it can be of the book, of your space, or something else on-theme."
          }
          setImageSource={setEventImageSource}
          setImagePreview={setEventImagePreview}
          imageSource={eventImageSource}
          imagePreview={eventImagePreview}
          setLoading={setLoading}
          uploadOnly={true}
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{
                eventName: props.event.eventName || "",
                eventBlurb: props.event.eventBlurb || "",
                eventDate: props.event.eventDate || "",
                startTime: props.event.startTime || "",
                endTime: props.event.endTime || "",
              }}
              onSubmit={async (values, actions) => {
                const {
                  eventName,
                  eventBlurb,
                  eventDate,
                  startTime,
                  endTime,
                } = values;
                let result = {};
                let imageResult = {};
                //--- Check if Image has been uploaded now or previously - Reject if not
                if (!eventImageSource && !eventImagePreview) {
                  setErrorMessage("Event Image is Required");
                  actions.setSubmitting(false);
                  return;
                }
                const eventLocation = props.event.eventLocation;
                try {
                  setLoading(true);
                  const startDate = getISODate(startTime, eventDate);
                  const endDate = getISODate(endTime, eventDate);
                  if (props.event.eventId) {
                    result = await updateEvent({
                      community: props.user.community || "Washington D.C.",
                      description: eventBlurb,
                      name: eventName,
                      start_date: startDate,
                      end_date: endDate,
                      event_id: props.event.eventId,
                      type: props.event.eventType,
                    });
                    //--- If a new image has been uploaded, upload it
                    //--- If an old image existed or if no image had been uploaded this
                    //--- would have been caught above
                    if (eventImageSource) {
                      imageResult = await updateEventImage(
                        props.event.eventId,
                        eventImageSource
                      );
                    }
                  } else {
                    result = await createEvent({
                      community: props.user.community || "Washington D.C.",
                      description: eventBlurb,
                      end_date: endDate,
                      name: eventName,
                      start_date: startDate,
                      type: props.event.eventType,
                    });
                    if (eventImageSource) {
                      imageResult = await updateEventImage(
                        result.event_id,
                        eventImageSource
                      );
                    }
                  }
                  let eventId = result ? result.event_id : props.event.eventId;
                  props.dispatch(
                    createEventAction({
                      ...props.event,
                      eventId,
                      eventName,
                      eventBlurb,
                      eventDate,
                      startTime,
                      endTime,
                      eventImage: eventImagePreview,
                    })
                  );
                  setLoading(false);
                  return props.updateFn(
                    eventLocation === "Virtual" ? "the-url" : props.updateValue
                  );
                } catch (e) {
                  const res = await e.response.json();
                  if (
                    res.errors &&
                    res.errors[0].details === "data.asset_id should be string"
                  ) {
                    setErrorMessage(
                      "Error Updating: Try Setting Your Image Again"
                    );
                  } else {
                    setErrorMessage("Error Updating:" + res.errors[0].details);
                  }

                  setLoading(false);
                  actions.setSubmitting(false);
                }
              }}
              validationSchema={createEventBasic}
              render={({ isSubmitting }) => (
                <Form>
                  <section>
                    <h2>What do you want to call your gathering?</h2>
                    <p>
                      Pro tip: For book clubs, it’s usually it’s the title of
                      the book.
                    </p>
                    <Input
                      name="eventName"
                      placeholder="What's the name of your event?"
                      type="text"
                    />
                  </section>
                  <section>
                    <h2>Write a small blurb about your event:</h2>
                    <CharacterLimitTextBox
                      placeholder="So excited to discuss “Very Nice” with everyone! You’re invited to my home in Logan Circle to discuss."
                      name="eventBlurb"
                      limit={20000}
                    />
                  </section>
                  <section>
                    <h2>When will it take place?</h2>
                    <p>Hint: At-home book clubs are usually 1.5–2 hours.</p>
                    <FormRow>
                      <DatePicker
                        name="eventDate"
                        label="Event Date"
                        placeholder="MM/DD/YYYY"
                      />
                      <TimePicker
                        name="startTime"
                        label="Start Time"
                        placeholder="6:00 PM"
                      />
                      <TimePicker
                        name="endTime"
                        label="End Time"
                        placeholder="8:00PM"
                      />
                    </FormRow>
                  </section>
                  <FormButton
                    label={
                      props.event.eventLocation &&
                      props.event.eventLocation === "Virtual"
                        ? "Next up: The URL"
                        : "Next up: The Venue"
                    }
                    disabled={isSubmitting}
                  />
                  {errorMessage && <ErrorBanner message={errorMessage} />}
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(TheBasics);
