import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";

import styles from "components/FormElements/FormElements.module.scss";

function TextBox({ label, name, placeholder, rows = "5" }) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <div className={cx(styles.input, { [styles.input__error]: error })}>
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <textarea
              rows={rows}
              name={name}
              aria-label={name}
              {...field}
              placeholder={placeholder}
            />
            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
}

TextBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextBox;
