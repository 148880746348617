import React, { memo } from "react";
import BlockContent from "@sanity/block-content-to-react";
// Components
import Button from "components/Button";
// Styles
import styles from "components/Membership/Membership.module.scss";

const ReadyToLoungeCTA = memo(function ({ readyTitle, readySubtitle = [] }) {
  return (
    <div className={styles.membership__ready__wrapper}>
      <div className={styles.membership__ready}>
        <h2>{readyTitle}</h2>
        <BlockContent blocks={readySubtitle} />
        <Button label="Join the waitlist" link="#waitlist" />
      </div>
    </div>
  );
});

export default ReadyToLoungeCTA;
