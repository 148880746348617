import React, { memo } from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

import parseDate from "utils/parseDate";

import Button from "components/Button";

const NewsLetterItem = ({ newsletter }) => {
  function dateMorph(date) {
    let formattedDate = parseDate(date);
    return `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year} `;
  }

  return (
    <a
      href={newsletter.link}
      className={styles.dashboard__newsletters__item}
      target="_blank"
      rel="noreferrer"
    >
      <h3 className={styles.dashboard__newsletters__item__title}>
        {newsletter.title}
      </h3>
      <div className={styles.dashboard__newsletters__item__date}>
        {dateMorph(newsletter.date)}
      </div>
    </a>
  );
};

const Newsletters = ({
  buttonLink = "#",
  buttonText = "See all past newsletters",
  items = [],
  text = "Missed an old newletter or just don’t want to look at your inbox? We’ve got them all here.",
  title = "Newletter Vault",
}) => {
  return (
    <section className={styles.dashboard__newsletters}>
      <div className={styles.dashboard__newsletters__wrapper}>
        <div className={styles.dashboard__newsletters__header}>
          <h2>{title}</h2>
          <p>{text}</p>
          <img
            src="/icons/Eyemask_forest.png"
            alt="Winking eye mask"
            className={styles.dashboard__newsletters__header__icon}
          />
        </div>
        <div className={styles.dashboard__newsletters__items}>
          <span className={styles.dashboard__newsletters__flag}>This Week</span>
          {items.map((newsletter, i) => (
            <NewsLetterItem key={i} newsletter={newsletter} />
          ))}
        </div>
        <div className={styles.dashboard__newsletters__button}>
          <Button
            label={buttonText}
            link={buttonLink}
            additionalClass="button__transparent"
          />
        </div>
      </div>
    </section>
  );
};

export default memo(Newsletters);
