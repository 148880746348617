import React from "react";
import styles from "components/Shared/MembersGroupCard/MembersGroupCard.module.scss";

function MembersGroupCard({ text, link, count }) {
  return (
    <a className={styles.membersGroupCard} href={link}>
      <span>{text}</span>
      <span>{count} Loungers</span>
    </a>
  );
}

export default MembersGroupCard;
