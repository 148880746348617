import { useEffect } from "react";

function useOutsideClickListener(ref, callback) {
  if (!ref) console.error("ref not defined");
  if (typeof callback !== "function")
    console.error("callback function must be defined");

  function handleClickOutside(event) {
    const clickedOutside = ref.current && !ref.current.contains(event.target);
    callback(clickedOutside);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

export default useOutsideClickListener;
