export function setCurrentUser(user) {
  return {
    type: "SET_CURRENT_USER",
    ...user,
  };
}

export function logout() {
  return {
    type: "LOGOUT_USER",
  };
}

export function updateInfo(user) {
  return {
    type: "UPDATE_INFO",
    ...user,
  };
}
