import React from "react";
import { withRouter, Redirect } from "react-router";

import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";
import EditEvent from "components/Events/EditEvent";
import { getEvent } from "api/event.js";

export default withRouter(function EditEventWrapper(props) {
  const event_id = props.match.params.id;
  return (
    <PageWrapper>
      <DataLoader
        cache={event_id}
        load={async () => {
          const eventQuery = await getEvent(event_id);
          return eventQuery;
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          if (!props.data[0]) return <Redirect to="/" />;
          return <EditEvent eventData={props.data && props.data[0]} />;
        }}
      </DataLoader>
    </PageWrapper>
  );
});
