import React, { useMemo, useState } from "react";
import cx from "classnames";
// Styles
import styles from "components/Members/Members.module.scss";
// Components
import Filter from "components/Shared/Filter";
import FilterButton from "components/Button/FilterButton";
import MembersHeader from "components/Members/MembersHeader";
import Pagination from "components/Shared/Pagination";
import ProfileCardMini from "components/Shared/ProfileCard/ProfileCardMini";
import Sorting from "components/Members/Sorting";
import SuggestedUsers from "components/Members/SuggestedUsers";
//--- Autocomplete Data
import Industries from "components/Shared/AutocompleteData/Industries";
import Cities from "components/Shared/AutocompleteData/Cities";
// Utils
import getHighestRole from "utils/getHighestRole";

const filters = [
  {
    label: "Location",
    filterprop: "community",
    filterArr: Cities,
  },
  {
    label: "Member Type",
    filterprop: "user_roles",
    filterArr: ["Member", "Host", "Admin"],
  },
  {
    label: "Bringing to the Table",
    filterprop: "membership_offering",
    filterArr: [
      "Self-care tips and tricks",
      "Mentorship",
      "Coffee/Activity Buddy",
      "Hosting gatherings",
      "Creative Collaboration",
      "Book conversation",
      "Cozy recs",
      "Event participation",
    ],
  },
  {
    label: "Industry",
    filterprop: "industry",
    filterArr: [...Industries],
  },
];

export default function Members({
  currentPage,
  baseUrl = "/members",
  data,
  isFiltering,
  perPage,
  sortingParam,
  suggestedUsers,
  suggestedValues,
}) {
  const [whichVisible, setWhichVisible] = useState(
    new Array(filters.length).fill(false)
  );
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const { totalUsers = 0 } = useMemo(() => {
    let totalUsers = 0;
    if (data && typeof data === "Array") {
      totalUsers = data[0].userCount;
    }
    return { totalUsers };
  }, [data]);

  return (
    <div className={styles.members}>
      <MembersHeader />
      <SuggestedUsers
        suggestedUsers={suggestedUsers}
        suggestedValues={suggestedValues}
      />
      <div className={styles.members__wrapper}>
        <div className={styles.members__top}>
          <h2>{totalUsers} Loungers</h2>
          <Sorting
            baseUrl={baseUrl}
            isFiltering={isFiltering}
            sortingParam={sortingParam ? sortingParam : "Latest Joined"}
          />
        </div>
        <div className={styles.members__filter}>
          <div className={styles.members__filter__mobile}>
            <FilterButton
              label="Filter"
              onclick={() => setOpenMobileNav(!openMobileNav)}
            />
          </div>
          <div
            className={cx(styles.members__filter__wrapper, {
              [styles.members__filter__wrapper__open]: openMobileNav,
            })}
          >
            <>
              <div
                className={styles.members__filter__mobileClose}
                onClick={() => setOpenMobileNav(false)}
              >
                <svg
                  width="17"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    transform="rotate(45 -.707 1.707)"
                    stroke="#284C4A"
                    d="M0-.5h21.213"
                  />
                  <path
                    transform="rotate(-45 20.126 6.039)"
                    stroke="#284C4A"
                    d="M0-.5h21.213"
                  />
                </svg>
              </div>

              {filters.map((filter, index) => (
                <Filter
                  extraStyles={`buttonSize${index + 1}`}
                  whichVisible={whichVisible}
                  setWhichVisible={setWhichVisible}
                  whichFilterAmI={index}
                  label={filter.label}
                  filterprop={filter.filterprop}
                  filterArr={filter.filterArr}
                  key={`${filter.label}-${index}`}
                />
              ))}
            </>
          </div>
        </div>

        <div className={styles.members__display}>
          {data.length > 0 &&
            data.map((user, i) => (
              <ProfileCardMini
                blurb={user.tagline}
                first_name={user.first_name}
                id={user._id}
                image={user.userImage}
                industry={user.industry}
                instagram={user.instagram}
                isOnDarkBg={true}
                key={i}
                last_name={user.last_name}
                linkedin={user.linkedin}
                location={user.community}
                membership={getHighestRole(user.user_roles)}
                pronouns={user.pronouns}
                website={user.website}
              />
            ))}
        </div>
      </div>
      <div className={styles.members__paginationWrapper}>
        <Pagination
          totalItems={totalUsers}
          currentPage={Number(currentPage)}
          isOnDarkBg
          itemsPerPage={Number(perPage)}
          baseLink="members?"
          additionalStyles="pagination__members"
          additionalItemStyles="pagination__extraItem"
          additionalArrowStyles="pagination__extraArrow"
        />
      </div>
    </div>
  );
}
