import React from "react";
//--- Styles
import styles from "components/Header/Header.module.scss";

export default function BackButton({
  progressStep,
  setCurrentStep,
  previousStep,
}) {
  return (
    <div>
      <div
        className={`${styles.header__back} ${
          progressStep === 0 ? styles.header__back__hide : ""
        }`}
        onClick={() => setCurrentStep(previousStep)}
        style={{ display: `${previousStep === "" ? "none" : "block"}` }}
      >
        <svg
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 1L2 7.5L8 14" stroke="#284C4A" strokeWidth="2" />
        </svg>
        Back
      </div>
    </div>
  );
}
