import React, { useEffect, useState } from "react";
import cx from "classnames";
//--- Styles
import styles from "components/Events/Events.module.scss";

export default function EmailAttendeesModal({
  attendees,
  closeModal,
  eventName,
  hostImage,
}) {
  return (
    <div className={cx(styles.event__modal, styles.event__modal__active)}>
      <div className={styles.event__modal__body__wrapper}>
        <div className={styles.event__modal__body}>
          <div
            className={styles.event__modal__close}
            onClick={() => closeModal("")}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>{eventName}</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <div className={styles.event__modal__left}>
              <div className={styles.event__modal__left__wrapper}>
                <div
                  className={styles.event__modal__left__image}
                  style={{ backgroundImage: `url('${hostImage}')` }}
                ></div>
                <h2>Email Attendees</h2>
                <p>
                  Copy your attendee's email addresses from the right and send
                  them an update!
                </p>
              </div>
            </div>
            <div className={styles.event__modal__right}>
              <div className={styles.event__modal__right__box}>
                {attendees.map((att, i) => (
                  <span>{att.attendee.email}, </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
