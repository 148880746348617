import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
///--- Queries & Actions
import { getArticle } from "api/articles.js";
//--- Components
import DataLoader from "components/DataLoader.js";
import ArticlePage from "components/Article/Article";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default withRouter(
  connect(mapStateToProps)(function Article(props) {
    const pageId = props.match.params.id;
    return (
      <PageWrapper>
        <DataLoader
          load={async () => {
            try {
              const article = await getArticle(pageId);
              return article;
            } catch (e) {
              const res = await e.response.json();
              console.warn("errors", res); // { errors; [ ... ] }
            }
          }}
          fallback={() => {
            return <Loading />;
          }}
        >
          {(props) => {
            return <ArticlePage article={props.data[0]} />;
          }}
        </DataLoader>
      </PageWrapper>
    );
  })
);

function mapStateToProps(state) {
  return {
    ...state,
  };
}
