import React from "react";
import BlockContent from "@sanity/block-content-to-react";
//--- Queries
import { getPublicCMSData } from "api/cms.js";
//--- Styles
import styles from "containers/Terms/Terms.module.scss";
//--- Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default function Terms() {
  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            const pageData = await getPublicCMSData("termsContent");
            return pageData;
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
          return null;
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          let cmsData = props.data && props.data[0] ? props.data[0] : {};
          return (
            <main className={styles.terms}>
              <div className={styles.terms__header}>
                <div className={styles.terms__header__wrapper}>
                  <h1>{cmsData.terms_title}</h1>
                </div>
              </div>

              <div className={styles.terms__body}>
                <BlockContent blocks={cmsData.terms_text} />
              </div>
            </main>
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}
