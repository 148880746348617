import React, { useEffect, useState } from "react";
//--- Styles
import styles from "components/Header/Header.module.scss";

export default function ProgressBar({ totalSteps, progressStep }) {
  const [spanArray, setSpanArray] = useState([]);
  useEffect(() => {
    let tempSpanArray = [];
    for (let i = 0; i < totalSteps; i++) {
      tempSpanArray.push({
        spanNum: i,
      });
    }
    setSpanArray(tempSpanArray);
  }, []);

  return (
    <div className={styles.header__progressBar}>
      {spanArray.map((item, i) => (
        <span
          key={i}
          className={`${styles.header__progressBar__item} ${
            item.spanNum <= progressStep
              ? styles.header__progressBar__item__filled
              : ""
          }`}
        />
      ))}
    </div>
  );
}
