import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
//--- Queries and Actions
import { updatePayment } from "api/user.js";
//--- Utils
import parseDate from "utils/parseDate";
import get from "utils/get";
//--- Styles
import styles from "components/EditAccount/EditAccount.module.scss";
//--- Components
import UpdateStripeForm from "components/Shared/Stripe/UpdateStripeForm";

function Billing({ stripeUser = {}, user = {} }) {
  const [buttonText, setButtonText] = useState("Update");

  const { nextBilling, planType } = useMemo(() => {
    const subscriptions = get(stripeUser, "subscriptions.data.0") || {};
    const date = new Date(get(subscriptions, "current_period_end") * 1000);
    const nextBilling = findNextBilling(date);
    const planType = get(subscriptions, "plan.id") || "";
    return {
      nextBilling,
      planType,
    };
  }, [stripeUser]);

  console.log({ stripeUser });

  function findNextBilling(date) {
    const dateObj = parseDate(date);
    return `${dateObj.month} ${dateObj.day}, ${dateObj.year}`;
  }

  function confirmationFunction() {
    setButtonText("Updated!");

    setTimeout(() => {
      setButtonText("Update Again");
    }, 1500);
  }

  return (
    <div className={styles.editAccount__body}>
      <h2>Membership &amp; Billing</h2>
      <section>
        <h3>Membership Tier</h3>
        <h4>
          {planType === "plan-monthly-001" && "$12/month - Monthly Lounger"}
          {planType === "plan-yearly-001" && "$120/year - Yearly Lounger"}
          {planType === "plan-yearly-002" && "$130/year - Yearly Lounger"}
        </h4>
        <h3>Next Bill</h3>
        <h4>{typeof nextBilling !== "NaN" && nextBilling}</h4>
        <div className={styles.editAccount__body__text}>
          Have questions or need help? Send us an email at{" "}
          <a href="mailto:thelounge@girlsnightin.co">
            thelounge@girlsnightin.co
          </a>
        </div>
      </section>

      <UpdateStripeForm
        user={user}
        stripeUser={stripeUser}
        confirmationFunction={confirmationFunction}
        buttonText={buttonText}
      >
        <h3>Billing Info</h3>
      </UpdateStripeForm>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Billing);
