import React from "react";

import styles from "components/Article/Articles.module.scss";

import ArticleCard from "components/Article/ArticleCard";

export default function ArticleListing(props) {
  return (
    <div className={styles.articles}>
      <div className={styles.articles__wrapper}>
        <div className={styles.articles__header}>
          <h1>Articles</h1>
        </div>
        <div className={styles.articles__body}>
          {props.articles &&
            props.articles.map((item, i) => (
              <ArticleCard
                _id={item._id}
                key={i}
                createdAt={item._createdAt}
                type={item.article_type}
                title={item.article_title}
                previewText={item.article_text_preview}
                image={item.articleImage}
                updatedAt={item._updatedAt}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
