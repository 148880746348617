import React from "react";
import { useHistory } from "react-router-dom";
// Queries
import { getPublicCMSData } from "api/cms.js";
//--- Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import OurStoryPage from "components/OurStory";
import PageWrapper from "components/PageWrapper";
// Utils
import { isAuthenticated } from "utils/isAuthenticated";

function OurStory() {
  const history = useHistory();
  return (
    <PageWrapper
      headerColor="#FFF7EE"
      isPublicPage={true}
      isMembership={true}
      loading={false}
      location={history.location}
      isAuthenticated={isAuthenticated()}
    >
      <DataLoader
        load={async () => {
          try {
            const pageData = await getPublicCMSData("ourStoryContent");
            return pageData;
          } catch (e) {
            const res = await e.response.json();
            console.warn("DataLoader errors", res); // { errors; [ ... ] }
          }
          return null;
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          let cmsData = props.data && props.data[0] ? props.data[0] : {};
          return <OurStoryPage content={cmsData} />;
        }}
      </DataLoader>
    </PageWrapper>
  );
}

export default OurStory;
