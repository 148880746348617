import React from "react";

import styles from "components/HostEvents/HostEvents.module.scss";

import Button from "components/Button";

export default function UnpublishConfirm({
  unpublishEvent,
  closeModal,
  eventId,
}) {
  return (
    <div className={styles.cancel}>
      <div className={styles.cancel__body}>
        <div className={styles.cancel__close} onClick={() => closeModal("")}>
          X
        </div>
        <div className={styles.cancel__body__wrapper}>
          <h3>Are you sure you want to unpublish?</h3>
          <p>This event will be saved in your drafts</p>
          <div className={styles.cancel__buttons}>
            <Button
              label="Yes, Unpublish"
              onclick={unpublishEvent}
              onclickvalue={eventId}
            />
            <Button
              label="No, Nevermind"
              onclick={closeModal}
              onclickvalue={""}
              additionalClass="button__transparent"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
