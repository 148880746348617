import ky from "ky";

export async function getArticle(pageId) {
  const article = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${pageId}"] | order(_updatedAt asc) {
          article_title,
          article_type,
          article_text,
          article_text_preview,
          article_author->{
            _id,
            "authorImage": avatar.asset->url,
            first_name,
            last_name
          },
          "articleImage": article_image.asset->url,
          _createdAt,
          _updatedAt,
        }`,
      },
    })
    .json();
  return article;
}

export async function getArticles(pageNum) {
  let page = `0...20`;
  if (pageNum > 1) {
    page = `${pageNum * 20}...${(pageNum + 1) * 20}`;
  }
  const articles = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type == "articles"] | order(_createdAt desc) {
          _id,
          article_title,
          article_text,
          article_type,
          article_text_preview,
          article_author->{
            _id,
            "authorImage": avatar.asset->url,
            first_name,
            last_name
          },
          "articleImage": article_image.asset->url,
          "articleCount":  count(*[_type == 'articles']),
          _createdAt,
          _updatedAt
        }[${page}]`,
      },
    })
    .json();
  return articles;
}
