import React, { memo } from "react";
// Components
import StoryEventCard from "components/OurStory/StoryEventCard";
// Styles
import styles from "components/OurStory/OurStory.module.scss";

const OurStoryGatherings = memo(function ({
  events,
  gatheringsText,
  gatheringsTitle,
}) {
  return (
    <div className={styles.story__gatherings}>
      <div className={styles.story__gatherings__top}>
        <h2>{gatheringsTitle}</h2>
        <p>{gatheringsText}</p>
      </div>
      <div className={styles.carousel}></div>
      <div className={styles.story__gatherings__events__wrapper}>
        <div className={styles.story__gatherings__events__header}>
          <p>Upcoming</p>
        </div>
        <div className={styles.story__gatherings__events}>
          {events.map((event, i) => {
            return <StoryEventCard event={event} key={i} />;
          })}
        </div>
      </div>
    </div>
  );
});

export default OurStoryGatherings;
