import React, { memo } from "react";
import cx from "classnames";
// Styles
import styles from "components/Shared/Pagination/Pagination.module.scss";

function setPages(currentPage, totalPages) {
  // If there is only one page, return a single element
  if (totalPages === 0 || totalPages === 1) return [1];
  let pagesArray = [];
  // currentPage is the base number for this. 2 up, 2 down
  if (currentPage - 2 > 0) pagesArray.push(currentPage - 2);
  if (currentPage - 1 > 0) pagesArray.push(currentPage - 1);
  pagesArray.push(currentPage);
  if (currentPage + 1 <= totalPages) pagesArray.push(currentPage + 1);
  if (currentPage + 2 <= totalPages) pagesArray.push(currentPage + 2);

  if (pagesArray.length < 5) {
    if (currentPage + 3 <= totalPages) pagesArray.push(currentPage + 3);
    if (currentPage + 4 <= totalPages) pagesArray.push(currentPage + 4);
  }

  return pagesArray;
}
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.get("page")) searchParams.delete("page");
const searchParamString = searchParams.toString();

const Pagination = memo(function ({
  totalItems,
  currentPage,
  itemsPerPage,
  baseLink,
  isOnDarkBg,
}) {
  let totalPages = Math.ceil(totalItems / itemsPerPage);
  let pagesArray = setPages(currentPage, totalPages);
  if (totalItems === 0 || totalPages === 1) return <></>;
  return (
    <div
      className={cx(styles.pagination, {
        [styles.pagination__dark]: isOnDarkBg,
      })}
    >
      {currentPage !== 1 ? (
        <a
          className={cx(styles.pagination__arrow, {
            [styles.pagination__arrow__dark]: isOnDarkBg,
          })}
          href={`/${baseLink}page=${currentPage - 1}${"&" + searchParamString}`}
        >
          <svg
            width="9"
            height="15"
            fill="none"
            style={{ transform: "rotate(180deg)" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 14l6-6.5L1 1" strokeWidth="2" />
          </svg>
        </a>
      ) : (
        <span
          className={cx(
            styles.pagination__arrow,
            styles.pagination__arrow__disabled,
            {
              [styles.pagination__arrow__dark]: isOnDarkBg,
            }
          )}
        >
          <svg
            width="9"
            height="15"
            fill="none"
            style={{ transform: "rotate(180deg)" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 14l6-6.5L1 1" strokeWidth="2" />
          </svg>
        </span>
      )}
      {pagesArray.length > 0 && (
        <>
          {currentPage > 3 && (
            //if currentPage 1 is not showing anymore... aka we are on page 4
            <>
              <a
                href={`/${baseLink}page=${1}${"&" + searchParamString}`}
                className={cx(styles.pagination__item, {
                  [styles.pagination__item__active]: currentPage === 1,
                  [styles.pagination__item__dark]: isOnDarkBg,
                  [styles.pagination__item__dark__active]:
                    isOnDarkBg && currentPage === 1,
                })}
              >
                {1}
              </a>
              <p>...</p>
            </>
          )}
          {pagesArray.map((item, i) => (
            <a
              href={`/${baseLink}page=${item}${"&" + searchParamString}`}
              key={i}
              className={cx(styles.pagination__item, {
                [styles.pagination__item__active]: currentPage === item,
                [styles.pagination__item__dark]: isOnDarkBg,
                [styles.pagination__item__dark__active]:
                  isOnDarkBg && currentPage === item,
              })}
            >
              {item}
            </a>
          ))}
          {currentPage < totalPages - 2 && (
            // if final page is not showing... aka we are 3 away from total pages
            <>
              <p>...</p>
              <a
                href={`/${baseLink}page=${totalPages}${
                  "&" + searchParamString
                }`}
                className={cx(styles.pagination__item, {
                  [styles.pagination__item__active]: currentPage === totalPages,
                  [styles.pagination__item__dark]: isOnDarkBg,
                  [styles.pagination__item__dark__active]:
                    isOnDarkBg && currentPage === totalPages,
                })}
              >
                {totalPages}
              </a>
            </>
          )}
        </>
      )}

      {!(currentPage >= totalPages) ? (
        <a
          className={cx(styles.pagination__arrow, {
            [styles.pagination__arrow__dark]: isOnDarkBg,
          })}
          href={`/${baseLink}page=${currentPage + 1}${"&" + searchParamString}`}
        >
          <svg
            width="9"
            height="15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 14l6-6.5L1 1" strokeWidth="2" />
          </svg>
        </a>
      ) : (
        <span
          className={cx(
            styles.pagination__arrow,
            styles.pagination__arrow__disabled,
            {
              [styles.pagination__arrow__dark]: isOnDarkBg,
            }
          )}
        >
          <svg
            width="9"
            height="15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 14l6-6.5L1 1" strokeWidth="2" />
          </svg>
        </span>
      )}
    </div>
  );
});

export default Pagination;
