import React from "react";

import styles from "components/HostEvents/HostEvents.module.scss";

import Button from "components/Button";

function WantToHost() {
  return (
    <div className={styles.hosting}>
      <div className={styles.hosting__wrapper}>
        <div className={styles.hosting__image}>
          <img src="icons/Salad.png" alt="Salad in a bowl" />
        </div>
        <div className={styles.hosting__body}>
          <h1>Want to Host?</h1>
          <p>
            You’re not currently a Lounge host. Would you like to apply to host
            your own gatherings?
          </p>
          <Button
            linkTarget="_blank"
            link="https://docs.google.com/forms/d/e/1FAIpQLSeNCli_Yf9XMy9I9et_IGU3Ce5Epihf1EmATvk5rlUB70Sjgw/viewform"
            label="Apply to Host"
          />
        </div>
      </div>
    </div>
  );
}

export default WantToHost;
