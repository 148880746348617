import ky from "ky";

export async function getAnnouncement(pageId) {
  const announcement = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${pageId}"] {
          announcement_title,
          announcement_text,
          announcement_text_preview,
          "announceImage": announcement_image.asset->url,
          _createdAt,
          _updatedAt
        }`,
      },
    })
    .json();
  return announcement;
}

export async function getAnnouncements(page = "0...12") {
  // let page = `0...20`;
  // if (pageNum > 1) {
  //   page = `${pageNum * 20}...${(pageNum + 1) * 20}`;
  // }
  const announcements = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type == "announcements" || _type == "articles"] | order(_updatedAt desc) {
          _id,
          announcement_title,
          announcement_text_preview,
          "announceImage": announcement_image.asset->url,
          article_title,
          article_text,
          article_type,
          article_text_preview,
          article_author->{
            _id,
            "authorImage": avatar.asset->url,
            first_name,
            last_name
          },
          "articleImage": article_image.asset->url,
          "count":  count(*[_type == 'announcements' || _type == 'articles']),
          _createdAt,
          _updatedAt
        }[${page}]`,
      },
    })
    .json();
  return announcements;
}
