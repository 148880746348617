import React from "react";

import styles from "components/HostEvents/HostEvents.module.scss";

import Button from "components/Button";

function CreateGathering() {
  return (
    <div className={styles.hosting}>
      <div className={styles.hosting__wrapper}>
        <div className={styles.hosting__image}>
          <img src="icons/Plant_forest.png" alt="A plant" />
        </div>
        <div className={styles.hosting__body}>
          <h1>Ready to host something?</h1>
          <p>Looks like you haven’t hosted any gatherings yet!</p>
          <Button link="/create-event" label="Create a gathering" />
        </div>
      </div>
    </div>
  );
}

export default CreateGathering;
