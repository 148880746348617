import React from "react";
import { withRouter } from "react-router";

import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";
import UserProfile from "components/UserProfile";
import { getUser } from "api/user.js";

export default withRouter(function Profile(props) {
  const user_id = props.match.params.id;
  return (
    <PageWrapper>
      <DataLoader
        cache={user_id}
        load={async () => {
          const userQuery = await getUser(user_id);
          return userQuery;
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          return <UserProfile userData={props} />;
        }}
      </DataLoader>
    </PageWrapper>
  );
});
