import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
//--- Queries & Actions
import { UpdateAccountInfoSchema } from "utils/validationSchema";
import { updateUser } from "api/user.js";
//--- Styles
import styles from "components/EditAccount/EditAccount.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import SelectBox from "components/FormElements/SelectBox";

//--- Autocomplete Data
import DCNeigborhoods from "components/Shared/AutocompleteData/DCNeighborhoods";
import NYCCNeigborhoods from "components/Shared/AutocompleteData/NYC";
import Cities from "components/Shared/AutocompleteData/Cities";

function EditInfo(props) {
  const [buttonText, setButtonText] = useState("Update Info");
  const [community, selectCommunity] = useState("");
  const [listValue, setListValue] = useState([]);

  function changeCommunity(val) {
    selectCommunity(val);
    if (val === "Washington D.C.") {
      return setListValue(DCNeigborhoods);
    } else if ((val = "New York City")) {
      return setListValue(NYCCNeigborhoods);
    } else {
      //--- Hide Neighborhood Input
      return setListValue([]);
    }
  }

  useEffect(() => {
    props.user.community && changeCommunity(props.user.community);
  }, []);

  return (
    <Formik
      initialValues={{
        first_name: props.user.first_name || "",
        last_name: props.user.last_name || "",
        neighborhood: props.user.neighborhoods
          ? props.user.neighborhoods[0]
          : "",
      }}
      validationSchema={UpdateAccountInfoSchema}
      onSubmit={async (values, actions) => {
        const { first_name, last_name, neighborhood } = values;
        setButtonText("Updating...");

        updateUser(props.user.user_id, {
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          community,
          neighborhoods: [neighborhood],
        })
          .then(async function onSuccess() {
            props.updateUserInfo(values);
            actions.resetForm(values);
            setButtonText("Updated!");

            setTimeout(() => {
              actions.setSubmitting(false);
              setButtonText("Update Info Again");
            }, 750);
          })
          .catch(async function (err) {
            console.error(err);
            actions.setSubmitting(false);
          });
      }}
      render={({ errors, isSubmitting }) => (
        <Form className={styles.form}>
          <section>
            <FormRow>
              <Input
                label="First Name"
                name="first_name"
                placeholder="First Name"
                type="text"
              />
              <Input
                label="Last Name"
                name="last_name"
                placeholder="Last Name"
                type="text"
              />
            </FormRow>
            <div className={styles.editAccount__locations}>
              {Cities.map((city, i) => (
                <SelectBox
                  currentValue={community}
                  key={i}
                  onclick={changeCommunity}
                  value={city}
                />
              ))}

              {/* --- Left here for when we get icons for all cities
              <LocationCard
                selectedValue={community}
                text="Washington D.C."
                image={{url: '/icons/washingtondc.svg', alt: 'Washington DC Building'}}
                onclick={changeCommunity}
                value="Washington D.C."
              /> */}
            </div>
            {listValue.length > 0 && (
              <AutocompleteInput
                initialValue={
                  (props.user.neighborhoods && props.user.neighborhoods[0]) ||
                  ""
                }
                label="Neighborhood"
                name="neighborhood"
                placeholder="Enter Neighborhood"
                type="text"
                listValues={listValue}
              />
            )}
          </section>

          <FormButton label={buttonText} disabled={isSubmitting} />
        </Form>
      )}
    />
  );
}

export default EditInfo;
