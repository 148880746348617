import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./Header.module.scss";

function NavItem({
  color = "forest",
  type = "text",
  text = "",
  link = "/",
  active = false,
}) {
  return (
    <div
      className={cx(styles.navItem, {
        [styles.navItem__button]: type === "button",
        [styles.navItem__active]: active,
        [styles.navItem__white]: color === "#F9F3EB",
        [styles.navItem__offwhite]: color === "#FFF7EE",
      })}
    >
      <a href={link} aria-label={text}>
        {text}
      </a>
    </div>
  );
}

NavItem.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};

export default NavItem;
