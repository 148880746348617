import React from "react";
import cx from "classnames";

import styles from "components/EditAccount/EditAccount.module.scss";

function Navigation({ activeItem, setActive }) {
  return (
    <div className={styles.editAccount__nav}>
      <div
        className={cx(styles.editAccount__nav__item, {
          [styles.editAccount__nav__item__active]: activeItem === "details",
        })}
        onClick={() => setActive("details")}
      >
        Profile Details
      </div>
      <div
        className={cx(styles.editAccount__nav__item, {
          [styles.editAccount__nav__item__active]: activeItem === "account",
        })}
        onClick={() => setActive("account")}
      >
        Account Settings
      </div>
      <div
        className={cx(styles.editAccount__nav__item, {
          [styles.editAccount__nav__item__active]: activeItem === "membership",
        })}
        onClick={() => setActive("membership")}
      >
        <span>
          <span className={styles.hideSmall}>Membership &amp; </span>Billing
        </span>
      </div>
      {/* <div
        className={cx(
          styles.editAccount__nav__item,
          {[styles.editAccount__nav__item__active]: activeItem === 'notification'}
        )}
        onClick={() => setActive('notification')}
      >
        Notifications
      </div> */}
      <a className={styles.editAccount__nav__item} href="/community-guidelines">
        <span>
          <span className={styles.hideSmall}>Community </span>Guidelines
        </span>
      </a>
      <a className={styles.editAccount__nav__item} href="/support">
        Support
      </a>
      <div className={styles.editAccount__nav__illo}>
        <img src="/icons/Coffee_forest.png" alt="coffee cup" />
      </div>
    </div>
  );
}

export default Navigation;
