import React from "react";
import PropTypes from "prop-types";
//--- Styles
import styles from "components/Header/Header.module.scss";
//--- Components
import BackButton from "components/Button/BackButton";
import FullLoungeLogo from "components/Logos/FullLoungeLogo";
import ProgressBar from "components/Shared/ProgressBar";

export default function SimpleHeader({
  setCurrentStep,
  previousStep,
  progressStep,
  totalSteps,
}) {
  return (
    <header className={styles.header}>
      <div
        className={`${styles.header__wrapper} ${styles.header__wrapper__simple}`}
      >
        <BackButton
          progressStep={progressStep}
          setCurrentStep={setCurrentStep}
          previousStep={previousStep}
        />
        <div className={styles.header__logo}>
          <FullLoungeLogo fillColor="#F5C38F" />
        </div>
      </div>
      <ProgressBar progressStep={progressStep} totalSteps={totalSteps} />
    </header>
  );
}

SimpleHeader.propTypes = {
  setCurrentStep: PropTypes.func,
  previousStep: PropTypes.string,
  progressStep: PropTypes.number,
  totalSteps: PropTypes.number,
};
