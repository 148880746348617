/**
 * This tiny/quick alternative to Lodash's get method has one limitation:
 * it only supports dot notation.
 *
 * So treat arrays like objects, instead of 👎'arr[0]', use 'arr.0'👍
 *
 * @param  {object} object       the object in which you're looking for the value
 * @param  {string} path         the dot notation path to the value
 * @param  {mixed}  defaultValue if this function resolves to 'undefined'
 *                               this value is returned instead
 *
 * @return {mixed}               the value at the path, or defaultValue
 */
function get(object, path, defaultValue) {
  const parts = path.split('.');

  for (let i = 0; i < parts.length; i++) {
    object = object[parts[i]];

    if (typeof object === 'undefined') {
      return defaultValue
    }
  }

  return object
}

export default get;