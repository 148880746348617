import React from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

import Button from "components/Button/index.js";

function MorningTea({ buttonLink, buttonText, items = [], text, title }) {
  return (
    <div className={styles.dashboard__morningtea}>
      <div className={styles.dashboard__morningtea__wrapper}>
        <div className={styles.dashboard__morningtea__header}>
          <h2>{title}</h2>
          <p>{text}</p>
          <img
            src="/icons/Coffee-colored.png"
            alt="Coffee mug"
            className={styles.dashboard__morningtea__header__icon}
          />
        </div>
        <div className={styles.dashboard__morningtea__items}>
          {items.map((item, i) => {
            if (i === 0) {
              return (
                <a
                  href={item.link}
                  className={styles.dashboard__morningtea__items__main}
                  key={i}
                >
                  <h3>{item.title}</h3>
                  <p>{item.loungers_text}</p>
                  <img
                    src="/icons/hot_topic.png"
                    alt="Hot Topic"
                    className={styles.dashboard__morningtea__items__main__icon}
                  />
                </a>
              );
            }
          })}
          <div className={styles.dashboard__morningtea__items__otherItems}>
            {items.map((item, i) => {
              if (i !== 0) {
                return (
                  <a
                    key={i}
                    className={styles.dashboard__morningtea__item}
                    href={item.link}
                  >
                    <h3>{item.title}</h3>
                    <p>{item.loungers_text}</p>
                  </a>
                );
              }
            })}
          </div>
        </div>
        <div className={styles.dashboard__morningtea__button}>
          <Button
            label={buttonText}
            link={buttonLink}
            additionalClass="button__transparent"
          />
        </div>
      </div>
    </div>
  );
}

export default MorningTea;
