import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useHistory } from "react-router-dom";
//--- Queries and Actions
import { updatePayment, updateUser, processPayment } from "api/user.js";
import { updateInfo } from "state/actions/userActions.js";
//--- Utils
// import parseDate from "utils/parseDate";
import get from "utils/get";
//--- Styles
import styles from "components/CancelledPage/CancelledPage.module.scss";
import signupstyles from "components/SignupFlow/SignupFlow.module.scss";
//--- Components
import StripeForm from "components/Shared/Stripe/StripeForm";

// TODO:
// Verify entering new card sets user as non-delinquent
// "Paused" accounts

function CancelledPage({ stripeUser, user, ...props }) {
  const [buttonText, setButtonText] = useState("Renew Subscription");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorFromServer, setErrorFromServer] = useState(null);
  const history = useHistory();

  const {
    subscriptions,
    // nextBilling,
    planType,
  } = useMemo(() => {
    const subscriptions = get(stripeUser, "subscriptions.data.0") || false;
    // const date = new Date(get(subscriptions, "current_period_end") * 1000);
    // const nextBilling = findNextBilling(date);
    const planType = get(subscriptions, "plan.id") || false;
    return {
      subscriptions,
      // nextBilling,
      planType,
    };
  }, [stripeUser]);

  async function onSuccess() {
    //--- Update Sanity with Profile Information
    const result = await updateUser(user.user_id, {
      subscription_status: "active",
      stripe_source: stripeUser.id,
    });
    if (result) {
      await props.dispatch(
        updateInfo({ ...user, subscription_status: "active" })
      );
      await history.push("/");
    }
  }

  // function findNextBilling(date) {
  //   const dateObj = parseDate(date);
  //   return `${dateObj.month} ${dateObj.day}, ${dateObj.year + 1}`;
  // }

  const userName = user ? `${user.first_name} ${user.last_name}` : "";

  async function handleSubmit(stripeUser) {
    let errorMessage;
    setButtonDisabled(true);
    //--- Add the new source to Stripe
    try {
      if (subscriptions) {
        const {
          success,
          // reason,
          // paymentStatus,
          // paymentIntentSecret,
        } = await updatePayment(user.user_id, stripeUser.id);
        if (success) {
          // Update Sanity User
          await onSuccess();
          setButtonDisabled(false);
          setButtonText("Updated!");
        }
      } else {
        // Selected Product
        const product_select = selectedProduct;
        // Create New Subscription
        const {
          success,
          // reason,
          // paymentStatus,
          // paymentIntentSecret,
        } = await processPayment(
          product_select,
          stripeUser.id,
          user.user_id,
          user.email,
          `${user.first_name} ${user.first_name}`
        );
        if (success) {
          // Update Sanity User
          await onSuccess();
          setButtonDisabled(false);
          setButtonText("Updated!");
        }
      }
    } catch (e) {
      if (e.response) {
        const res = await e.response.json();
        const { errors } = res || {};
        errorMessage =
          Array.isArray(errors) && errors.length > 0 && errors[0].details;
        console.warn("errors", res);

        if (errorMessage === "Invoice is already paid") {
          setTimeout(() => onSuccess("active"), 1000);
        }
      } else {
        console.error(e);
      }
      setErrorFromServer(
        errorMessage ||
          "Payment could not be processed. Please contact us for help."
      );
      setButtonDisabled(false);
    }

    setTimeout(() => {
      setButtonText("Update Again");
    }, 1500);
  }

  return (
    <div className={styles.cancelledPage}>
      <h2>Renew Your Account</h2>
      {stripeUser && (
        <h3>
          {stripeUser.subscriptions.total_count === 0 &&
            "Your subscription had been cancelled"}
          {stripeUser.delinquent && "Your payment information is out of date"}
        </h3>
      )}
      <p>
        Your credit card is out of date, we were unable to charge you, or you
        have cancelled your account. Please update your information below to
        continue using The Lounge.
      </p>
      <section>
        <h3>Your Membership Tier</h3>
        {planType && (
          <h4>
            {planType === "plan-monthly-001" && "$12/month - Monthly Lounger"}
            {planType === "plan-yearly-001" && "$120/year - Yearly Lounger"}
            {planType === "plan-yearly-002" && "$130/year - Yearly Lounger"}
          </h4>
        )}
        {!planType && (
          <div className={signupstyles.signup__productSelect}>
            <div
              className={cx(signupstyles.signup__productSelect__item, {
                [signupstyles.signup__productSelect__item__active]:
                  selectedProduct === "annual",
              })}
              onClick={() => setSelectedProduct("annual")}
            >
              <span>10% savings</span>
              <h3 style={{ textAlign: "center" }}>ANNUAL</h3>
              <h4>$130</h4>
              <p>per year</p>
            </div>
            <div
              className={cx(signupstyles.signup__productSelect__item, {
                [signupstyles.signup__productSelect__item__active]:
                  selectedProduct === "monthly",
              })}
              onClick={() => setSelectedProduct("monthly")}
            >
              <h3 style={{ textAlign: "center" }}>MONTHLY</h3>
              <h4>$12</h4>
              <p>per month</p>
            </div>
          </div>
        )}
        <div className={styles.cancelledPage__body__text}>
          Have questions or need help? Send us an email at{" "}
          <a href="mailto:thelounge@girlsnightin.co">
            thelounge@girlsnightin.co
          </a>
        </div>
      </section>
      <StripeForm
        userName={userName}
        onSubmit={handleSubmit}
        className={styles.stripeForm}
        buttonText={buttonText}
        buttonDisabled={buttonDisabled}
        selectedProduct={subscriptions ? "renewal" : selectedProduct}
      >
        <h3>Billing Info</h3>
      </StripeForm>
      {errorFromServer && <p style={{ color: "red" }}>{errorFromServer}</p>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CancelledPage);
