import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";

function TheURL(props) {
  const cmsData = props.cmsData ? props.cmsData : {};

  return (
    <div className={styles.createEvent}>
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          cmsImage={cmsData.url_side_image ? cmsData.url_side_image : null}
          dynamicText={true}
          title={cmsData.url_side_title ? cmsData.url_side_title : "The URL"}
          text={cmsData.url_side_text ? cmsData.url_side_text : null}
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{
                url: props.event.url ? props.event.url : "",
              }}
              onSubmit={async (values) => {
                const { url } = values;
                props.dispatch(
                  createEventAction({
                    ...props.event,
                    url,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ isSubmitting }) => (
                <Form>
                  <section>
                    <Input label="Zoom Link" name="url" type="string" />
                  </section>
                  <FormButton
                    label="Next Up: The Details"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(TheURL);
