import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
import TinyDatePicker from "tiny-date-picker";

import formatDate from "utils/formatDate";

import styles from "components/FormElements/FormElements.module.scss";
import dpStyles from "components/FormElements/DatePicker.module.scss";

import "tiny-date-picker/tiny-date-picker.css";

function DatePickerInner({ field, form, placeholder }) {
  const { name, value, onBlur } = field;
  let dateInput = useRef();

  useEffect(() => {
    const dp = TinyDatePicker(dateInput.current, {
      min: new Date(),
    });

    dp.on("select", (_, dp) => {
      form.setFieldValue(name, dp.state.selectedDate);
    });
  }, []);

  const displayDate = formatDate(value);

  return (
    <input
      aria-label={name}
      value={displayDate}
      name={name}
      onBlur={onBlur}
      placeholder={placeholder}
      ref={dateInput}
      readOnly
    />
  );
}

function DatePicker({ label, name, placeholder }) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <div className={cx(styles.input, { [styles.input__error]: error })}>
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <DatePickerInner
              field={field}
              form={form}
              placeholder={placeholder}
            />
            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
}

DatePicker.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DatePicker;
