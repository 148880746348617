import React from "react";
import { connect } from "react-redux";
import { getCMSData } from "api/cms.js";

import ComponentHandler from "containers/Onboarding/ComponentHandler";
import SimpleHeader from "components/Header/SimpleHeader";
import { setCreateAccountContent } from "state/actions/cmsContentActions.js";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userStep: "",
      stepNumber: 0,
      previousStep: "",
    };
  }

  async componentDidMount() {
    if (!this.props.cms.onboardingContent) {
      try {
        const pageData = await getCMSData("onboardingContent");
        this.props.dispatch(
          setCreateAccountContent({
            ...this.props.cms,
            onboardingContent: pageData[0],
          })
        );
      } catch (e) {
        const res = await e.response.json();
        console.warn("errors", res); // { errors; [ ... ] }
      }
    }
    this.setState({
      userStep: "basics",
    });
  }

  regressFlow = (currentStep) => {
    switch (currentStep) {
      case "basics":
        return "";
      case "shipping":
        return "basics";
      case "details":
        return "shipping";
      case "interests":
        return "details";
      case "social":
        return "interests";
      case "hopes-dreams":
        return "social";
      case "bring-to-table":
        return "hopes-dreams";
      case "overview":
        return "bring-to-table";
      default:
        return "";
    }
  };

  progressFlow = (updateValue) => {
    this.setState({
      previousStep: this.regressFlow(updateValue),
      userStep: updateValue,
      stepNumber: this.state.stepNumber + 1,
    });
  };

  render() {
    return (
      <div>
        <SimpleHeader
          setCurrentStep={this.progressFlow}
          previousStep={this.state.previousStep}
          progressStep={this.state.stepNumber}
          totalSteps={7}
        />
        <ComponentHandler
          cmsData={
            this.props.cms.onboardingContent
              ? this.props.cms.onboardingContent
              : {}
          }
          setCurrentStep={this.progressFlow}
          signupStep={this.state.userStep}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Onboarding);
