import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logout } from "api/auth.js";
import { logout as logoutUser } from "state/actions/userActions.js";
import { resetCreateEvent } from "state/actions/createEventActions.js";

export default connect()(
  withRouter(
    class Logout extends React.Component {
      componentDidMount() {
        logout();
        this.props.dispatch(logoutUser(), resetCreateEvent());
        sessionStorage.clear();
        this.props.history.push({ pathname: "/" });
      }

      render() {
        return null;
      }
    }
  )
);
