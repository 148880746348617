import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "components/FormElements/FormElements.module.scss";

function SelectBox({ onclick, value, currentValue }) {
  return (
    <div
      className={cx(styles.selectBox, {
        [styles.selectBox__active]: currentValue === value,
      })}
      onClick={() => onclick(value)}
    >
      {value}
    </div>
  );
}

SelectBox.propTypes = {
  onclick: PropTypes.func,
  value: PropTypes.string,
  currentValue: PropTypes.string,
};

export default SelectBox;
