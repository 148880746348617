import React from "react";
import BlockContent from "@sanity/block-content-to-react";
//--- Styles
import styles from "components/Article/Articles.module.scss";
//--- Components
import ArticleHeader from "components/Article/ArticleHeader";

export default function Article({ article }) {
  return (
    <div className={styles.article}>
      <ArticleHeader
        title={article.article_title}
        type={article.article_type}
        createdAt={article._createdAt}
        image={article.articleImage}
        author={article.article_author}
        preview={article.article_text_preview}
        updatedAt={article._updatedAt}
      />
      <div className={styles.article__body}>
        <BlockContent blocks={article.article_text} />
      </div>
    </div>
  );
}
