import React from "react";
import { Redirect } from "react-router-dom";

import BasicInfo from "components/Onboarding/BasicInfo";
import Loading from "components/Loading";
import Shipping from "components/Onboarding/Shipping";
import Details from "components/Onboarding/Details";
import Interests from "components/Onboarding/Interests";
import Social from "components/Onboarding/Social";
import HopesDreams from "components/Onboarding/HopesDreams";
import BringToTable from "components/Onboarding/BringToTable";
import Overview from "components/Onboarding/Overview";

function ComponentHandler({ signupStep, setCurrentStep, cmsData }) {
  switch (signupStep) {
    case "basics":
      return (
        <BasicInfo
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="shipping"
        />
      );
    case "shipping":
      return (
        <Shipping
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="details"
        />
      );
    case "details":
      return (
        <Details
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="interests"
        />
      );
    case "interests":
      return (
        <Interests
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="social"
        />
      );
    case "social":
      return (
        <Social
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="hopes-dreams"
        />
      );
    case "hopes-dreams":
      return (
        <HopesDreams
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="bring-to-table"
        />
      );
    case "bring-to-table":
      return (
        <BringToTable
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="overview"
        />
      );
    case "overview":
      return (
        <Overview
          cmsData={cmsData}
          updateFn={setCurrentStep}
          updateValue="to-dashboard"
        />
      );
    case "to-dashboard":
      return <Redirect to="/" />;
    default:
      return <Loading active={true} />;
  }
}

export default ComponentHandler;
