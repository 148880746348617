import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          rest.user.access_token &&
          rest.user.subscription_status === "pending"
        ) {
          return (
            <Redirect
              to={{
                pathname: "/signup",
                state: { from: props.location },
              }}
            />
          );
          // } else if (
          //   rest.user.access_token &&
          //   (rest.user.subscription_status === "cancelled" ||
          //     rest.user.subscription_status === "delinquent")
          // ) {
          //   return (
          //     <Redirect
          //       to={{
          //         pathname: "/update-membership",
          //         state: { from: props.location },
          //       }}
          //     />
          //   );
        } else if (rest.user.access_token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default connect((state) => ({ user: state.user }))(PrivateRoute);
