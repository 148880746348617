import React, { useCallback, useEffect, useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { connect } from "react-redux";
import cx from "classnames";
//--- Utils
// import useOutsideClickListener from "utils/useOutsideClickListener";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import Button from "components/Button";
import EventCard from "components/Shared/EventCard";
import EventCardMini from "components/Shared/EventCard/EventCardMini";
import Pagination from "components/Shared/Pagination";
import Filter from "components/Shared/Filter";
import FilterButton from "components/Button/FilterButton";
// Autocomplete
import Cities from "components/Shared/AutocompleteData/Cities";

// URL Params:
// venueType
// type: eventType
// community

const filters = [
  {
    label: "Type",
    filterprop: "type",
    filterArr: [
      "Book club",
      "Food",
      "Cookbook club",
      "Discussion",
      "Swap",
      "Crafts",
      "Wellness",
      "Entertainment",
      "Something else",
    ],
  },
  {
    label: "Location",
    filterprop: "community",
    filterArr: [...Cities],
  },
  {
    label: "Upcoming/Past",
    filterprop: "eventRange",
    filterArr: ["Upcoming", "Past"],
  },
];

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={`${styles.events__attending__button} ${styles.events__attending__button__prev}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
      <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
    </svg>
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={`${styles.events__attending__button} ${styles.events__attending__button__next}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
      <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
    </svg>
  </button>
);

function EventListing({
  events,
  cmsData = null,
  currentPage,
  baseUrl = "/gatherings",
  perPage,
  venueType = [""],
}) {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const emblaOptions = {
    align: "start",
    draggable: true,
    loop: false,
    slidesToScroll: 1,
    // containScroll: "trimSnaps",
  };
  const [emblaRef, embla] = useEmblaCarousel(emblaOptions);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla]);

  const totalEvents = events[0] ? events[0].eventCount : 0;
  const [whichVisible, setWhichVisible] = useState(
    new Array(filters.length).fill(false)
  );
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const eventsRSVP =
    cmsData && cmsData[0].eventRSVPS ? cmsData[0].eventRSVPS : [];

  return (
    <div className={styles.events}>
      <section className={styles.events__header}>
        <div className={styles.events__header__wrapper}>
          <h1>Gather and connect, online and offline.</h1>
        </div>
        <div className={styles.events__header__illo}>
          <img
            src="/icons/gathering_forest.png"
            alt="women sitting around drinking tea"
          />
        </div>
      </section>

      <section className={styles.events__attending}>
        <h2>You're Going</h2>

        {eventsRSVP.length > 0 ? (
          <>
            <div className={styles.events__attending__wrapper} ref={emblaRef}>
              <div className={styles.events__attending__items}>
                {eventsRSVP.map((event, i) => (
                  <EventCardMini details={event} key={i} />
                ))}
              </div>
            </div>
            {eventsRSVP.length > 1 && (
              <>
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
              </>
            )}
          </>
        ) : (
          <div className={styles.events__attending__empty}>
            <h4>You aren't RSVP'd to any gatherings.</h4>
          </div>
        )}
      </section>

      <section className={styles.events__filter}>
        <div className={styles.events__filtering}>
          <h2>Find Gatherings</h2>
          <div className={styles.events__filtering__type}>
            <a
              className={cx(styles.events__filtering__type__item, {
                [styles.events__filtering__type__item__active]:
                  venueType[0] === "",
              })}
              href={baseUrl}
            >
              All
            </a>
            <a
              className={cx(styles.events__filtering__type__item, {
                [styles.events__filtering__type__item__active]:
                  venueType[0] === "Virtual",
              })}
              href={`${baseUrl}venue_type=Virtual`}
            >
              Virtual
            </a>
            <a
              className={cx(styles.events__filtering__type__item, {
                [styles.events__filtering__type__item__active]:
                  venueType[0] === "inperson",
              })}
              href={`${baseUrl}venue_type=inperson`}
            >
              In Person
            </a>
          </div>

          <div className={styles.events__filtering__options}>
            <div className={styles.events__filtering__mobile}>
              <FilterButton
                label="Filter"
                lightBackground
                onclick={() => setOpenMobileNav(!openMobileNav)}
              />
            </div>

            <div
              className={cx(styles.events__filtering__wrapper, {
                [styles.events__filtering__wrapper__open]: openMobileNav,
              })}
            >
              <div
                className={styles.events__filtering__mobileClose}
                onClick={() => setOpenMobileNav(false)}
              >
                <svg
                  width="17"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    transform="rotate(45 -.707 1.707)"
                    stroke="#284C4A"
                    d="M0-.5h21.213"
                  />
                  <path
                    transform="rotate(-45 20.126 6.039)"
                    stroke="#284C4A"
                    d="M0-.5h21.213"
                  />
                </svg>
              </div>

              {filters.map((filter, index) => (
                <Filter
                  extraStyles={`buttonSize${index + 1}`}
                  whichVisible={whichVisible}
                  setWhichVisible={setWhichVisible}
                  whichFilterAmI={index}
                  label={filter.label}
                  lightBackground={true}
                  filterprop={filter.filterprop}
                  filterArr={filter.filterArr}
                  key={`${filter.label}-${index}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.events__filter__invite}>
          {cmsData && (
            <div className={styles.explanationCard}>
              <div className={styles.explanationCard__cta}>
                <h2>{cmsData[0].title}</h2>
                {cmsData[0].cta_text && (
                  <Button
                    label={cmsData[0].cta_text}
                    link={cmsData[0].cta_link}
                    additionalClass="button__smaller"
                  />
                )}
              </div>
              <div className={styles.explanationCard__text}>
                <p>{cmsData[0].text}</p>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className={styles.events__list}>
        {events.length === 0 && (
          <div className={styles.events__list__noEvents}>
            <h2>No Gatherings To Display</h2>
            <Button label="View All Events" link="/gatherings" />
          </div>
        )}
        {events &&
          events.map((event, i) => {
            const isRSVPd = eventsRSVP.find((ev) => ev._id === event._id);
            return <EventCard details={event} isRSVPd={isRSVPd} key={i} />;
          })}
      </section>
      {totalEvents > perPage && (
        <Pagination
          totalItems={totalEvents}
          currentPage={Number(currentPage)}
          itemsPerPage={Number(perPage)}
          baseLink="gatherings?"
          additionalItemStyles="pagination__extraItem"
          additionalArrowStyles="pagination__extraArrow"
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EventListing);
