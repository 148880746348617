import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries
import { updateEvent, updateEventImage } from "api/event";
//--- Utils
import getISODate from "utils/getISODate";
import parseDate from "utils/parseDate";
//--- Styles
import styles from "components/Events/Events.module.scss";
import createStyles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import DatePicker from "components/FormElements/DatePicker";
import ErrorBanner from "components/Shared/ErrorBanner";
import EventImageUpload from "components/Shared/ImageUpload/EventImageUpload";
import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import Loading from "components/Loading";
import SelectBox from "components/FormElements/SelectBox";
import StateSelector from "components/FormElements/StateSelector";
import TextBox from "components/FormElements/TextBox";
import TimePicker from "components/FormElements/TimePicker";
//--- Autocomplete Data
import DCNeighborhoods from "components/Shared/AutocompleteData/DCNeighborhoods";
import NYCCNeigborhoods from "components/Shared/AutocompleteData/NYC";

const EVENT_LOCATION = [
  {
    name: "Virtual",
  },
  {
    name: "In Person",
  },
];

const AMENITIES = [
  {
    name: "Light Snacks",
  },
  {
    name: "Alcoholic Beverages",
  },
  {
    name: "Non-alcoholic Beverages",
  },
  {
    name: "Handicap Accessible",
  },
  {
    name: "Pet(s) on Premises",
  },
];

const TOPICS = [
  "Books",
  "Food",
  "Discussion",
  "Swap",
  "Crafts",
  "Wellness",
  "Entertainment",
  "Something Else",
];

function EditEvent({ eventData }) {
  const [eventLocation, selectEventLocation] = useState("");
  const [eventType, selectEventType] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [updateValue, forceUpdate] = useState(1);
  const [suggested, setSuggested] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventImageSource, setEventImageSource] = useState(false);
  const [eventImagePreview, setEventImagePreview] = useState(false);
  const [listValue, setListValue] = useState(DCNeighborhoods);

  function changeCommunity(val) {
    if (val === "Washington D.C.") {
      return setListValue(DCNeighborhoods);
    } else if ((val = "New York City")) {
      return setListValue(NYCCNeigborhoods);
    } else {
      //--- Allow Freeform Neighborhood Input
      return setListValue([]);
    }
  }

  function getPreFillTime(time) {
    const preDate = new Date(time);
    const parsedDate = parseDate(preDate);
    return `${parsedDate.hour}:${
      parsedDate.minutes
    } ${parsedDate.ampm.toLowerCase()}`;
  }

  useEffect(() => {
    eventData.venue_type &&
      eventData.venue_type !== "" &&
      selectEventLocation(eventData.venue_type);
    eventData.type && eventData.type !== "" && selectEventType(eventData.type);
    eventData.amenities &&
      eventData.amenities.length !== 0 &&
      setAmenities(eventData.amenities);
    eventData.question_1 && setSuggested(eventData.question_1);
    eventData.imageUrl && setEventImagePreview(eventData.imageUrl);
    eventData.community && changeCommunity(eventData.community);
  }, [eventData]);

  function addAmenities(value) {
    let newAmenities = amenities;
    if (amenities && amenities.includes(value)) {
      let index = newAmenities.indexOf(value);
      newAmenities.splice(index, 1);
    } else {
      newAmenities.push(value);
    }
    setAmenities(newAmenities);
    return forceUpdate(updateValue + 1);
  }

  return (
    <div className={styles.editEvent}>
      {loading && <Loading />}
      <div className={styles.editEvent__wrapper}>
        <h1>
          <span style={{ color: "#DD5D39" }}>Edit:</span> {eventData.name}
        </h1>
        <div className={styles.editEvent__image}>
          <EventImageUpload
            imagePreview={eventImagePreview}
            imageSource={eventImageSource}
            loading={setLoading}
            setImageSource={setEventImageSource}
            setImagePreview={setEventImagePreview}
          />
        </div>
        <Formik
          initialValues={{
            address_1: eventData.address_1 || "",
            address_2: eventData.address_2 || "",
            attendees_limit: eventData.attendees_limit || "",
            city: eventData.city || "",
            description: eventData.description || "",
            guest_limit: eventData.guest_limit || 0,
            image: eventData.imageUrl || "",
            name: eventData.name || "",
            neighborhood: eventData.neighborhood || "",
            notes: eventData.notes || "",
            pets: eventData.pets || "",
            published: eventData.published || "",
            question_1: eventData.question_1 || "",
            start_date: eventData.start_date || "",
            state: eventData.state || "",
            venue_name: eventData.venue_name || "",
            zip: eventData.zip || "",
            url: eventData.url || "",
            //-- date and time
            eventDate: eventData.start_date || "",
            startTime: eventData.start_date
              ? getPreFillTime(eventData.start_date)
              : "",
            endTime: eventData.end_date
              ? getPreFillTime(eventData.end_date)
              : "",
          }}
          onSubmit={async (values, actions) => {
            setLoading(true);
            setErrorMessage(false);
            const {
              name,
              description,
              startTime,
              endTime,
              eventDate,
              attendees_limit,
              guest_limit,
              venue_name,
              address_1,
              address_2,
              city,
              state,
              zip,
              neighborhood,
              pets,
              notes,
              question_1,
              url,
            } = values;
            const startDate = startTime
              ? getISODate(startTime, eventDate)
              : eventData.start_time;
            const endDate = endTime
              ? getISODate(endTime, eventDate)
              : eventData.end_time;

            if (!eventImageSource && !eventImagePreview) {
              setErrorMessage("Event Image is Required");
              actions.setSubmitting(false);
              return;
            }

            try {
              if (eventImageSource) {
                const imageResult = await updateEventImage(
                  eventData._id,
                  eventImageSource
                );
              }
              const result = await updateEvent({
                address_1,
                address_2,
                amenities: amenities,
                attendees_limit,
                city,
                description,
                end_date: endDate,
                event_id: eventData._id,
                guest_limit,
                name,
                neighborhood,
                notes,
                pets,
                question_1,
                start_date: startDate,
                state,
                type: eventType,
                url,
                venue_name,
                venue_type: eventLocation,
                zip,
              });
              setLoading(false);
              actions.setSubmitting(false);
              return setSuccessMessage(true);
            } catch (e) {
              const res = await e.response.json();
              setLoading(false);
              actions.setSubmitting(false);
              console.warn("error", res);
              setErrorMessage(
                "Error Updating: " + (res.errors[0] && res.errors[0].details)
              );
            }
          }}
          render={({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <section>
                <Input
                  name="name"
                  label="Event Name"
                  placeholder="What's the name of your event?"
                  type="text"
                />
                <h5>Event Type</h5>
                <div className={createStyles.createEvent__selectorCards}>
                  {TOPICS.map((type, i) => (
                    <div
                      className={createStyles.createEvent__selectorCards__item}
                      key={i}
                    >
                      <SelectBox
                        value={type}
                        onclick={selectEventType}
                        currentValue={eventType}
                      />
                    </div>
                  ))}
                </div>
                <CharacterLimitTextBox
                  limit={20000}
                  placeholder="So excited to discuss “Very Nice” with everyone! You’re invited to my home in Logan Circle to discuss."
                  name="description"
                  label="Event Description"
                />
                <FormRow>
                  <DatePicker
                    name="eventDate"
                    label="Event Date"
                    placeholder="MM/DD/YYYY"
                  />
                  <TimePicker
                    name="startTime"
                    label="Start Time"
                    placeholder="6:00 PM"
                  />
                  <TimePicker
                    name="endTime"
                    label="End Time"
                    placeholder="8:00PM"
                  />
                </FormRow>
              </section>
              <section>
                <h5>Event Location</h5>
                <div
                  className={cx(
                    createStyles.createEvent__selectorCards,
                    createStyles.createEvent__selectorCards__two
                  )}
                >
                  {EVENT_LOCATION.map((type, i) => (
                    <div
                      className={styles.createEvent__selectorCards__item}
                      key={i}
                    >
                      <SelectBox
                        value={type.name}
                        onclick={selectEventLocation}
                        currentValue={eventLocation}
                      />
                    </div>
                  ))}
                </div>
                <Input
                  name="url"
                  label="Virtual URL"
                  placeholder="https://zoom.meeting"
                  type="text"
                />

                <Input
                  name="venue_name"
                  label="Venue Name"
                  placeholder="My Apartment"
                  type="text"
                />
                <AutocompleteInput
                  initialValue={eventData.neighborhood || ""}
                  name="neighborhood"
                  label="Neighborhood"
                  listValues={listValue}
                  placeholder=""
                  type="text"
                />
                <FormRow>
                  <Input
                    name="address_1"
                    label="Address"
                    placeholder="1234 Street Name"
                    type="text"
                  />
                  <Input
                    name="address_2"
                    label="Apt/Suite"
                    placeholder="Apt/Suite"
                    type="text"
                  />
                </FormRow>
                <FormRow>
                  <Input
                    name="city"
                    label="City"
                    placeholder="City"
                    type="text"
                  />
                  <StateSelector
                    error={errors.state && touched.state}
                    label="State"
                    name="state"
                    type="text"
                  />
                  <Input
                    name="zip"
                    label="Zipcode"
                    placeholder="11111"
                    type="text"
                  />
                </FormRow>
              </section>
              <section>
                <Input
                  name="attendees_limit"
                  label="Available Seats"
                  placeholder="5"
                  type="number"
                />
                <Input
                  name="guest_limit"
                  type="number"
                  label="Guests per attendee"
                />
                <h5>Amenities</h5>
                <div className={createStyles.createEvent__selectorCards}>
                  {AMENITIES.map((item, i) => (
                    <div
                      className={createStyles.createEvent__selectorCards__item}
                      key={i}
                    >
                      <SelectBox
                        value={item.name}
                        onclick={addAmenities}
                        currentValue={
                          amenities && amenities.includes(item.name)
                            ? item.name
                            : ""
                        }
                      />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: `${
                      amenities && amenities.includes("Pet(s) on Premises")
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <Input
                    name="pets"
                    label="What Pet Will be Present"
                    type="text"
                  />
                </div>
                <CharacterLimitTextBox
                  limit={550}
                  name="notes"
                  label="Additional Note to Attendees"
                  placeholder="Hey all, I’m so excited to talk about this book! One of my favorite reads so far. To get into my building on Friday, please text me at: 212-756-5309."
                />
              </section>
              <section>
                <TextBox
                  placeholder="Do you have any book discussion questions for the group?"
                  name="question_1"
                  label="Discussion Question"
                />
                <div className={createStyles.createEvent__suggestions}>
                  <div
                    className={createStyles.createEvent__suggestions__wrapper}
                  >
                    <div
                      className={cx(
                        createStyles.createEvent__suggestions__item,
                        {
                          [createStyles.createEvent__suggestions__item__active]:
                            suggested ===
                            "What discussion question do you have for the group?",
                        }
                      )}
                      onClick={() => {
                        setFieldValue(
                          "question_1",
                          "What discussion question do you have for the group?"
                        );
                        setSuggested(
                          "What discussion question do you have for the group?"
                        );
                      }}
                    >
                      Do you have any discussion questions for the group?
                    </div>
                    <div
                      className={cx(
                        createStyles.createEvent__suggestions__item,
                        {
                          [createStyles.createEvent__suggestions__item__active]:
                            suggested ===
                            "What’s your fav local hangout spot for the summer?",
                        }
                      )}
                      onClick={() => {
                        setFieldValue(
                          "question_1",
                          "What’s your fav local hangout spot for the summer?"
                        );
                        setSuggested(
                          "What’s your fav local hangout spot for the summer?"
                        );
                      }}
                    >
                      What’s your fav local hangout spot for the summer?
                    </div>
                    <div
                      className={cx(
                        createStyles.createEvent__suggestions__item,
                        {
                          [createStyles.createEvent__suggestions__item__active]:
                            suggested ===
                            "What dish or drink are you planning to bring?",
                        }
                      )}
                      onClick={() => {
                        setFieldValue(
                          "question_1",
                          "What dish or drink are you planning to bring?"
                        );
                        setSuggested(
                          "What dish or drink are you planning to bring?"
                        );
                      }}
                    >
                      What dish or drink are you planning to bring?
                    </div>
                    <div
                      className={cx(
                        createStyles.createEvent__suggestions__item,
                        {
                          [createStyles.createEvent__suggestions__item__active]:
                            suggested ===
                            "What’s your fav local hangout spot for the winter?",
                        }
                      )}
                      onClick={() => {
                        setFieldValue(
                          "question_1",
                          "What’s your fav local hangout spot for the winter?"
                        );
                        setSuggested(
                          "What’s your fav local hangout spot for the winter?"
                        );
                      }}
                    >
                      What’s your fav local hangout spot for the winter?
                    </div>
                  </div>
                </div>
              </section>
              <FormButton label="Update Event" disabled={isSubmitting} />
              {successMessage && (
                <div>
                  <ErrorBanner
                    notAnError={true}
                    message="Your Event has been updated"
                  />
                  <p className={styles.editEvent__returnMessage}>
                    <a href="/hosting">Return to Hosting Dashboard</a>
                  </p>
                </div>
              )}
              {errorMessage && <ErrorBanner message={errorMessage} />}
            </Form>
          )}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EditEvent);
