import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "api/user.js";
import { setCurrentUser } from "state/actions/userActions.js";

import styles from "components/Onboarding/Onboarding.module.scss";

import Button from "components/Button";
import SideBanner from "components/Shared/SideBanner";

function Overview({
  user = {},
  cmsData = {},
  dispatch,
  updateFn,
  updateValue,
}) {
  const [addressComplete, setAddressComplete] = useState(false);

  async function updateUserState(userState, user_id) {
    try {
      const userQuery = await getUser(user_id);
      if (userQuery) {
        return dispatch(
          setCurrentUser({
            ...userState,
            ...userQuery[0],
          })
        );
      }
    } catch (e) {
      const res = await e.response.json();
      console.warn("ERROR: User Update Errors", res);
      return res;
    }
  }

  useEffect(() => {
    if (user.address_1 && user.city && user.state && user.zip) {
      setAddressComplete(true);
    }
    updateUserState(user, user.user_id);
  }, []);

  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={cmsData.final_title}
          text={cmsData.final_subtitle}
          cmsImage={cmsData.final_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          {addressComplete && (
            <div className={styles.profile__body__section}>
              <h3 className={styles.profile__address__title}>
                {cmsData.final_input_title}
              </h3>
              <p>{cmsData.final_input_text}</p>
              <div className={styles.profile__address}>
                {user.first_name} {user.last_name}
                <br />
                {user.address_1} {user.address_2}
                <br />
                {user.city} {user.state}, {user.zip}
              </div>
            </div>
          )}
          {!addressComplete && (
            <div className={styles.profile__body__section}>
              <h3 className={styles.profile__address__title}>
                You missed some things
              </h3>
              <p>But that's okay - you can always update later!</p>
            </div>
          )}

          <Button
            onclick={updateFn}
            onclickvalue={updateValue}
            label="Go to my dashboard"
          />
          <p className={styles.profile__address__help}>
            Questions? We’ve got answers at{" "}
            <a href="mailto:thelounge@girlsnightin.co">
              thelounge@girlsnightin.co
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Overview);
