import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.user.access_token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default connect((state) => ({ user: state.user }))(PrivateRoute);
