export default function parseDate(date) {
  const parseDate = new Date(date);
  function determineMonth(val) {
    switch (val) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
      default:
        return "";
    }
  }
  function determineDay(val) {
    switch (val) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  }

  let month = determineMonth(parseDate.getMonth()),
    monthNum = parseDate.getMonth() + 1,
    dayOfWeek = determineDay(parseDate.getDay()),
    hour = parseDate.getHours(),
    rawHour = (parseDate.getHours() < 10 ? "0" : "") + parseDate.getHours(),
    minutes = (parseDate.getMinutes() < 10 ? "0" : "") + parseDate.getMinutes(),
    day = parseDate.getDate(),
    ampm = "AM",
    year = parseDate.getFullYear(),
    timeZone = parseDate
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];

  if (hour > 12) {
    hour = hour - 12;
    ampm = "PM";
  } else if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    hour = 12;
    ampm = "PM";
  }

  return {
    month,
    monthNum,
    dayOfWeek,
    hour,
    rawHour,
    minutes,
    ampm,
    year,
    day,
    timeZone,
  };
}
