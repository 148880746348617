import React from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

function CommunityGuidelines({
  title = "Community Values",
  linkText = "Read the Community Handbook",
  link = "#",
  cards = [],
}) {
  return (
    <div className={styles.dashboard__guidelines__wrapper}>
      <div className={styles.dashboard__guidelines}>
        <div className={styles.dashboard__guidelines__title}>
          <div></div>
          <h2>{title}</h2>
          <p>
            <a href={link}>{linkText}</a>
          </p>
        </div>
        <div className={styles.dashboard__guidelines__cards}>
          {cards.map((card, i) => (
            <div className={styles.dashboard__guidelines__card} key={i}>
              <h3>{card.title}</h3>
              <p>{card.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityGuidelines;
