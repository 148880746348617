import React from "react";

import styles from "components/EditAccount/EditAccount.module.scss";

/*
----- NOTE: This Component exists only for future development.
----- no integration with the existing MailChimp newsletter systems
----- have been created.
*/

function Notification(props) {
  return (
    <div className={styles.editAccount__body}>
      <h2>Notification</h2>
      <h3>Send me these newsletters from The Lounge HQ:</h3>
    </div>
  );
}

export default Notification;
