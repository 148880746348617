const NYCNeighborhoods = [
  'Battery Park City',
  'Financial District',
  'TriBeCa',
  'Chinatown',
  'Greenwich Village',
  'Little Italy',
  'Lower East Side',
  'NoHo',
  'SoHo',
  'West Village',
  'Alphabet City',
  'Chinatown',
  'East Village',
  'Lower East Side',
  'Two Bridges ',
  'Chelsea',
  'Clinton',
  'Midtown',
  'Gramercy',
  'Kips Bay',
  'Murray Hill',
  'Stuyvesant Town',
  'Sutton Place',
  'Turtle Bay',
  'Manhattan Valley',
  'Upper West Side',
  'Lenox Hill',
  'Roosevelt Island',
  'Upper East Side',
  'Yorkville',
  'Hamilton Heights',
  'Manhattanville',
  'Morningside Heights',
  'Harlem',
  'East Harlem',
  'Spanish Harlem',
  'Inwood',
  'Washington Heights',
  'Crown Heights',
  'Flatbush',
  'Prospect Park',
  'Kensington',
  'Midwood',
  'Brownsville',
  'Canarsie',
  'East New York',
  'Highland Park',
  'Northern',
  'Bedford-Stuyvesant',
  'Bushwick',
  'Greenpoint',
  'Williamsburg',
  'Brooklyn Heights',
  'Brooklyn Navy Yard',
  'Cadman Plaza',
  'Clinton Hill',
  'Downtown Brooklyn',
  'DUMBO',
  'Fort Greene',
  'Fulton Ferry',
  'Pacific Park/Atlantic Yards',
  'Prospect Heights',
  'Vinegar Hill',
  'Boerum Hill',
  'Carroll Gardens',
  'Cobble Hill',
  'Gowanus',
  'Park Slope',
  'Red Hook',
  'Barren Island',
  'Bergen Beach and Georgetown',
  'Coney Island',
  'Sheepshead Bay and Madison',
  'Flatlands',
  'Gerritsen Beach',
  'Gravesend',
  'Marine Park',
  'Mill Basin',
  'Plumb Beach',
  'Southwestern',
  'Bay Ridge',
  'Fort Hamilton',
  'Bensonhurst',
  'Borough Park',
  'Dyker Heights',
  'Sunset Park',
  'Astoria',
  'Jackson Heights',
  'Long Island City',
  'Sunny side',
  'Northeastern',
  'Bayside',
  'Bellerose',
  'College Point',
  'Douglaston-Little Neck',
  'Flushing',
  'Pomonok',
  'Floral Park',
  'Fresh Meadows',
  'Glen Oaks',
  'White Stone',
  'Central',
  'Corona',
  'East Elmhurst',
  'Elmhurst',
  'Forest Hills',
  'Fresh Pond',
  'Glendale',
  'Jackson Heights',
  'Kew Gardens',
  'Maspeth',
  'Middle Village',
  'Rego Park',
  'Ridgewood',
  'Woodside',
  'Bellaire',
  'Brockville',
  'Cambria Heights',
  'Hollis Hills',
  'Hollis',
  'Jamaica',
  'Laurel ton',
  'Meadow mere',
  'Queens Village',
  'Rochdale Village',
  'Rosedale',
  'Saint Albans',
  'South Jamaica Springfield Gardens',
  'The Hole',
  'Howard Beach',
  'Ozone Park',
  'Richmond Hill',
  'Woodhaven',
  'The Rockaways',
  'Annadale',
  'Arden Heights',
  'Arlington',
  'Arrochar',
  'Bay Terrace',
  'Bloomfield',
  'Brighton Heights',
  'Bulls Head',
  'Castleton Corners',
  'Charleston',
  'Chelsea',
  'Clifton',
  'Concord',
  'Dongan Hills',
  'Egbertville',
  'Elm Park',
  'Eltingville',
  'Emerson Hill',
  'Fort Wadsworth',
  'Graniteville',
  'Grant City',
  'Grasmere',
  'Great Kills',
  'Greenridge',
  'Grymes Hill',
  'Hamilton Park',
  'Heartland Village',
  'Huguenot',
  'Lighthouse Hill',
  'Livingston',
  'Manor Heights',
  'Mariners Harbor',
  'Meiers Corners',
  'Midland Beach',
  'New Brighton',
  'New Dorp',
  'New Springville',
  'Oakwood',
  'Old Place',
  'Old Town',
  'Pleasant Plains',
  'Port Ivory',
  'Port Richmond',
  'Prince\'s Bay',
  'Randall Manor',
  'Richmond Valley',
  'Richmondtown',
  'Rosebank',
  'Rossville',
  'Saint George',
  'Sandy Ground',
  'Shore Acres',
  'Silver Lake',
  'South Beach',
  'Stapleton',
  'Stapleton Heights',
  'Sunnyside',
  'Todt Hill',
  'Tompkinsville',
  'Tottenville',
  'Tottenville Beach',
  'Travis',
  'Ward Hill',
  'Westerleigh',
  'West New Brighton',
  'Willowbrook',
  'Woodrow'  
];

export default NYCNeighborhoods;