import React from "react";
import PropTypes from "prop-types";
// Styles
import styles from "components/Button/Button.module.scss";

function Button({
  additionalClass,
  disabled,
  label,
  link,
  linkTarget,
  onclick,
  onclickvalue,
  smaller,
  visualOnly,
}) {
  return (
    <div
      className={`
        ${styles.button}
        ${additionalClass && styles[additionalClass]}
        ${smaller && styles.button__smaller}
      `}
    >
      {onclick && (
        <button onClick={() => onclick(onclickvalue)} disabled={disabled}>
          {label}
        </button>
      )}
      {link && (
        <a aria-label={label} href={link} target={linkTarget}>
          {label}
        </a>
      )}
      {visualOnly && <button>{label}</button>}
    </div>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.string,
  additionalClass: PropTypes.string,
  onclick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
