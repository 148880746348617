import ky from "ky";

export async function getAuthenticatedUser(user_id) {
  const user = await ky.get("/.netlify/functions/userGet").json();
  return user;
}

export async function getHostUser(user_id) {
  const user = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${user_id}"] {
          _id,
          "userImage": avatar.asset->url,
          "eventHosted": *[_type=='event' && deleted != true && host._ref == ^._id] | order(start_date asc){
            _id,
            attendees[]{
              attendee->{
                _id,
                "attendeeImage": avatar.asset->url,
                email,
                first_name,
                last_name,
                tagline
              },
              attendee_answer
            },
            attendees_limit,
            cancelled,
            community,
            description,
            end_date,
            guest_limit,
            host->{
              "hostImage": avatar.asset->url,
              first_name
            },
            name,
            neighborhood,
            start_date,
            type,
            venue_type,
            published,
            waitlist[]->{
              _id,
              "attendeeImage": avatar.asset->url,
              email,
              first_name,
              last_name
            }
          }
        }`,
      },
    })
    .json();
  return user;
}

export async function getUser(user_id) {
  const user = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${user_id}"] {
          _id,
          bio,
          community,
          currently_reading,
          currently_watching,
          currently_listening,
          currently_eating,
          currently_instagram,
          currently_irl,
          email,
          first_name,
          industry,
          instagram,
          last_name,
          linkedin,
          membership_interests,
          membership_offering,
          neighborhoods,
          onboarded,
          pronouns,
          show_email,
          show_pronouns,
          sign,
          social_email,
          stripe_source,
          subscription_level,
          subscription_status,
          tagline,
          twitter,
          user_roles,
          website,
          "userImage": avatar.asset->url,
          "eventRSVPS": *[_type=='event' && attendees[]._key == "${user_id}" && published == true] | order(start_date asc) {
            _id,
            attendees[]{
              attendee->{
                _id,
                "attendeeImage": avatar.asset->url,
                first_name,
                last_name,
                tagline
              },
              attendee_answer
            },
            attendees_limit,
            cancelled,
            community,
            description,
            end_date,
            guest_limit,
            host->{
              "hostImage": avatar.asset->url,
              first_name
            },
            name,
            neighborhood,
            published,
            start_date,
            venue_type,
            type
          },
          "eventHosted": *[_type=='event' && host._ref == ^._id && published == true] | order(start_date desc) {
            _id,
            attendees[]{
              attendee->{
                _id,
                "attendeeImage": avatar.asset->url,
                first_name,
                last_name,
                tagline
              },
              attendee_answer
            },
            attendees_limit,
            cancelled,
            community,
            description,
            end_date,
            guest_limit,
            host->{
              "hostImage": avatar.asset->url,
              first_name
            },
            name,
            neighborhood,
            start_date,
            type,
            venue_type,
            published
          }
        }`,
      },
    })
    .json();
  return user;
}

export async function getUserForEdit(user_id) {
  const user = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_id == "${user_id}"] {
          _createdAt,
          _id,
          address_1,
          address_2,
          bio,
          city,
          community,
          currently_reading,
          currently_watching,
          currently_listening,
          currently_eating,
          currently_instagram,
          currently_irl,
          email,
          first_name,
          industry,
          instagram,
          last_name,
          linkedin,
          membership_interests,
          membership_offering,
          neighborhoods,
          onboarded,
          pronouns,
          show_email,
          show_pronouns,
          sign,
          social_email,
          state,
          subscription_level,
          subscription_status,
          tagline,
          twitter,
          user_roles,
          website,
          zip,
          "userImage": avatar.asset->url,
        }`,
      },
    })
    .json();
  return user;
}

export async function updateUser(user_id, fields) {
  const user = await ky
    .post("/.netlify/functions/userUpdate", {
      json: {
        user_id,
        fields,
      },
    })
    .json();
  return user;
}

export async function adminUpdateUser(user_id, fields) {
  const user = await ky
    .post("/.netlify/functions/adminUpdateUser", {
      json: {
        user_id,
        ...fields,
      },
    })
    .json();

  return user;
}

export async function updateUserPassword(email, password, new_password) {
  const user = await ky
    .post("/.netlify/functions/userUpdatePassword", {
      json: {
        email,
        password,
        new_password,
      },
    })
    .json();

  return user;
}

export async function updateUserEmail(email, password, new_email) {
  const user = await ky
    .post("/.netlify/functions/userUpdateEmail", {
      json: {
        email,
        password,
        new_email,
      },
    })
    .json();

  return user;
}

export async function updateUserAvatar(user_id, asset_id) {
  const user = await ky
    .post("/.netlify/functions/userUpdateAvatar", {
      json: {
        user_id,
        asset_id,
      },
    })
    .json();

  return user;
}

export async function processPayment(plan, source_id, user_id, email, name) {
  const user = await ky
    .post("/.netlify/functions/stripeProcessSubscription", {
      json: {
        plan,
        source_id,
        user_id,
        email,
        name,
      },
    })
    .json();

  return user;
}
export async function updatePayment(user_id, source_id) {
  const user = await ky
    .post("/.netlify/functions/stripeUpdatePaymentMethod", {
      json: {
        user_id,
        source_id,
      },
    })
    .json();

  return user;
}

export async function getAllUsers(
  page = "0...15",
  filterObj = {},
  sortBy = "Latest Joined"
) {
  let sortByVal;

  switch (sortBy) {
    case "A-Z First Name":
      sortByVal = "first_name asc";
      break;
    case "Z-A First Name":
      sortByVal = "first_name desc";
      break;
    case "A-Z Last Name":
      sortByVal = "last_name asc";
      break;
    case "Z-A Last Name":
      sortByVal = "last_name desc";
      break;
    case "Earliest Joined":
      sortByVal = "_createdAt asc";
      break;
    case "Latest Joined":
      sortByVal = "_createdAt desc";
      break;
    default:
      sortByVal = "_createdAt desc";
      break;
  }

  const filterObjToQueryString = (obj) => {
    let output = "(";
    let filterTypes = Object.keys(obj);
    for (let i = 0; i < filterTypes.length; i++) {
      //if we find it more useful or important to make this modular. what needs to be done here is to make
      //this check if querying filtertypes[i] returns an array
      if (
        filterTypes[i] === "membership_offering" ||
        filterTypes[i] === "neighborhoods"
      ) {
        for (let j = 0; j < obj[filterTypes[i]].length; j++) {
          output += `"${obj[filterTypes[i]][j]}" in ${filterTypes[i]}`;
          if (j < obj[filterTypes[i]].length - 1) {
            output += " || ";
          }
        }
      } else if (filterTypes[i] === "user_roles") {
        for (let j = 0; j < obj[filterTypes[i]].length; j++) {
          output += `"${obj[filterTypes[i]][j].toLowerCase()}" in ${
            filterTypes[i]
          }`;
          if (j < obj[filterTypes[i]].length - 1) {
            output += " || ";
          }
        }
      } else {
        for (let j = 0; j < obj[filterTypes[i]].length; j++) {
          if (obj[filterTypes[i]][j].substring(0, 5) === "Other") {
            let otherArr = obj[filterTypes[i]][j].split("-");
            output += "(";
            for (let k = 1; k < otherArr.length; k++) {
              output += `${filterTypes[i]} != "${otherArr[k]}"`;
              output += k < otherArr.length - 1 ? ` && ` : `)`;
            }
          } else {
            output += `"${obj[filterTypes[i]][j]}" == ${filterTypes[i]}`;
          }
          if (j < obj[filterTypes[i]].length - 1) {
            output += " || ";
          }
        }
      }
      if (i < filterTypes.length - 1) {
        output += ") && (";
      }
    }
    output += ")";
    return output;
  };

  const users = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `*[_type == "user" && subscription_status == 'active' ${
          Object.keys(filterObj).length
            ? `&& ${filterObjToQueryString(filterObj)} `
            : ""
        }] | order(${sortByVal}) {
          _id,
          first_name,
          last_name,
          tagline,
          community,
          industry,
          instagram,
          linkedin,
          membership_offering,
          neighborhood,
          pronouns,
          "userImage": avatar.asset->url,
          "userCount": count(*[_type == "user" && subscription_status == 'active' ${
            Object.keys(filterObj).length
              ? `&& ${filterObjToQueryString(filterObj)} `
              : ""
          }]),
          "eventRSVPS": *[_type=='event' && references(^._id)] | order(start_date asc) {
            _id,
          },
          _createdAt,
          subscription_status,
          user_roles,
          website
        }[${page}]`,
      },
    })
    .json();
  return users;
}

export async function getSuggestedUsers(sortingCards = {}) {
  const users = await ky
    .post("/.netlify/functions/proxy", {
      json: {
        query: `{
      "suggested_industry_count": count(*[_type == 'user' && industry == '${
        sortingCards.industry
      }']),
      "suggested_neighborhood": count(*[_type == 'user' && '${
        sortingCards.neighborhood
      }' in neighborhoods]),
      "suggested_bringing": count(*[_type == 'user' && '${
        sortingCards.bringingToTable ? sortingCards.bringingToTable[0] : ""
      }' in membership_offering]),
      "suggested_city": count(*[_type == 'user' && community == '${
        sortingCards.city
      }']),
    }`,
      },
    })
    .json();
  return users;
}

export async function getStripeSubscription(user_id) {
  const user = await ky
    .post("/.netlify/functions/stripeGetSubscriptionInformation", {
      json: {
        user_id,
      },
    })
    .json();
  return user;
}
