import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { initLoading, stopLoading } from "state/actions/loadingActions.js";
import { cacheData } from "state/actions/dataActions.js";

const mapStateToProps = (state, props) => ({
  data: state.data[props.cache],
});

const DataLoader = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.data);

  useEffect(async () => {
    const { cache, load, dispatch } = props;
    setLoading(true);
    dispatch(initLoading());

    const data = await load();

    dispatch(
      cacheData({
        [cache]: data,
      })
    );
    if (!data) return;
    setLoading(false);
    setData(data);

    dispatch(stopLoading());
  }, []);

  const component = useMemo(() => {
    const { children = null, render = null } = props;

    const component = typeof children === "function" ? children : render;
    return component;
  }, [props]);

  if (loading) return props.fallback();
  return <div>{component({ data: data || props.data || [] })}</div>;
};

export default connect(mapStateToProps)(DataLoader);
