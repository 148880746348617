import React from "react";
// Components
import FullLoungeLogo from "components/Logos/FullLoungeLogo";
// Styles
import styles from "components/Footer/Footer.module.scss";

export default function Footer({ isLoggedIn }) {
  return (
    <div className={styles.footer}>
      {/* <div className={styles.footer__row}>
        <div className={styles.footer__top}>
          <div className={styles.footer__row}>
            <FullLoungeLogo fillColor="#F5C38F" />
            <div className={styles.footer__social}>
              <a href="https://twitter.com/girlsnightinHQ">
                <svg
                  width="25"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.616 4.773c0-1.142.406-2.11 1.217-2.904.812-.794 1.789-1.19 2.93-1.19 1.214 0 2.204.418 2.971 1.257.857-.178 1.731-.5 2.623-.963-.285.963-.883 1.721-1.793 2.274a9.395 9.395 0 0 0 2.355-.669 8.495 8.495 0 0 1-2.06 2.168v.508c0 1.945-.429 3.765-1.285 5.46-.857 1.73-2.213 3.202-4.068 4.416-1.856 1.213-3.97 1.82-6.343 1.82-2.319 0-4.424-.616-6.315-1.847.285.036.624.053 1.017.053 1.909 0 3.604-.58 5.084-1.74-.927-.017-1.735-.302-2.422-.856-.686-.553-1.155-1.23-1.405-2.033.179.07.429.107.75.107.32 0 .678-.045 1.07-.134-.928-.196-1.704-.67-2.328-1.419-.625-.749-.937-1.64-.937-2.676.518.286 1.133.455 1.847.509-1.231-.857-1.847-2.007-1.847-3.453 0-.678.188-1.355.562-2.033 2.248 2.73 5.085 4.148 8.51 4.255a3.21 3.21 0 0 1-.133-.91z"
                    fill="#F9F3EB"
                  />
                </svg>
              </a>
              <a href="https://www.instagram.com/girlsnightinclub/">
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.021 0H4.639a4.38 4.38 0 0 0-1.733.347c-.531.23-.999.548-1.403.953A4.454 4.454 0 0 0 .55 2.72a4.255 4.255 0 0 0-.347 1.716v7.4c0 .6.116 1.172.347 1.715.23.543.548 1.013.953 1.412.404.399.872.713 1.403.944a4.2 4.2 0 0 0 1.733.364h7.382a4.2 4.2 0 0 0 1.733-.364c.531-.23.999-.546 1.403-.944.405-.399.722-.87.953-1.412a4.335 4.335 0 0 0 .347-1.716V4.436c0-.612-.116-1.184-.347-1.715A4.453 4.453 0 0 0 13.754.347 4.38 4.38 0 0 0 12.02 0zm2.963 11.835c0 .809-.289 1.502-.866 2.08a2.856 2.856 0 0 1-2.097.866H4.639c-.82 0-1.519-.289-2.097-.866a2.836 2.836 0 0 1-.866-2.08V4.436c0-.809.289-1.505.866-2.088a2.842 2.842 0 0 1 2.097-.875h7.382c.82 0 1.52.292 2.097.875s.866 1.28.866 2.088v7.4zM8.92 4.436a3.002 3.002 0 0 0-1.109 0 3.38 3.38 0 0 0-1.369.503c-.416.265-.762.589-1.04.97-.277.381-.479.809-.606 1.282a3.627 3.627 0 0 0 .399 2.816c.254.422.583.783.987 1.083.335.22.688.399 1.057.537.37.139.757.208 1.161.208.081 0 .17-.003.269-.009a.497.497 0 0 0 .251-.078c.485-.069.939-.23 1.36-.485a3.752 3.752 0 0 0 1.084-.987 3.528 3.528 0 0 0 .658-2.738 3.478 3.478 0 0 0-1.014-2.071A3.58 3.58 0 0 0 8.92 4.436zm1.265 4.956c-.185.22-.404.413-.658.58a1.883 1.883 0 0 1-.832.304 2.3 2.3 0 0 1-1.638-.425 2.006 2.006 0 0 1-.875-1.43c-.104-.589.032-1.134.408-1.637.375-.503.857-.794 1.446-.875h.59c.485.08.895.289 1.23.624.335.335.543.745.624 1.23.07.3.072.592.008.875a2.372 2.372 0 0 1-.303.754zm1.698-5.84a.623.623 0 0 0-.173.226.746.746 0 0 0-.052.294c0 .104.017.197.052.277.035.081.093.162.173.243.07.07.153.121.252.156a.814.814 0 0 0 .268.052.697.697 0 0 0 .277-.052.953.953 0 0 0 .243-.156.84.84 0 0 0 .156-.269.748.748 0 0 0 0-.546.72.72 0 0 0-.156-.225.714.714 0 0 0-1.04 0z"
                    fill="#F9F3EB"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className={styles.footer__row}>
            <h3>Info</h3>
            <p className={styles.footer__link}>
              <a href="/our-story">Our Story</a>
            </p>
            {isLoggedIn && (
              <p className={styles.footer__link}>
                <a href="/community-guidelines">Community Guidelines</a>
              </p>
            )}
            {isLoggedIn && (
              <p className={styles.footer__link}>
                <a href="/support">Support</a>
              </p>
            )}
            <p className={styles.footer__link}>
              <a href="/terms">Terms & Conditions</a>
            </p>
            <p className={styles.footer__link}>
              <a
                href="http://girlsnightinclub.com"
                target="_blank"
                rel="noreferrer"
              >
                Girls' Night In
              </a>
            </p>
            <p className={styles.footer__link}>
              <a
                href="http://instagram.com/jointhelounge"
                target="_blank"
                rel="noreferrer"
              >
                @jointhelounge
              </a>
            </p>
          </div>
          <div className={styles.footer__row}>
            <h3>Questions?</h3>
            <p className={styles.footer__link}>
              <a href="/faqs">FAQs</a>
            </p>
            <p className={styles.footer__link}>
              <a href="/refunds">Refund Policy</a>
            </p>
            <p className={styles.footer__link}>
              <a href="mailto:thelounge@girlsnightin.co">
                thelounge@girlsnightin.co
              </a>
            </p>
          </div>
        </div>
      </div> */}
      <div className={styles.footer__bottom}>
        <p>copyright {new Date().getFullYear()} the lounge</p>
      </div>
    </div>
  );
}
