import React, { memo, useRef, useState } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
// API
import { submitWaitlist } from "api/submitWaitlist";
// Components
import Button from "components/Button";
import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import SelectBox from "components/FormElements/SelectBox";
import TextBox from "components/FormElements/TextBox";
// Styles
import styles from "components/Membership/Membership.module.scss";
// Utils
import useOutsideClickListener from "utils/useOutsideClickListener";

const MembershipWaitlist = memo(function ({
  waitlistTitle,
  waitlistSuccessTitle = `You're on the waitlist!`,
  waitlistSubtitle,
  waitlistSuccessSubtitle = `we'll be in touch via email.`,
}) {
  const [modalOpen, openModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [raceEthnicity, setRaceEthnicity] = useState([]);
  const [newsLetterSubscriber, setNewsLetterSubscriber] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [userName, setUserName] = useState(false);

  const containerRef = useRef(null);

  useOutsideClickListener(containerRef, (clickedOutside) => {
    if (modalOpen && clickedOutside) openModal(false);
  });

  function setRaceEthnicityArray(val) {
    let raceEthnicityArray = [...raceEthnicity];
    let containsValue = raceEthnicityArray.find((el) => el === val);
    if (containsValue) {
      raceEthnicityArray = raceEthnicityArray.filter((el) => {
        return el !== val;
      });
    } else {
      raceEthnicityArray.push(val);
    }
    setRaceEthnicity(raceEthnicityArray);
  }

  return (
    <div className={styles.membership__waitlist} id="waitlist">
      <div className={styles.membership__waitlist__wrapper}>
        <h2>{showSuccess ? waitlistSuccessTitle : waitlistTitle}</h2>
        <p>
          {showSuccess
            ? `Thanks${userName && " " + userName}, ${waitlistSuccessSubtitle}`
            : waitlistSubtitle}
        </p>
        {!showSuccess && (
          <Button
            label={showSuccess ? "Thanks for signing up" : "Join the waitlist"}
            additionalClass="button__greenWhite"
            disabled={showSuccess}
            onclick={openModal}
            onclickvalue={true}
          />
        )}
      </div>
      <div
        className={cx(styles.membership__waitlist__modal, {
          [styles.membership__waitlist__modal__active]: modalOpen,
        })}
      >
        <div
          className={styles.membership__waitlist__modal__wrapper}
          ref={containerRef}
        >
          <div
            className={styles.membership__waitlist__modal__close}
            onClick={() => openModal(false)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <h2>We can’t wait to lounge with you!</h2>
          <p>
            Girls’ Night In and The Lounge are committed to diversity. In an
            effort to help us better understand our community, we ask that you
            share some information with us.
          </p>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email_address: "",
              location: "",
              instagram: "",
              more_information: "",
            }}
            onSubmit={(values, actions) => {
              setSubmitError(false);
              const { setFieldError, setSubmitting } = actions;

              const {
                first_name,
                last_name,
                email_address,
                location,
                instagram,
                more_information,
              } = values;

              setUserName(first_name);
              setSubmitting(true);
              let race_ethnicity = "";
              for (let i = 0; i < raceEthnicity.length; i++) {
                race_ethnicity = race_ethnicity + raceEthnicity[i] + ", ";
              }

              if (first_name === "") {
                setFieldError("first_name", "Required");
                setSubmitError("First Name is required");
                setSubmitting(false);
                return null;
              }
              if (last_name === "") {
                setFieldError("last_name", "Required");
                setSubmitError("Last Name is required");
                setSubmitting(false);
                return null;
              }
              if (email_address === "") {
                setFieldError("email_address", "Required");
                setSubmitError("Email Address is required");
                setSubmitting(false);
                return null;
              }

              let dateNow = new Date(Date.now());
              dateNow = `${dateNow.toDateString()} ${dateNow.toTimeString()}`;
              const moreInformation = more_information
                ? encodeURIComponent(more_information)
                : "";

              const instagramEncode = instagram
                ? encodeURIComponent(instagram)
                : "";
              const locationEncode = location
                ? encodeURIComponent(location)
                : "";
              const firstNameEncode = location
                ? encodeURIComponent(first_name)
                : "";
              const lastNameEncode = location
                ? encodeURIComponent(last_name)
                : "";

              submitWaitlist({
                first_name: firstNameEncode,
                last_name: lastNameEncode,
                email_address,
                subscriber: newsLetterSubscriber.toString(),
                location: locationEncode,
                race_ethnicity: race_ethnicity.toString(),
                instagram: instagramEncode,
                more_information: moreInformation,
                timestamp: dateNow,
              })
                .then(async function () {
                  setSubmitting(false);
                  // Show Success State in Waitlist
                  setShowSuccess(true);
                  // Close Modal
                  openModal(false);
                })
                .catch(async function (e) {
                  setSubmitting(false);
                  submitError("Error Submitting - Please try again");
                  console.warn("ERROR", e);
                });
            }}
            render={({ errors, status, touched, isSubmitting }) => (
              <Form>
                <FormRow>
                  <Input
                    name="first_name"
                    label="First Name"
                    placeholder=""
                    type="text"
                  />
                  <Input
                    name="last_name"
                    label="Last Name"
                    placeholder=""
                    type="text"
                  />
                </FormRow>
                <Input
                  name="email_address"
                  label="Email Address"
                  placeholder=""
                  type="email"
                />
                <h3>Are you a Girls’ Night In newsletter subscriber?</h3>
                <FormRow>
                  <SelectBox
                    value="Yes"
                    onclick={setNewsLetterSubscriber}
                    currentValue={newsLetterSubscriber}
                  />
                  <SelectBox
                    value="No"
                    onclick={setNewsLetterSubscriber}
                    currentValue={newsLetterSubscriber}
                  />
                </FormRow>
                <h3>Where are you located?</h3>
                <Input
                  label=""
                  name="location"
                  placeholder="City, State"
                  type="text"
                />
                <h3>Which race/ethnicity best describes you? </h3>
                <h4>Please select all that apply.</h4>
                <div
                  className={styles.membership__waitlist__modal__selectBoxes}
                >
                  <SelectBox
                    value="American Indian or Alaskan Native"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "American Indian or Alaskan Native"
                        ? "American Indian or Alaskan Native"
                        : ""
                    )}
                  />
                  <SelectBox
                    value="Asian or Pacific Islander"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Asian or Pacific Islander"
                        ? "Asian or Pacific Islander"
                        : ""
                    )}
                  />
                  <SelectBox
                    value="Black or African American"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Black or African American"
                        ? "Black or African American"
                        : ""
                    )}
                  />
                  <SelectBox
                    value="Hispanic or Latinx"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Hispanic or Latinx"
                        ? "Hispanic or Latinx"
                        : ""
                    )}
                  />
                  <SelectBox
                    value="White"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "White" ? "White" : ""
                    )}
                  />
                  <SelectBox
                    value="Middle Eastern or North African"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Middle Eastern or North African"
                        ? "Middle Eastern or North African"
                        : ""
                    )}
                  />
                  <SelectBox
                    value="Other"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Other" ? "Other" : ""
                    )}
                  />
                  <SelectBox
                    value="Prefer not to answer"
                    onclick={setRaceEthnicityArray}
                    currentValue={raceEthnicity.find((element) =>
                      element === "Prefer not to answer"
                        ? "Prefer not to answer"
                        : ""
                    )}
                  />
                </div>
                <h3>We’d love to get to know you better!</h3>
                <h4>Please share where we can find you on social.</h4>
                <Input
                  name="instagram"
                  label="Instagram"
                  placeholder="@"
                  type="text"
                />
                <h3>Anything else we should know about you? </h3>
                <h4>What would make you a great addition to our community?</h4>
                <TextBox
                  placeholder="Other comments"
                  name="more_information"
                  label=""
                />
                {submitError && <p style={{ color: "red" }}>{submitError}</p>}
                <FormButton
                  additionalClass="button__greenWhite"
                  disabled={isSubmitting}
                  label="Submit"
                />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
});

export default MembershipWaitlist;
