function formatDate(date) {
  if (isNaN(Date.parse(date))) return '';

  date = new Date(date);

  const m = date.getMonth() + 1;
  const d = date.getDate();
  const yyyy = date.getFullYear();
  return `${m}/${d}/${yyyy}`
}

export default formatDate;
