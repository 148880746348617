import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

function PublicOnlyRoute ({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => {
      return rest.user.access_token ? (
        <Redirect to={{
          pathname: '/dashboard',
          state: { from: props.location }
        }} />
      ) : (
        <Component {...props} />
      )
    }} />
  );
}

export default connect(state => ({ user: state.user }))(PublicOnlyRoute);
