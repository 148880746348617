import React, { useState } from "react";
//--- Queries & Actions
import { cancelRSVP } from "api/event";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import AddGuestModal from "components/Events/AddGuestModal";
import Button from "components/Button";
import Note from "components/Shared/Note";
import CancelRSVPConfirm from "components/Events/CancelRSVPConfirm";

function RSVPTaskBar({
  hostFirstName,
  hostNote,
  guestAllowed = 0,
  eventId,
  eventName,
  setLoading,
  showInviteGuests,
  remainingAttendees,
}) {
  const [cancelModal, toggleCancelModal] = useState(false);
  const [guestModal, toggleGuestModal] = useState(false);

  async function cancelRSVPFunc(eventid) {
    setLoading(true);
    try {
      const result = await cancelRSVP({ event_id: eventid });
      window.location = `/event/${eventId}`;
    } catch (e) {
      const res = await e.response.json();
      console.warn("errors", res);
    }
  }

  return (
    <div className={styles.event__RSVPedit}>
      {cancelModal && (
        <CancelRSVPConfirm
          closeModal={toggleCancelModal}
          cancelRSVP={cancelRSVPFunc}
          eventId={eventId}
        />
      )}
      {guestModal && (
        <AddGuestModal
          setLoading={setLoading}
          eventName={eventName}
          closeModal={toggleGuestModal}
          eventId={eventId}
        />
      )}
      <div className={styles.event__RSVPedit__title}>
        <h2>You're Going!</h2>
      </div>
      <div className={styles.event__RSVPedit__note}>
        {hostNote !== "" && <h3>Note from the host, {hostFirstName}</h3>}
        {hostNote !== "" && <Note text={hostNote} />}
      </div>
      <div className={styles.event__RSVPedit__utility}>
        {guestAllowed !== 0 && showInviteGuests && remainingAttendees > 0 && (
          <div>
            <h3>{`${guestAllowed} guest${
              guestAllowed > 1 ? "s" : ""
            } allowed`}</h3>
            <Button
              label="Add a Guest"
              onclick={toggleGuestModal}
              onclickvalue={true}
            />
          </div>
        )}
        <Button
          onclick={toggleCancelModal}
          onclickvalue={true}
          additionalClass="button__transparent"
          label="Cancel RSVP"
        />
        <p>Please give at least 48 hours’ notice as a courtesy to the host.</p>
      </div>
    </div>
  );
}

export default RSVPTaskBar;
