import React, { useState } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
//--- Queries & Actions
import { guestRSVP } from "api/event";
//--- Utils
import { AddGuestSchema } from "utils/validationSchema";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

export default function AddGuestModal({
  closeModal,
  eventId,
  eventName,
  setLoading,
}) {
  return (
    <div className={cx(styles.event__modal, styles.event__modal__active)}>
      <div className={styles.event__modal__body__wrapper}>
        <div className={styles.event__modal__body}>
          <div
            className={styles.event__modal__close}
            onClick={() => closeModal(false)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>{eventName}</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <div
              className={cx(
                styles.event__modal__left,
                styles.event__congratsModal__left
              )}
            >
              <div className={styles.event__modal__left__wrapper}>
                <div
                  className={styles.event__modal__left__image}
                  style={{
                    backgroundImage: `url('${"/placeholder/placeholder-profile-image.png"}')`,
                  }}
                ></div>
                <h2>Add a guest</h2>
                <p>
                  Bringing a +1? The more the merrier! Your guest will receive
                  an email invite and RSVP confirmation.
                </p>
              </div>
            </div>
            <div
              className={cx(
                styles.event__modal__right,
                styles.event__congratsModal__right
              )}
            >
              <Formik
                initialValues={{
                  first_name: undefined,
                  last_name: undefined,
                  email: undefined,
                }}
                validationSchema={AddGuestSchema}
                onSubmit={async (values, actions) => {
                  const { first_name, last_name, email } = values;
                  setLoading(true);
                  try {
                    const result = await guestRSVP({
                      guest: { first_name, last_name, email },
                      event_id: eventId,
                    });
                    setLoading(false);
                    window.location = `/event/${eventId}`;
                  } catch (e) {
                    const res = await e.response.json();
                    setLoading(false);
                    actions.setSubmitting(false);
                    console.warn("errors", res);
                  }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                  <Form>
                    <Input
                      label="First Name"
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                    />
                    <Input
                      label="Last Name"
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                    />
                    <Input
                      label="Email Address"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                    />
                    <FormButton label="Submit" disabled={isSubmitting} />
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
