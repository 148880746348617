import React from "react";
///--- Queries & Actions
import { getArticles } from "api/articles.js";
//--- Utils
import { pageParams } from "utils/parseQueryParams";
//--- Components
import DataLoader from "components/DataLoader.js";
import Articles from "components/Article";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default function ArticleListing(props) {
  //--- Get Query Params
  const urlParams = new URLSearchParams(props.location.search);
  //--- Pagination
  const pageParam = urlParams.get("page") ? urlParams.get("page") : "1";
  const perPage = "8";
  const page = pageParams(pageParam, perPage);

  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            const articles = await getArticles(page);
            return { articles };
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          return (
            <Articles
              articles={props.data.articles}
              currentPage={pageParam}
              perPage={perPage}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}
