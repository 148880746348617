export default function createEventReducer (state = {}, action) {
  const { type, ...event } = action;

  switch (type) {
    case 'CREATE_EVENT':
      return event;
    case 'SET_CREATE_EVENT':
      return event;
    case 'RESET_CREATE_EVENT': 
      return {eventFinalImage: event.eventImage, eventFinalId: event.eventId};
    default:
      return state;
  }
}
