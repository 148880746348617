import React from "react";

import styles from "components/Events/Events.module.scss";

function WhatToExpect({ amenities }) {
  return (
    <div className={styles.event__expect}>
      <h2>
        <span>What to Expect</span> <span></span>
      </h2>
      <div className={styles.event__expect__items}>
        {amenities &&
          amenities.map((item, i) => (
            <div className={styles.event__expect__item} key={i}>
              <div>
                {item === "Light Snacks" && (
                  <img src="/icons/snacks.svg" alt="Cheese" />
                )}
                {item === "Alcoholic Beverages" && (
                  <img src="/icons/wine-cheese.svg" alt="Wine glass" />
                )}
                {item === "Non-alcoholic Beverages" && (
                  <img
                    src="/icons/non-alcoholic-beverages.svg"
                    alt="Coffee cup"
                  />
                )}
                {item === "Handicap Accessible" && (
                  <img src="/icons/handicap-accessible.svg" alt="Wheelchair" />
                )}
                {item === "Pet(s) on Premises" && (
                  <img src="/icons/pets.svg" alt="Paw prints" />
                )}
              </div>
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default WhatToExpect;
