import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";

import styles from "components/FormElements/FormElements.module.scss";

function Input({
  label,
  name,
  placeholder,
  type,
  inputRef,
  withAtSymbol = false,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <div
            className={cx(styles.input, {
              [styles.input__error]: error,
              [styles.input__atSymbol]: withAtSymbol,
            })}
          >
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <input
              type={type ? type : "text"}
              min={type === "number" ? 0 : null}
              name={name}
              aria-label={name}
              {...field}
              placeholder={placeholder}
              ref={inputRef}
            />
            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
