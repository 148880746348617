import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
// Queries
import { getPublicCMSData } from "api/cms.js";
// Utils
import { isAuthenticated } from "utils/isAuthenticated";
// Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import MembershipComponent from "components/Membership";
import PageWrapper from "components/PageWrapper";
import styles from "components/Membership/Membership.module.scss";

function Membership() {
  // If logged in route to dashboard
  if (isAuthenticated()) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <PageWrapper
        isPublicPage={true}
        loading={false}
        headerColor="#FFF7EE"
        isMembership={true}
        isAuthenticated={isAuthenticated()}
      >
        <div className={styles.membership__header}>
          <div className={styles.membership__header__border}>
            <div className={styles.membership__header__greenSpace}></div>
          </div>
          <div className={styles.membership__header__wrapper}>
            <p>
              The Lounge is no longer active and no longer accepting names for
              the waitlist. Thank you to all of our wonderful members and
              partners for cultivating this community with us.
            </p>
            <p>
              Stay tuned for any future community developments via the Girls
              Night In newsletter.
            </p>
          </div>
        </div>

        {/* <DataLoader
          load={async () => {
            try {
              const pageData = await getPublicCMSData("marketingPage");
              return pageData;
            } catch (e) {
              const res = await e.response.json();
              console.warn("DataLoader errors", res); // { errors; [ ... ] }
              return null;
            }
          }}
          fallback={() => <Loading />}
        >
          {({ data = [] }) => (
            <MembershipComponent content={data.length ? data[0] : {}} />
          )}
        </DataLoader> */}
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Membership);
