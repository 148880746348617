import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { isUserInBeta } from "api/auth.js";
import { updateInfo } from "state/actions/userActions.js";
import { EmailSchema } from "utils/validationSchema";

import styles from "components/SignupFlow/SignupFlow.module.scss";

import ErrorBanner from "components/Shared/ErrorBanner";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import FullLoungeLogo from "components/Logos/FullLoungeLogo";

function EmailEntry(props) {
  const [generalError, setGeneralError] = useState("");
  const emailInitial = props.emailParam !== "" ? props.emailParam : "";

  return (
    <div className={styles.emailSignup}>
      <div className={styles.emailSignup__wrapper}>
        <div className={styles.emailSignup__sidebar}>
          <img
            src="/icons/Lounge_Login.png"
            alt="Women sitting around drinking tea"
          />
        </div>
        <main className={styles.emailSignup__body}>
          <div className={styles.emailSignup__body__wrapper}>
            <FullLoungeLogo fillColor="#F5C38F" center="true" />
            <p className={styles.emailSignup__subtitle}>
              Shhhhh. Enter your email for something new from the Girls’ Night
              In team.
            </p>
            <Formik
              initialValues={{
                email: props.user.email ? props.user.email : emailInitial,
              }}
              validationSchema={EmailSchema}
              onSubmit={async (values, actions) => {
                const { email } = values;
                try {
                  const result = await isUserInBeta({ email });
                  // if email exists -> set email in state -> allow progression
                  props.dispatch(updateInfo({ ...props.user, email }));
                  return props.updateFn(props.updateValue);
                } catch (e) {
                  // if not email -> set error message
                  const res = await e.response.json();
                  console.warn("errors", res);
                  setGeneralError(res.errors[0].details);
                  actions.setSubmitting(false);
                }
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  {generalError !== "" && (
                    <ErrorBanner message={generalError} />
                  )}
                  <Input
                    type="email"
                    label=""
                    name="email"
                    placeholder="Email Address"
                  />
                  <FormButton
                    additionalClass="button__peach"
                    label="Unlock Invitation"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
            <div className={styles.emailSignup__login}>
              <p>Already a member?</p>
              <p>
                <a href="/login">Log In</a>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

EmailEntry.propTypes = {
  updateFn: PropTypes.func,
  updateValue: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EmailEntry);
