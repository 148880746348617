import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { signup, login } from "api/auth.js";
import { updateInfo } from "state/actions/userActions.js";
import { CreateAccountSchema } from "utils/validationSchema";

import styles from "components/SignupFlow/SignupFlow.module.scss";

import Checkbox from "components/FormElements/Checkbox";
import ErrorBanner from "components/Shared/ErrorBanner";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";

function CreateAccount(props) {
  const [generalError, setGeneralError] = useState("");
  const cmsData = props.cmsData ? props.cmsData : {};
  const user = props.user ? props.user : {};
  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={
            cmsData.create_title ? cmsData.create_title : "Set up your account"
          }
          text={
            cmsData.create_subtitle
              ? cmsData.create_subtitle
              : `As a member you'll be able to connect both online and offline with other community members. Let's create your account.`
          }
        />
        <div className={styles.signup__form}>
          <Formik
            initialValues={{
              email: user.email ? user.email : "",
              password: "",
              legal: false,
            }}
            validationSchema={CreateAccountSchema}
            onSubmit={async (values, actions) => {
              const { email, password } = values;
              try {
                const result = await signup({ email, password });
                if (result.userExists) {
                  return props.history.push({
                    pathname: `/login?userExists=true&email=${email}`,
                  });
                }
                const loginUser = await login({ email, password });
                //--- Update the component handler
                await props.dispatch(
                  updateInfo({
                    ...user,
                    email,
                    user_id: loginUser.user_id,
                  })
                );
                return props.updateFn(props.updateValue);
              } catch (e) {
                const res = await e.response.json();
                console.warn("ERROR: Create Account", res); // { errors; [ ... ] }
                if (res.errors[0].title === "PasswordStrengthError") {
                  setGeneralError(
                    "Password is too weak: Must have more than 8 characters with at least one lower case (a-z), upper case (A-Z) and number (0-9)"
                  );
                } else {
                  setGeneralError(res.errors[0].details);
                }

                actions.setSubmitting(false);
              }
            }}
            render={({ errors, status, touched, isSubmitting, values }) => (
              <Form>
                {generalError !== "" && <ErrorBanner message={generalError} />}
                {generalError ===
                  "This user already exists. Did you want to login?" && (
                  <p className={styles.signup__loginLink}>
                    <a href="/login">Login Here</a>
                  </p>
                )}
                <Input type="email" label="Email Address" name="email" />
                <Input
                  label="Create a Password"
                  name="password"
                  type="password"
                />
                <div className={styles.signup__form__passwordReqs}>
                  <p>Password Requirements:</p>
                  <ul>
                    <li>Must have 8 characters</li>
                    <li>At least 1 lower case(a-z)</li>
                    <li>At least 1 uppercase (A-Z)</li>
                    <li>At least 1 number</li>
                  </ul>
                </div>
                <Checkbox
                  name="legal"
                  label={
                    cmsData.create_legal
                      ? cmsData.create_legal
                      : `By clicking Create Account, I agree to receive email updates and marketing from The Lounge and Girls’ Night In. (You can unsubscribe at any time by clicking the unsubscribe link in the footer of any email you receive from us.)`
                  }
                />
                <FormButton
                  label="Create Account"
                  disabled={isSubmitting || values.legal !== true}
                />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

CreateAccount.propTypes = {
  updateFn: PropTypes.func,
  updateValue: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(CreateAccount);
