export default function createBaseUrl(baseVal, params, valsToInclude = []) {
  let baseUrl = baseVal + "?";
  for (let i = 0; i < valsToInclude.length; i += 1) {
    if (params[valsToInclude[i]]) {
      baseUrl = baseUrl + valsToInclude[i] + "=";
      for (let index = 0; index < params[valsToInclude[i]].length; index += 1) {
        baseUrl = baseUrl + params[valsToInclude[i]][index];

        if (!(index === params[valsToInclude[i]].length - 1)) {
          baseUrl = baseUrl + "+";
        } else baseUrl = baseUrl + "&";
      }
    }
  }
  return baseUrl;
}
