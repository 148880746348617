import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//--- Queries
import { getCMSData } from "api/cms";
//--- Components
import BackButton from "components/Button/BackButton";
import CreateEventBackWrapper from "components/Button/CreateEventBackWrapper";
import ComponentHandler from "containers/CreateEvent/ComponentHandler";
import PageWrapper from "components/PageWrapper";
import ProgressBar from "components/Shared/ProgressBar";

class CreateEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsData: false,
      createEventStep: "",
      previousStep: "",
      stepNumber: 0,
      eventId: "",
      memberRole: "",
      suggestedQuestions: [],
    };
  }
  //--- Sets the value for the back button
  // TODO
  regressFlow = (currentStep) => {
    switch (currentStep) {
      case "irl-url":
        return "";
      case "event-topic":
        return "irl-url";
      case "the-basics":
        return "event-topic";
      case "the-url":
        return "the-basics";
      case "the-venue":
        return "the-basics";
      case "venue-info":
        return "the-basics";
      case "ask-attendees":
        return "venue-info";
      case "event-preview":
        return "ask-attendees";
      case "event-final":
        return "event-preview";
      default:
        return "";
    }
  };

  async componentDidMount() {
    let highestRole = "";
    if (
      this.props.user.user_roles &&
      this.props.user.user_roles.includes("admin")
    ) {
      highestRole = "admin";
    } else if (
      this.props.user.user_roles &&
      this.props.user.user_roles.includes("host")
    ) {
      highestRole = "host";
    } else {
      highestRole = "member";
    }
    let result = [];
    let sugQuestions = [];
    try {
      result = await getCMSData("createEventContent");

      sugQuestions = result[0] ? result[0].suggested_questions : [];
    } catch (e) {
      const res = await e.response.json();
      console.warn("ERROR: CMS Query Create Event", res);
    }

    this.setState({
      cmsData: result,
      createEventStep: "irl-url",
      stepNumber: 0,
      memberRole: highestRole,
      suggestedQuestions: sugQuestions,
    });
  }
  //--- Sets state for the component handler to progress through the flow
  progressFlow = (updateValue, event_id) => {
    this.setState({
      previousStep: this.regressFlow(updateValue),
      stepNumber: this.state.stepNumber + 1,
      createEventStep: updateValue,
      eventId: event_id ? event_id : "",
    });

    window.scrollTo(0, 0);
  };

  render() {
    if (this.state.memberRole === "member") {
      return <Redirect to="/dashboard" />;
    }
    return (
      <PageWrapper>
        <ProgressBar progressStep={this.state.stepNumber} totalSteps={7} />
        <CreateEventBackWrapper>
          <BackButton
            progressStep={this.state.stepNumber}
            setCurrentStep={this.progressFlow}
            previousStep={this.state.previousStep}
          />
        </CreateEventBackWrapper>
        <ComponentHandler
          progressFlow={this.progressFlow}
          createEventStep={this.state.createEventStep}
          eventId={this.state.eventId}
          suggestedQuestions={this.state.suggestedQuestions}
          cmsData={this.state.cmsData}
        />
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(CreateEvent);
