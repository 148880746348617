import React, { memo } from "react";
import BlockContent from "@sanity/block-content-to-react";
// Styles
import styles from "components/OurStory/OurStory.module.scss";

const OurStoryHeader = memo(function ({
  letterAttributionAffiliation,
  letterAttributionName,
  letterAttributionTitle,
  letterHeading,
  letterTitle,
  rowOne,
  rowTwo,
}) {
  return (
    <div className={styles.story__letter}>
      <img className={styles.story__letter__icon} src={""} alt={""} />
      <h2>{letterHeading}</h2>
      <h3>{letterTitle}</h3>
      <div className={styles.story__letter__rows}>
        <div className={styles.story__letter__row}>
          <BlockContent blocks={rowOne} />
        </div>
        <div className={styles.story__letter__row}>
          <BlockContent blocks={rowTwo} />
        </div>
      </div>
      <div className={styles.story__letter__attribution}>
        <h4>{letterAttributionName}</h4>
        <p>{letterAttributionTitle}</p>
        <p>{letterAttributionAffiliation}</p>
      </div>
    </div>
  );
});

export default OurStoryHeader;
