import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";

function LegalRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
}

export default connect((state) => ({ user: state.user }))(LegalRoute);
