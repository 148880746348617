import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
//--- Styles
import styles from "components/FormElements/FormElements.module.scss";

function CharacterLimitTextBox({
  label,
  name,
  placeholder,
  rows = "5",
  limit = 50,
  initialValue = "",
}) {
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(initialValue.length);
  }, [initialValue]);

  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <div className={cx(styles.input, { [styles.input__error]: error })}>
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <textarea
              rows={rows}
              name={name}
              aria-label={name}
              {...field}
              onChange={(e) => {
                const currentInput = e.currentTarget;
                if (currentInput.value.length <= limit) {
                  form.setFieldValue(name, currentInput.value);
                  setCharCount(currentInput.value.length);
                } else {
                  form.setFieldValue(
                    name,
                    currentInput.value.substring(0, limit)
                  );
                  setCharCount(limit);
                }
              }}
              placeholder={placeholder}
            />
            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
            <div
              className={cx(styles.charCounter, {
                [styles.charCounter__errorCorrect]: error,
              })}
            >
              {charCount}/{limit}
            </div>
          </div>
        );
      }}
    </Field>
  );
}

CharacterLimitTextBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
};

export default CharacterLimitTextBox;
