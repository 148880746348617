import React from "react";
//--- Styles
import styles from "components/404/FourOhFour.module.scss";
//--- Components

export default function FourOhFour() {
  return (
    <div className={styles.fourohfour}>
      <h1>This page does not exist.</h1>
      <p>
        <a href="/">Return to home</a>
      </p>
    </div>
  );
}
