import React from "react";
import { connect } from "react-redux";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import Button from "components/Button";

function EventFinal(props) {
  return (
    <div className={styles.congrats}>
      <div className={styles.congrats__wrapper}>
        <div className={styles.congrats__image}>
          <img
            src={props.event.eventFinalImage + "?w=480&fit=max"}
            alt="Event preview"
          />
        </div>
        <div className={styles.congrats__body}>
          <div className={styles.congrats__body__wrapper}>
            <h1>Woooo! Your gathering is live and ready for loungers!</h1>
            <p className={styles.congrats__body__message}>
              <a
                href={`https://lounge.girlsnightin.co/event/${
                  props.event ? props.event.eventFinalId : ""
                }`}
              >
                Here’s a link
              </a>{" "}
              — only members will be able to access it.
            </p>
            <Button link="/hosting" label="Back to my Gatherings" />
            <p className={styles.congrats__body__moreInfo}>
              More questions? Contact{" "}
              <a href="mailto:thelounge@girlsnightin.co">
                thelounge@girlsnightin.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EventFinal);
