import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import store from "state/store.js";

import AdminOnlyRoute from "components/AdminOnlyRoute.js";
import CancelledRoute from "components/CancelledRoute.js";
import LegalRoute from "components/LegalRoute.js";
import PrivateRoute from "components/PrivateRoute.js";
import PublicOnlyRoute from "components/PublicOnlyRoute.js";
import SignupRoute from "components/SignupRoute";

import Admin from "containers/Admin";
import Announcement from "containers/Announcement";
import AnnouncementListing from "containers/AnnouncementListing";
import Article from "containers/Article";
import ArticleListing from "containers/ArticleListing";
import Cancelled from "containers/Cancelled";
import CommunityGuidelines from "containers/CommunityGuidelines";
import CreateEvent from "containers/CreateEvent";
import EditAccount from "containers/EditAccount";
import EditEvent from "containers/EditEvent";
import Event from "containers/Event";
import EventsWrapper from "containers/EventListing";
import FAQs from "containers/FAQs";
import FourOhFour from "components/404";
import DashboardWrapper from "containers/Dashboard";
import Hosting from "containers/Hosting";
import Login from "containers/Login";
import Logout from "containers/Logout";
import Membership from "containers/Membership";
import Members from "containers/Members";
import Onboarding from "containers/Onboarding";
import OurStory from "containers/OurStory";
import Refunds from "containers/Refunds";
import Signup from "containers/Signup";
import Support from "containers/Support";
import Perks from "containers/Perks";
import User from "containers/User";
import Terms from "containers/Terms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe("pk_test_H6cv8rU1yGM6T7c8yLjesTBc00Fst9DWo1");

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" exact component={Membership} />
            <Route path="/our-story" exact component={OurStory} />
            <Route path="/logout" component={Logout} />
            <LegalRoute path="/faqs" component={FAQs} />
            <LegalRoute path="/terms" component={Terms} />
            <LegalRoute path="/refunds" component={Refunds} />
            <LegalRoute path="/support" component={Support} />
            <LegalRoute
              path="/community-guidelines"
              component={CommunityGuidelines}
            />
            <LegalRoute path="/announcements" component={AnnouncementListing} />
            <LegalRoute path="/announcement/:id" component={Announcement} />
            <LegalRoute path="/posts" component={ArticleListing} />
            <LegalRoute path="/post/:id" component={Article} />
            <SignupRoute path="/signup" component={Signup} />
            <PublicOnlyRoute path="/login" component={Login} />
            <AdminOnlyRoute path="/admin" component={Admin} />
            {/* <CancelledRoute path="/update-membership" component={Cancelled} /> */}

            <PrivateRoute path="/create-event" component={CreateEvent} />
            <PrivateRoute
              path="/dashboard"
              exact
              component={DashboardWrapper}
            />
            <PrivateRoute path="/edit-account" exact component={EditAccount} />
            <PrivateRoute path="/edit-event/:id" exact component={EditEvent} />
            <PrivateRoute path="/event/:id" exact component={Event} />
            <PrivateRoute path="/gatherings" component={EventsWrapper} />
            <PrivateRoute path="/hosting" exact component={Hosting} />
            <PrivateRoute path="/members" exact component={Members} />
            <PrivateRoute path="/onboarding" exact component={Onboarding} />
            <PrivateRoute path="/perks" exact component={Perks} />
            <PrivateRoute path="/user/:id" exact component={User} />
            <Route component={FourOhFour} />
          </Switch>
        </Router>
      </Provider>
    </Elements>
  );
}
