import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import cx from "classnames";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import FormButton from "components/Button/FormButton";
import SelectBoxLarge from "components/FormElements/SelectBox/SelectBoxLarge";
import SideBanner from "components/Shared/SideBanner";

const TOPICS = [
  "Book club",
  "Food",
  "Discussion",
  "Swap",
  "Crafts",
  "Wellness",
  "Entertainment",
  "Something Else",
];

function TheTopic(props) {
  const [eventType, selectEventType] = useState("");
  const cmsData = props.cmsData ? props.cmsData : {};

  useEffect(() => {
    props.event.eventType &&
      props.event.eventType !== "" &&
      selectEventType(props.event.eventType);
  }, []);

  return (
    <div className={styles.createEvent}>
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          cmsImage={cmsData.topic_side_image ? cmsData.topic_side_image : null}
          dynamicText={true}
          title={
            cmsData.topic_side_title ? cmsData.topic_side_title : "The Topic"
          }
          text={cmsData.topic_side_text ? cmsData.topic_side_text : null}
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                props.dispatch(
                  createEventAction({
                    ...props.event,
                    eventType,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ isSubmitting }) => (
                <Form>
                  <section>
                    <div
                      className={cx(
                        styles.createEvent__selectorCards,
                        styles.createEvent__selectorCards__topic
                      )}
                    >
                      {TOPICS.map((type, i) => (
                        <div
                          className={styles.createEvent__selectorCards__item}
                          key={i}
                        >
                          <SelectBoxLarge
                            value={type}
                            onclick={selectEventType}
                            currentValue={eventType}
                          />
                        </div>
                      ))}
                    </div>
                  </section>
                  <FormButton
                    label="Next up: The Basics"
                    disabled={isSubmitting || eventType === ""}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(TheTopic);
