import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";
//--- Styles
import styles from "components/Onboarding/Onboarding.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import FormButton from "components/Button/FormButton";
import Selector from "components/FormElements/Selector";
import SideBanner from "components/Shared/SideBanner";
//--- Autocomplete Data
import Industries from "components/Shared/AutocompleteData/Industries";

function Details(props) {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={props.cmsData.details_title && props.cmsData.details_title}
          text={
            props.cmsData.details_subtitle && props.cmsData.details_subtitle
          }
          cmsImage={props.cmsData.details_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <Formik
            initialValues={{
              industry: props.user.industry ? props.user.industry : "",
              bio: props.user.bio ? props.user.bio : "",
              sign: props.user.sign ? props.user.sign : "",
            }}
            onSubmit={async (values, actions) => {
              const { industry, bio, sign } = values;
              props.dispatch(
                updateInfo({ ...props.user, industry, bio, sign })
              );
              return props.updateFn(props.updateValue);
            }}
            render={({ errors, status, touched, isSubmitting, values }) => (
              <Form>
                <div className={styles.profile__body__section}>
                  <h3>
                    {props.cmsData.details_input_industry &&
                      props.cmsData.details_input_industry}
                  </h3>
                  <AutocompleteInput
                    initialValue={props.user.industry}
                    label=""
                    limitValues
                    listValues={Industries}
                    name="industry"
                    placeholder="Select Industry"
                    type="text"
                  />
                </div>
                <div className={styles.profile__body__section}>
                  <h3>
                    {props.cmsData.details_input_horoscope &&
                      props.cmsData.details_input_horoscope}
                  </h3>
                  <Selector
                    label=""
                    name="sign"
                    placeholder="Select Horoscope"
                    value={values.sign}
                    values={[
                      " Aries",
                      "Taurus",
                      "Gemini",
                      "Cancer",
                      "Leo",
                      "Virgo",
                      "Libra",
                      "Scorpio",
                      "Sagittarius",
                      "Capricorn",
                      "Aquarius",
                      "Pisces",
                    ]}
                  />
                </div>
                <div className={styles.profile__body__section}>
                  <h3>
                    {props.cmsData.details_input_bio_title &&
                      props.cmsData.details_input_bio_title}
                  </h3>
                  <p>
                    {props.cmsData.details_input_bio_text &&
                      props.cmsData.details_input_bio_text}
                  </p>
                  <CharacterLimitTextBox
                    initialValue={props.user.bio}
                    limit={300}
                    name="bio"
                    placeholder="Ex. I'm a multi-hyphenate writer-baker-coder. I’m weirdly passionate about finding the perfect recipe for scones. Please let me know if you’ve found one."
                  />
                  <FormButton
                    label="Next up: Downtime"
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Details);
