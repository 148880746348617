import merge from "lodash.merge";

// Get the (optionally nested) value from the redux state
const extractNestedValue = (keyString, state) => {
  const keys = keyString.split(".");
  return keys.reduce((val, key) => val && val[key], state);
};

// Create a (optionally nested) state object
const createNestedState = (keyString, storedValue) =>
  keyString
    .split(".")
    .reverse()
    .reduce(
      (val, key) => ({
        [key]: val,
      }),
      storedValue
    );

export const sessionStateSaver = (stateKeys) => (store) => (next) => (
  action
) => {
  // Get the state before and after the action was performed
  const previousState = store.getState();
  next(action);
  const nextState = store.getState();

  if (typeof window === "undefined" || !window.sessionStorage) return;

  // Respond to changes
  stateKeys.forEach((stateKey) => {
    const previousValue = extractNestedValue(stateKey, previousState);
    const nextValue = extractNestedValue(stateKey, nextState);
    if (previousValue !== nextValue) {
      const key = `redux_session_save_${stateKey}`;
      window.sessionStorage.setItem(key, JSON.stringify(nextValue));
    }
  });
};

export const getInitialStateFromSessionStorage = (stateKeys) => {
  // Don't do it on SSR or if there's no sessionStorage
  if (typeof window === "undefined" || !window.sessionStorage) return {};

  const initialState = stateKeys.reduce((acc, stateKey) => {
    const key = `redux_session_save_${stateKey}`;
    const stateValue = window.sessionStorage.getItem(key);
    if (stateValue) {
      try {
        const value = JSON.parse(stateValue);
        const nestedState = createNestedState(stateKey, value);
        return merge(acc, nestedState);
      } catch (err) {
        console.error(
          "couldn't JSON.parse the session stored state object... deleting it from sessionStorage"
        );
        delete sessionStorage[key];
      }
    }
    return acc;
  }, {});

  return initialState;
};
