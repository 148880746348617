import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
///--- Queries & Actions
import { getEvents } from "api/event.js";
import { getEventsCMSData } from "api/cms.js";
//--- Utils
import { pageParams } from "utils/parseQueryParams";
import createBaseUrl from "utils/createBaseUrl";
//--- Components
import DataLoader from "components/DataLoader.js";
import EventListing from "components/Events/EventListing";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

const perPage = 8;

function EventsWrapper(props) {
  //--- Get Query Params
  const urlParams = new URLSearchParams(props.location.search);
  let user = props.user ? props.user : {};
  //--- Pagination
  const pageParam = urlParams.get("page") ? urlParams.get("page") : "1";
  //--- Available Params for Filtering
  const page = pageParams(pageParam, perPage);
  const filterObj = {};

  urlParams.delete("page");

  urlParams.forEach((param, index) => {
    filterObj[index] = param.split("+");
  });
  // venue_type: all, virtual, inperson
  // type

  const baseUrl = createBaseUrl("/gatherings", filterObj, [
    "type",
    "community",
    "eventRange",
  ]);
  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            let currentUser = user._id ? user._id : user.user_id;
            const events = await getEvents(page, filterObj);
            const pageData = await getEventsCMSData(
              "eventsContent",
              currentUser
            );
            return { events, pageData };
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {({ data }) => {
          return (
            <EventListing
              events={data.events || []}
              cmsData={data.pageData || null}
              perPage={perPage}
              baseUrl={baseUrl}
              currentPage={pageParam}
              isFiltering={filterObj !== {}}
              venueType={filterObj.venue_type}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(withRouter(EventsWrapper));
