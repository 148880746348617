import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SelectBox from "components/FormElements/SelectBox";
import SideBanner from "components/Shared/SideBanner";

const AMENITIES = [
  {
    name: "Light Snacks",
  },
  {
    name: "Alcoholic Beverages",
  },
  {
    name: "Non-alcoholic Beverages",
  },
  {
    name: "Handicap Accessible",
  },
  {
    name: "Pet(s) on Premises",
  },
];

function VenueInfo(props) {
  const [amenities, setAmenities] = useState([]);
  const [updateValue, forceUpdate] = useState(1);
  const cmsData = props.cmsData ? props.cmsData : {};

  useEffect(() => {
    props.event.amenities &&
      props.event.amenities.length !== 0 &&
      setAmenities(props.event.amenities);
  }, []);

  function addAmenities(value) {
    let newAmenities = amenities;
    if (amenities && amenities.includes(value)) {
      let index = newAmenities.indexOf(value);
      newAmenities.splice(index, 1);
    } else {
      newAmenities.push(value);
    }
    setAmenities(newAmenities);
    return forceUpdate(updateValue + 1);
  }
  const eventLocation = props.event.eventLocation;
  return (
    <div className={styles.createEvent}>
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          cmsImage={
            cmsData.details_side_image ? cmsData.details_side_image : null
          }
          title={cmsData ? cmsData.details_side_title : "The Details"}
          text={
            cmsData ? cmsData.details_side_text : "What can loungers expect?"
          }
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{
                petsInfo: props.event.petsInfo ? props.event.petsInfo : "",
                attendeeNote: props.event.attendeeNote
                  ? props.event.attendeeNote
                  : "",
                availableSeats: props.event.availableSeats
                  ? props.event.availableSeats
                  : "",
                guest_limit: props.event.guest_limit
                  ? props.event.guest_limit
                  : 1,
              }}
              onSubmit={async (values, actions) => {
                const {
                  petsInfo,
                  attendeeNote,
                  availableSeats,
                  guest_limit,
                } = values;
                props.dispatch(
                  createEventAction({
                    ...props.event,
                    petsInfo,
                    attendeeNote,
                    amenities,
                    availableSeats,
                    guest_limit,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <section>
                    <h2>How many seats are available?</h2>
                    <p>
                      For discussion-based gatherings like book clubs, keep in
                      mind that everyone should have a place to comfortably sit.
                    </p>
                    <Input
                      name="availableSeats"
                      placeholder="5"
                      type="number"
                    />
                  </section>
                  {eventLocation !== "Virtual" && (
                    <section>
                      <h2>What amenities or features will be provided?</h2>
                      <p>
                        Check all that apply. We recommend light bites, and
                        ensure you account for common allergies.
                      </p>
                      <div
                        className={cx(
                          styles.createEvent__selectorCards,
                          styles.createEvent__selectorCards__topic
                        )}
                      >
                        {AMENITIES.map((item, i) => (
                          <div
                            className={styles.createEvent__selectorCards__item}
                            key={i}
                          >
                            <SelectBox
                              value={item.name}
                              onclick={addAmenities}
                              currentValue={
                                amenities && amenities.includes(item.name)
                                  ? item.name
                                  : ""
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </section>
                  )}
                  <section
                    style={{
                      display: `${
                        amenities && amenities.includes("Pet(s) on Premises")
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    <h2>What pets will be present?</h2>
                    <Input name="petsInfo" type="text" />
                  </section>
                  <section>
                    <h2>How many guests can one attendee bring?</h2>
                    <Input name="guest_limit" type="number" />
                  </section>
                  <section>
                    <h2>Additional Notes to Attendees</h2>
                    <CharacterLimitTextBox
                      limit={550}
                      name="attendeeNote"
                      placeholder="Hey all, I’m so excited to talk about this book! One of my favorite reads so far. To get into my building on Friday, please text me at: 212-756-5309."
                    />
                  </section>
                  <FormButton
                    label="Last step: Ask attendees"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(VenueInfo);
