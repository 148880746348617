import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { createEventAction } from "state/actions/createEventActions.js";
import { createEventVenue } from "utils/validationSchema";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import FormButton from "components/Button/FormButton";
import FormRow from "components/FormElements/FormRow";
import Input from "components/FormElements/Input";
import SelectBoxLarge from "components/FormElements/SelectBox/SelectBoxLarge";
import SideBanner from "components/Shared/SideBanner";
import StateSelector from "components/FormElements/StateSelector";
//--- Autocomplete Data
import DCNeighborhoods from "components/Shared/AutocompleteData/DCNeighborhoods";
import NYCCNeigborhoods from "components/Shared/AutocompleteData/NYC";

function TheVenue(props) {
  const [listValue, setListValue] = useState([]);
  const cmsData = props.cmsData ? props.cmsData : {};

  function changeCommunity(val) {
    if (val === "Washington D.C.") {
      return setListValue(DCNeighborhoods);
    } else if ((val = "New York City")) {
      return setListValue(NYCCNeigborhoods);
    } else {
      //--- Allow Freeform Neighborhood Input
      return setListValue([]);
    }
  }

  useEffect(() => {
    props.user.community && changeCommunity(props.user.community);
  }, []);

  return (
    <div className={styles.createEvent}>
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          cmsImage={cmsData.venue_side_image ? cmsData.venue_side_image : null}
          title={
            cmsData.venue_side_title ? cmsData.venue_side_title : "The Venue"
          }
          text={
            cmsData.venue_side_text
              ? cmsData.venue_side_text
              : "Sounds fun! Where are you hosting it?"
          }
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{
                venueName: props.event.venueName ? props.event.venueName : "",
                neighborhood: props.event.neighborhood
                  ? props.event.neighborhood
                  : "",
                address_1: props.event.address_1 ? props.event.address_1 : "",
                address_2: props.event.address_2 ? props.event.address_2 : "",
                city: props.event.city ? props.event.city : "",
                state: props.event.state ? props.event.state : "",
                zip: props.event.zip ? props.event.zip : "",
              }}
              onSubmit={async (values, actions) => {
                const {
                  venueName,
                  neighborhood,
                  address_1,
                  address_2,
                  city,
                  state,
                  zip,
                } = values;
                props.dispatch(
                  createEventAction({
                    ...props.event,
                    venueName,
                    neighborhood,
                    address_1,
                    address_2,
                    city,
                    state,
                    zip,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              validationSchema={createEventVenue}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <section>
                    <Input
                      name="venueName"
                      label="Venue Name"
                      placeholder={`${
                        props.user.first_name ? props.user.first_name : "My"
                      } apartment`}
                      type="text"
                    />
                    <p className={styles.createEvent__body__formMessage}>
                      We won’t show your private address to anyone who isn’t
                      RSVP’d to your event
                    </p>
                    <AutocompleteInput
                      initialValue={props.event.neighborhood || ""}
                      name="neighborhood"
                      label="Neighborhood"
                      listValues={listValue}
                      placeholder="Neighborhood"
                      type="text"
                    />
                    <p className={styles.createEvent__body__formMessage}>
                      This neighborhood will show up with the event listing,
                      even to non-attendees.
                    </p>
                  </section>
                  <section>
                    <FormRow>
                      <Input
                        name="address_1"
                        label="Address"
                        placeholder="1234 Street Name"
                        type="text"
                      />
                      <Input
                        name="address_2"
                        label="Apt/Suite"
                        placeholder="Apt/Suite"
                        type="text"
                      />
                    </FormRow>
                    <FormRow>
                      <Input
                        name="city"
                        label="City"
                        placeholder="City"
                        type="text"
                      />
                      <StateSelector
                        error={errors.state && touched.state}
                        label="State"
                        name="state"
                        type="text"
                      />
                      <Input
                        name="zip"
                        label="Zipcode"
                        placeholder="11111"
                        type="text"
                      />
                    </FormRow>
                  </section>
                  <FormButton
                    label="Next up: The Details"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(TheVenue);
