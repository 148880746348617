import React, { useCallback, useState } from "react";
import cx from "classnames";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { updateUser, processPayment } from "api/user.js";
import { updateInfo } from "state/actions/userActions.js";

import styles from "components/SignupFlow/SignupFlow.module.scss";
import inputStyles from "components/FormElements/FormElements.module.scss";

import Checkbox from "components/FormElements/Checkbox";
import FormButton from "components/Button/FormButton";
import SideBanner from "components/Shared/SideBanner";

const zeroPad = (n) => ("00" + n).slice(-2);

function ReviewInformation({
  cmsData = {},
  dispatch,
  updateFn,
  updateValue,
  user,
}) {
  const { stripe_source } = user;
  const { card = {} } = stripe_source;
  const { last4 = "****", exp_month, exp_year } = card;
  const expMonth = exp_month ? zeroPad(exp_month) : "??";
  const expYear = exp_year ? zeroPad(exp_year) : "??";
  const [errorFromServer, setErrorFromServer] = useState(null);

  const onSuccess = useCallback(async () => {
    //--- Update Sanity with Profile Information
    try {
      await updateUser(user.user_id, {
        name: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        neighborhoods: user.neighborhoods,
        community: user.community,
        subscription_status: "active",
        stripe_source: stripe_source.id,
        user_roles: ["member"],
      });

      await dispatch(updateInfo({ ...user, subscription_status: "active" }));
      return updateFn(updateValue);
    } catch {
      // TODO: Retry sanity update in this scenario
      setErrorFromServer("Error processing payment - please try again");
    }
  }, [dispatch, stripe_source, updateFn, updateValue, user]);

  async function handleSubmit({ verify }, { setSubmitting }) {
    let errorMessage;
    if (!verify) return;
    try {
      const {
        email,
        first_name,
        last_name,
        product_select,
        stripe_source,
        user_id,
      } = user;

      setErrorFromServer(null);
      const fullName = `${first_name} ${last_name}`;
      //--- Process Payment on Submit
      const {
        success,
        reason,
        paymentStatus,
        paymentIntentSecret,
      } = await processPayment(
        product_select,
        stripe_source.id,
        user_id,
        email,
        fullName
      );

      if (success) return await onSuccess();
      //--- Handle errors
      setErrorFromServer(reason); // update the UI right away
      errorMessage = reason;
      //--- Handle async auth process
      if (paymentStatus === "requires_action") {
        setTimeout(() => setErrorFromServer(null), 1750); // clear the error during the auth modal
        // Stripe Production:
        const stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY);
        // Stripe Test:
        // const stripe = window.Stripe(STRIPE_TEST_PUBLIC_KEY);
        const { error, paymentIntent } = await stripe.handleCardPayment(
          paymentIntentSecret
        );

        if (paymentIntent && paymentIntent.status === "succeeded") {
          return await onSuccess();
        }

        setErrorFromServer(error.message);
      }
    } catch (e) {
      if (e.response) {
        const res = await e.response.json();
        const { errors } = res || {};
        errorMessage =
          Array.isArray(errors) && errors.length > 0 && errors[0].details;
        console.warn("errors", res);

        if (errorMessage === "Invoice is already paid") {
          setTimeout(() => onSuccess("active"), 1000);
          return await onSuccess();
        }
      } else {
        console.error(e);
      }
      setErrorFromServer(
        errorMessage ||
          "Payment could not be processed. Please contact us for help."
      );
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={cmsData.review_title}
          text={cmsData.review_subtitle}
        />
        <div className={styles.signup__confirm__wrapper}>
          <div className={styles.signup__confirm__notice}>
            <h2>
              {user.product_select === "annual"
                ? `You’ll be charged $130 for 1 year of Membership to The Lounge.`
                : `You’ll be charged $12 monthly for Membership to The Lounge.`}
            </h2>
          </div>
          <div className={styles.signup__confirm}>
            <div className={styles.signup__confirm__section}>
              <h2>Email</h2>
              <p>{user.email && user.email}</p>
            </div>
            <div className={styles.signup__confirm__section}>
              <h2>The Basics</h2>
              <p>{`${user.first_name} ${user.last_name}`}</p>
              <p>{user.community}</p>
              <div
                className={styles.signup__confirm__edit}
                onClick={() => updateFn("basic-info")}
              >
                Edit
              </div>
            </div>

            <div className={styles.signup__confirm__section}>
              <h2>Payment Details</h2>
              <p>**** **** **** {last4}</p>
              <p>
                Expires {expMonth}/{expYear}
              </p>
              <div
                className={styles.signup__confirm__edit}
                onClick={() => updateFn("payment")}
              >
                Edit
              </div>
            </div>
          </div>
          <Formik
            initialValues={{ verify: false }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              verify: Yup.bool().oneOf(
                [true],
                "You must agree to the Terms & Conditions"
              ),
            })}
            render={({ isSubmitting, values }) => (
              <Form>
                <Checkbox
                  name="verify"
                  labelHtml={`By becoming a member, I agree to the <a href="/terms" target="_blank" rel="noreferrer">Terms & Conditions and the Privacy Policy</a> of Girls Night In.`}
                />

                {errorFromServer && (
                  <p
                    className={cx(
                      inputStyles.input__error__message,
                      styles.signup__error
                    )}
                  >
                    {errorFromServer}
                  </p>
                )}
                <FormButton
                  label="Confirm &amp; Pay"
                  disabled={isSubmitting || values.verify !== true}
                />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(ReviewInformation);
