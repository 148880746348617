import React, { useState } from "react";
import { connect } from "react-redux";
//--- Queries & Actions
import { updateInfo } from "state/actions/userActions.js";
//--- Styles
import styles from "components/EditAccount/EditAccount.module.scss";
//--- Components
import CancelAccount from "components/EditAccount/Account/CancelAccount";
import EditInfo from "components/EditAccount/Account/EditInfo";
import EditMailingAddress from "components/EditAccount/Account/EditMailingAddress";
import EditEmail from "components/EditAccount/Account/EditEmail";
import EditPassword from "components/EditAccount/Account/EditPassword";

function EditAccount({ user, userData, dispatch }) {
  const [userObject, setUserObject] = useState({
    ...user,
    ...userData,
  });

  function updateUserInfo(newInfo) {
    const updatedInfo = {
      ...userObject,
      ...newInfo,
    };

    dispatch(updateInfo(updatedInfo));
    setUserObject(updatedInfo);
  }

  const sectionProps = {
    user: userObject,
    updateUserInfo,
  };

  return (
    <div className={styles.editAccount__body}>
      <h2>Account</h2>

      <EditInfo {...sectionProps} />
      <EditMailingAddress {...sectionProps} />
      <EditEmail {...sectionProps} />
      <EditPassword {...sectionProps} />
      <CancelAccount />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(EditAccount);
