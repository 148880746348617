import React from "react";
import { connect } from "react-redux";
//--- Queries & Actions
import { getCMSData } from "api/cms.js";
import { getUser } from "api/user.js";
import { setCurrentUser } from "state/actions/userActions.js";
import { setCreateAccountContent } from "state/actions/cmsContentActions.js";
//--- Utils
import { isAuthenticated } from "utils/isAuthenticated";
import { clearLoginCookie } from "utils/clearLoginCookie";
//--- Components
import ComponentHandler from "containers/Signup/ComponentHandler";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userStep: "",
      previousStep: "",
      stepNumber: 0,
      loading: true,
      emailParam: "",
      hasMounted: false,
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let userAuthenticated = isAuthenticated();

      if (!userAuthenticated) {
        //--- Clear Cookie
        clearLoginCookie();
      }
      if (!this.props.cms.signupContent) {
        try {
          const pageData = await getCMSData("signupContent");
          this.props.dispatch(
            setCreateAccountContent({
              ...this.props.cms,
              signupContent: pageData[0],
            })
          );
        } catch (e) {
          const res = await e.response.json();
          console.warn("errors", res); // { errors; [ ... ] }
        }
      }
    }
  }

  async updateUserState(user_id) {
    try {
      const userQuery = await getUser(user_id);
      return this.props.dispatch(
        setCurrentUser({ user_id, ...this.props.user, ...userQuery[0] })
      );
    } catch (e) {
      const res = await e.response.json();
      return res;
    }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const emailParam = urlParams.get("email") ? urlParams.get("email") : "";
    const userAuthenticated = isAuthenticated();
    if (userAuthenticated && this.props.user.user_id) {
      await this.updateUserState(this.props.user.user_id);
    }
    let startingStep = "email-entry";
    let stepNumber = 0;
    if (!this.props.cms.signupContent && userAuthenticated) {
      try {
        const pageData = await getCMSData("signupContent");
        this.props.dispatch(
          setCreateAccountContent({
            ...this.props.cms,
            signupContent: pageData[0],
          })
        );
      } catch (e) {
        const res = await e.response.json();
        console.warn("ERROR: SIGNUP CMS", res);
      }
    }

    if (
      userAuthenticated &&
      this.props.user.subscription_status &&
      this.props.user.subscription_status !== "pending"
    ) {
      startingStep = "payment";
      stepNumber = 5;
    } else if (userAuthenticated && this.props.user.community) {
      startingStep = "location";
      stepNumber = 4;
    } else if (userAuthenticated) {
      startingStep = "basic-info";
      // startingStep = "payment";
      stepNumber = 3;
    }

    this.setState({
      userStep: startingStep,
      previousStep: this.regressFlow(startingStep),
      stepNumber: stepNumber,
      emailParam: emailParam,
      hasMounted: true,
    });
  }

  regressFlow = (currentStep) => {
    switch (currentStep) {
      case "email-entry":
        return "";
      case "create-account":
        return "email-entry";
      case "basic-info":
        return "";
      case "location":
        return "basic-info";
      case "neighborhood":
        return "location";
      case "payment":
        return "neighborhood";
      case "review-info":
        return "payment";
      default:
        return "";
    }
  };

  progressFlow = (updateValue) => {
    if (updateValue === "process-account") {
      return (window.location = "/onboarding");
    }
    this.setState({
      previousStep: this.regressFlow(updateValue),
      stepNumber: this.state.stepNumber + 1,
      userStep: updateValue,
    });
  };

  render() {
    return (
      <>
        {this.state.hasMounted && (
          <ComponentHandler
            cmsData={
              this.props.cms.signupContent ? this.props.cms.signupContent : {}
            }
            setCurrentStep={this.progressFlow}
            signupStep={this.state.userStep}
            emailParam={this.state.emailParam}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Signup);
