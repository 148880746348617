import React from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

import Button from "components/Button/index.js";

function TheLatest({
  buttonLink = "#",
  buttonText = "See all updates",
  items = [],
  text = "Exclusive content and updates from The Lounge HQ.",
  title = "The Latest",
}) {
  return (
    <div className={styles.dashboard__latest}>
      <div className={styles.dashboard__latest__wrapper}>
        <div className={styles.dashboard__latest__header}>
          <h2>{title}</h2>
          <p>{text}</p>
          <img
            src="/icons/iphone-colored.png"
            alt="Iphone and coffee"
            className={styles.dashboard__latest__header__icon}
          />
        </div>

        <div className={styles.dashboard__latest__items}>
          {items.map((item, i) => {
            let type = item.article_type ? item.article_type : "announcement";
            let title =
              type === "announcement"
                ? item.announcement_title
                : item.article_title;
            let imageUrl = `${
              type === "announcement"
                ? item.announcementImage
                : item.articleImage
            }?w=500`;

            let url = `${
              type === "announcement" ? "/announcement/" : "/post/"
            }${item._id}`;
            return (
              <a key={i} className={styles.dashboard__latest__item} href={url}>
                <span style={{ backgroundImage: `url("${imageUrl}")` }}></span>
                <p>{type}</p>
                <h3>{title}</h3>
              </a>
            );
          })}
        </div>
        <div className={styles.dashboard__latest__button}>
          <Button
            label={buttonText}
            link={buttonLink}
            additionalClass="button__transparent"
          />
        </div>
      </div>
    </div>
  );
}

export default TheLatest;
