import React, { useCallback, useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";
// Redux
import { updateInfo } from "state/actions/userActions.js";
// Styles
import styles from "components/SignupFlow/SignupFlow.module.scss";
// Components
import SideBanner from "components/Shared/SideBanner";
import StripeForm from "components/Shared/Stripe/StripeForm";

function Payment(props) {
  const [selectedProduct, setSelectedProduct] = useState("");
  const { cmsData, user = {} } = props;

  const confirmationFunction = useCallback((result) => {
    if (!result.source) return null;
    props.dispatch(
      updateInfo({
        ...props.user,
        stripe_source: result.source,
      })
    );
    return props.updateFn(props.updateValue);
  }, []);

  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={cmsData.payment_title && cmsData.payment_title}
          text={cmsData.payment_subtitle && cmsData.payment_subtitle}
        />
        <div className={styles.signup__formStripe}>
          <h2>{cmsData.payment_membership && cmsData.payment_membership}</h2>
          <div className={styles.signup__productSelect}>
            <div
              className={cx(styles.signup__productSelect__item, {
                [styles.signup__productSelect__item__active]:
                  selectedProduct === "annual",
              })}
              onClick={() => setSelectedProduct("annual")}
            >
              <span>10% savings</span>
              <h3>ANNUAL</h3>
              <h4>$130</h4>
              <p>per year</p>
            </div>
            <div
              className={cx(styles.signup__productSelect__item, {
                [styles.signup__productSelect__item__active]:
                  selectedProduct === "monthly",
              })}
              onClick={() => setSelectedProduct("monthly")}
            >
              <h3>MONTHLY</h3>
              <h4>$12</h4>
              <p>per month</p>
            </div>
          </div>
          <StripeForm
            selectedProduct={selectedProduct}
            user={user}
            confirmationFunction={confirmationFunction}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(Payment);
