import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
//--- Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";
import SingleEvent from "components/Events/SingleEvent";
import { getEvent } from "api/event.js";

export default withRouter(
  connect(mapStateToProps)(function Event(props) {
    const event_id = props.match.params.id;
    const user_id = props.user.user_id;

    return (
      <PageWrapper>
        <DataLoader
          load={async () => {
            try {
              const event = await getEvent(event_id, user_id);
              return event;
            } catch (e) {
              const res = await e.response.json();
              console.warn("errors", res); // { errors; [ ... ] }
            }
          }}
          fallback={() => {
            return <Loading />;
          }}
        >
          {({ data }) => {
            if (!data) return null;
            return <SingleEvent eventData={data[0] || {}} />;
          }}
        </DataLoader>
      </PageWrapper>
    );
  })
);

function mapStateToProps(state) {
  return {
    ...state,
  };
}
