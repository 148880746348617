import React, { useEffect, useState } from "react";
//--- Queries
import {
  cancelEvent,
  deleteEvent,
  unpublishEvent,
  publishEvent,
} from "api/event";
//--- Utils
import { compareTimePast, compareTimeUpcoming } from "utils/compareTime";
//--- Styles
import styles from "components/HostEvents/HostEvents.module.scss";
//--- Components
import AddGuestModal from "components/Events/AddGuestModal";
import Button from "components/Button";
import CancelConfirm from "components/HostEvents/CancelConfirm";
import DeleteConfirm from "components/HostEvents/DeleteConfirm";
import ErrorBanner from "components/Shared/ErrorBanner";
import EmailAttendeesModal from "components/HostEvents/EmailAttendeesModal";
import EventCard from "components/Shared/EventCard";
import Loading from "components/Loading";
import ManageAttendeesModal from "components/HostEvents/ManageAttendeesModal";
import UnpublishConfirm from "components/HostEvents/UnpublishConfirm";
import UpcomingPast from "components/Shared/UpcomingPast";
import WaitlistModal from "components/HostEvents/WaitlistModal";

function HostEvents({ userData }) {
  const [loading, setLoading] = useState(false);
  const [eventRange, setEventRange] = useState("upcoming");
  const [error, setError] = useState(false);
  const [eventsDisplay, setEventsDisplay] = useState([]);
  const [cancelOpen, toggleCancelOpen] = useState("");
  const [deleteOpen, toggleDeleteOpen] = useState("");
  const [unpublishOpen, toggleUnpublishOpen] = useState("");
  // const [addGuestOpen, toggleAddGuestOpen] = useState('');
  const [emailAttOpen, toggleEmailAttOpen] = useState("");
  const [manageAttOpen, toggleManageAttOpen] = useState("");
  const [waitlistOpen, toggleWaitlistOpen] = useState("");

  useEffect(() => {
    userData.eventHosted && changeEventView("upcoming");
  }, []);

  async function cancelEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await cancelEvent({ event_id });
      toggleCancelOpen("");
      setLoading(false);
      window.location = "/hosting";
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      console.warn("errors", res);
    }
  }

  async function removeRSVP(event_id, user_id) {
    setLoading(true);
    try {
      const result = await cancelEvent({ event_id });
      toggleCancelOpen("");
      setLoading(false);
      window.location = "/hosting";
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      console.warn("errors", res);
    }
  }

  async function deleteEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await deleteEvent({ event_id });
      toggleCancelOpen("");
      setLoading(false);
      window.location = "/hosting";
      return;
    } catch (e) {
      // const res = await e.response.json();
      setLoading(false);
      console.warn("errors", e);
    }
  }

  async function unpublishEventFunc(event_id) {
    setLoading(true);
    try {
      const result = await unpublishEvent({ event_id });
      toggleUnpublishOpen("");
      setLoading(false);
      window.location = "/hosting";
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      console.warn("errors", res);
    }
  }

  async function publishEventEdit(event_id) {
    setLoading(true);
    try {
      const result = await publishEvent({ event_id });
      setLoading(false);
      window.location = "/hosting";
      return;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      setError(res.errors[0].details);
      console.warn("errors", res);
    }
  }

  function changeEventView(range) {
    let filterEvents = userData.eventHosted;
    if (range === "draft") {
      filterEvents = filterEvents.filter(
        (event) => event.published === false || !event.published
      );
    } else if (range === "upcoming") {
      filterEvents = filterEvents.filter((event) =>
        compareTimeUpcoming(event.start_date)
      );
      filterEvents = filterEvents.filter((event) => event.published === true);
    } else if (range === "past") {
      filterEvents = filterEvents.filter((event) =>
        compareTimePast(event.start_date)
      );
    } else {
      console.warn("ERROR: Sorting Events Error - Invalid Range");
    }
    setEventRange(range);
    return setEventsDisplay(filterEvents);
  }
  return (
    <div className={styles.hostEvents}>
      {loading && <Loading />}
      <div className={styles.hostEvents__wrapper}>
        <div className={styles.hostEvents__top}>
          <h1>Manage Gatherings</h1>
          <Button link="/create-event" label="Create New" />
        </div>
        {error && <ErrorBanner message={error} />}
        <div className={styles.hostEvents__events}>
          <UpcomingPast
            includeDraft={true}
            onclick={changeEventView}
            eventType={eventRange}
          />
          {eventsDisplay.length < 1 && (
            <section className={styles.hostEvents__noEvents}>
              <h2>No {eventRange} gatherings to display</h2>
            </section>
          )}
          {eventsDisplay.map((event, i) => {
            return (
              <section key={i} className={styles.hostEvents__event}>
                {cancelOpen === event._id && (
                  <CancelConfirm
                    cancelEvent={cancelEventFunc}
                    closeModal={toggleCancelOpen}
                    eventId={event._id}
                  />
                )}
                {waitlistOpen === event._id && (
                  <WaitlistModal
                    waitlist={event.waitlist}
                    closeModal={toggleWaitlistOpen}
                    eventId={event._id}
                    eventName={event.name}
                    hostImage={
                      userData.userImage
                        ? userData.userImage + "?w=120"
                        : "/placeholder/placeholder-profile-image.png"
                    }
                    setLoading={setLoading}
                  />
                )}
                {deleteOpen === event._id && (
                  <DeleteConfirm
                    deleteEvent={deleteEventFunc}
                    closeModal={toggleCancelOpen}
                    eventId={event._id}
                  />
                )}
                {unpublishOpen === event._id && (
                  <UnpublishConfirm
                    unpublishEvent={unpublishEventFunc}
                    closeModal={toggleUnpublishOpen}
                    eventId={event._id}
                  />
                )}

                {manageAttOpen === event._id && (
                  <ManageAttendeesModal
                    attendees={event.attendees}
                    closeModal={toggleManageAttOpen}
                    eventId={event._id}
                    eventName={event.name}
                    hostImage={
                      userData.userImage
                        ? userData.userImage + "?w=120"
                        : "/placeholder/placeholder-profile-image.png"
                    }
                    setLoading={setLoading}
                  />
                )}
                {emailAttOpen === event._id && (
                  <EmailAttendeesModal
                    closeModal={toggleEmailAttOpen}
                    attendees={event.attendees}
                    eventId={event._id}
                    eventName={event.name}
                    hostImage={
                      userData.userImage
                        ? userData.userImage + "?w=120"
                        : "/placeholder/placeholder-profile-image.png"
                    }
                  />
                )}
                <EventCard details={event} isRSVPd={true} />
                {!event.cancelled ? (
                  <div className={styles.hostEvents__event__edit}>
                    <p>
                      <a href={`/edit-event/${event._id}`}>
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.23292 12.9092L15.1421 1L19.3847 5.24264L7.47556 17.1518L3.12127 17.2635L3.23292 12.9092Z"
                            stroke="#284C4A"
                          />
                          <path
                            d="M12.667 4.18198L16.5561 8.07106"
                            stroke="#284C4A"
                          />
                        </svg>
                        Edit
                      </a>
                    </p>
                    {eventRange === "upcoming" && event.published && (
                      <p onClick={() => toggleManageAttOpen(event._id)}>
                        <svg
                          width="20"
                          height="21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="5" r="4" stroke="#284C4A" />
                          <path
                            d="M10 11a9 9 0 0 0-9 9h18a9 9 0 0 0-9-9z"
                            stroke="#284C4A"
                          />
                        </svg>
                        Manage Attendees
                      </p>
                    )}
                    {event.waitlist && event.waitlist.length > 0 && (
                      <p onClick={() => toggleWaitlistOpen(event._id)}>
                        <svg
                          width="20"
                          height="21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="5" r="4" stroke="#284C4A" />
                          <path
                            d="M10 11a9 9 0 0 0-9 9h18a9 9 0 0 0-9-9z"
                            stroke="#284C4A"
                          />
                        </svg>
                        View Waitlist
                      </p>
                    )}
                    {eventRange === "upcoming" && event.published && (
                      <p onClick={() => toggleEmailAttOpen(event._id)}>
                        <svg
                          width="20"
                          height="15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 14V1h18v13H1z" stroke="#284C4A" />
                          <path d="M1 1.5l9 5 9-5" stroke="#284C4A" />
                        </svg>
                        Email Attendees
                      </p>
                    )}
                    {event.published && (
                      <p onClick={() => toggleCancelOpen(event._id)}>
                        <svg
                          width="20"
                          height="23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.5 22V7h13v15h-13zM7.5 11v7M12.5 11v7"
                            stroke="#284C4A"
                          />
                          <path
                            d="M7.5 4V1h5v3M1 4.5h18"
                            stroke="#284C4A"
                            strokeLinecap="square"
                          />
                        </svg>
                        Cancel Event
                      </p>
                    )}
                    {!event.published && (
                      <p onClick={() => toggleDeleteOpen(event._id)}>
                        <svg
                          width="20"
                          height="23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.5 22V7h13v15h-13zM7.5 11v7M12.5 11v7"
                            stroke="#284C4A"
                          />
                          <path
                            d="M7.5 4V1h5v3M1 4.5h18"
                            stroke="#284C4A"
                            strokeLinecap="square"
                          />
                        </svg>
                        Delete Event
                      </p>
                    )}
                    {!event.published && (
                      <p onClick={() => publishEventEdit(event._id)}>
                        <svg
                          width="20"
                          height="21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="5" r="4" stroke="#284C4A" />
                          <path
                            d="M10 11a9 9 0 0 0-9 9h18a9 9 0 0 0-9-9z"
                            stroke="#284C4A"
                          />
                        </svg>
                        Publish Event
                      </p>
                    )}
                    {event.attendees &&
                      event.attendees.length <= 1 &&
                      event.published && (
                        <p onClick={() => toggleUnpublishOpen(event._id)}>
                          <svg
                            width="17"
                            height="16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              transform="rotate(45 -.707 1.707)"
                              stroke="#284C4A"
                              d="M0-.5h21.213"
                            />
                            <path
                              transform="rotate(-45 20.126 6.039)"
                              stroke="#284C4A"
                              d="M0-.5h21.213"
                            />
                          </svg>
                          Unpublish
                        </p>
                      )}
                    <div className={styles.hostEvents__event__tickets}>
                      <p>
                        {event.attendees && event.attendees.length} /{" "}
                        {event.attendees_limit} Tickets
                      </p>
                      <div className={styles.hostEvents__progress}>
                        {Array.from(
                          { length: event.attendees_limit },
                          () => ""
                        ).map((item, i) => (
                          <span
                            key={i}
                            className={`${styles.hostEvents__progress__item} ${
                              i < event.attendees.length
                                ? styles.hostEvents__progress__item__filled
                                : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.hostEvents__event__edit} />
                )}
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HostEvents;
