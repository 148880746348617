import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";
import { onboardingSocial } from "utils/validationSchema";

import styles from "components/Onboarding/Onboarding.module.scss";

import Checkbox from "components/FormElements/Checkbox";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";

function Social(props) {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={props.cmsData.social_title && props.cmsData.social_title}
          text={props.cmsData.social_subtitle && props.cmsData.social_subtitle}
          cmsImage={props.cmsData.social_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <div className={styles.profile__body__section}>
            <h3>
              {props.cmsData.social_input_title &&
                props.cmsData.social_input_title}
            </h3>
            <Formik
              initialValues={{
                twitter: props.user.twitter ? props.user.twitter : "",
                instagram: props.user.instagram ? props.user.instagram : "",
                linkedin: props.user.linkedin ? props.user.linkedin : "",
                website: props.user.website ? props.user.website : "",
                social_email: props.user.social_email
                  ? props.user.social_email
                  : "",
                show_email: props.user.show_email
                  ? props.user.show_email
                  : true,
              }}
              onSubmit={async (values, actions) => {
                const {
                  twitter,
                  instagram,
                  linkedin,
                  website,
                  social_email,
                  show_email,
                } = values;
                props.dispatch(
                  updateInfo({
                    ...props.user,
                    twitter,
                    instagram,
                    linkedin,
                    website,
                    social_email,
                    show_email,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              validationSchema={onboardingSocial}
              render={({ errors, status, touched, isSubmitting, values }) => (
                <Form>
                  <Input
                    label="Twitter"
                    name="twitter"
                    placeholder=""
                    type="text"
                    withAtSymbol={true}
                  />
                  <Input
                    label="Instagram"
                    name="instagram"
                    placeholder=""
                    type="text"
                    withAtSymbol={true}
                  />
                  <Input
                    label="LinkedIn"
                    name="linkedin"
                    placeholder="https://linkedin.com/in/username"
                    type="text"
                  />
                  <Input
                    label="Website"
                    name="website"
                    placeholder="http://"
                    type="text"
                  />
                  <Input
                    label="Email"
                    name="social_email"
                    placeholder="email address"
                    type="email"
                  />
                  <div
                    style={{
                      display: `${
                        values.social_email !== "" ? "block" : "none"
                      }`,
                    }}
                  >
                    <Checkbox
                      name="show_email"
                      label="Show my email on my profile (other members will see it)"
                    />
                  </div>
                  <FormButton
                    label="Next up: Hopes &amp; Dreams"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Social);
