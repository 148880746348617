import React, { memo } from "react";
// Styles
import styles from "components/OurStory/OurStory.module.scss";
// Utils
import parseSanityImage from "utils/parseSanityImage";

const OurStoryHeader = memo(function ({ headerTitle, backgroundImage }) {
  let headerImage =
    backgroundImage &&
    parseSanityImage(backgroundImage, true).width(1280).url();
  return (
    <div
      className={styles.story__header}
      style={{ backgroundImage: `url('${headerImage}')` }}
    >
      <div className={styles.story__header__border}>
        <div className={styles.story__header__greenSpace}></div>
      </div>
      <div className={styles.story__header__wrapper}>
        <h1>{headerTitle}</h1>
      </div>
    </div>
  );
});

export default OurStoryHeader;
