import React from "react";

import styles from "components/Members/Members.module.scss";

export default function MembersHeader() {
  return (
    <div className={styles.members__header}>
      <div className={styles.members__header__wrapper}>
        <h1>Fellow Loungers, future friends.</h1>
        <p className={styles.members__header__message}>
          We firmly believe that connection through community is an important
          part of taking care.
        </p>
        <div className="somescrollingthingthatsayspeoplewhoyouhavecommoninterestsorsomething">
          {/* */}
        </div>
      </div>
    </div>
  );
}
