import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";

import styles from "components/Onboarding/Onboarding.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";
import SelectBox from "components/FormElements/SelectBox";

function HopesDreams({
  dispatch,
  user = {},
  cmsData = {},
  updateValue,
  updateFn,
}) {
  const [interested, setInterested] = useState([]);
  const [forceUpdateValue, forceUpdate] = useState(1);

  useEffect(() => {
    if (user.membership_interests) {
      setInterested(user.membership_interests);
    }
  }, [user]);

  function interestedList(value) {
    let newInterests = interested;
    if (interested && interested.includes(value)) {
      let index = newInterests.indexOf(value);
      newInterests.splice(index, 1);
    } else {
      newInterests.push(value);
    }
    setInterested(newInterests);
    return forceUpdate(forceUpdateValue + 1);
  }

  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={cmsData.membership_title}
          text={cmsData.membership_subtitle}
          cmsImage={cmsData.membership_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <div className={styles.profile__body__section}>
            <h3>{cmsData.membership_aspects_title}</h3>
            <p>{cmsData.membership_aspects_text}</p>
            <div className={styles.profile__body__section__rowTwo}>
              <SelectBox
                value="Meeting new people IRL"
                onclick={interestedList}
                currentValue={
                  interested && interested.includes("Meeting new people IRL")
                    ? "Meeting new people IRL"
                    : ""
                }
              />
              <SelectBox
                value="Exclusive member content"
                onclick={interestedList}
                currentValue={
                  interested && interested.includes("Exclusive member content")
                    ? "Exclusive member content"
                    : ""
                }
              />
              <SelectBox
                value="Expanding my professional network"
                onclick={interestedList}
                currentValue={
                  interested &&
                  interested.includes("Expanding my professional network")
                    ? "Expanding my professional network"
                    : ""
                }
              />
              <SelectBox
                value="Finding mentors or learning from others"
                onclick={interestedList}
                currentValue={
                  interested &&
                  interested.includes("Finding mentors or learning from others")
                    ? "Finding mentors or learning from others"
                    : ""
                }
              />
              <SelectBox
                value="Mentoring others or teaching a new skill"
                onclick={interestedList}
                currentValue={
                  interested &&
                  interested.includes(
                    "Mentoring others or teaching a new skill"
                  )
                    ? "Mentoring others or teaching a new skill"
                    : ""
                }
              />
              <SelectBox
                value="Finding cozy events to attend"
                onclick={interestedList}
                currentValue={
                  interested &&
                  interested.includes("Finding cozy events to attend")
                    ? "Finding cozy events to attend"
                    : ""
                }
              />
              <SelectBox
                value="Swag/merch"
                onclick={interestedList}
                currentValue={
                  interested && interested.includes("Swag/merch")
                    ? "Swag/merch"
                    : ""
                }
              />
              <SelectBox
                value="Brand discounts and perks"
                onclick={interestedList}
                currentValue={
                  interested && interested.includes("Brand discounts and perks")
                    ? "Brand discounts and perks"
                    : ""
                }
              />
            </div>
          </div>
          <Formik
            initialValues={{
              membership_requests: user.membership_requests
                ? user.membership_requests
                : "",
            }}
            onSubmit={async (values, actions) => {
              const { membership_requests } = values;
              dispatch(
                updateInfo({
                  ...user,
                  membership_requests,
                  membership_interests: interested,
                })
              );
              return updateFn(updateValue);
            }}
            render={({ isSubmitting }) => (
              <Form>
                <div className={styles.profile__body__section}>
                  <h3>{cmsData.membership_suggestion_title}</h3>
                  <p>{cmsData.membership_suggestion_text}</p>
                  <Input
                    label=""
                    name="membership_requests"
                    placeholder="Ex. Chocolate subscription"
                    type="text"
                  />
                </div>
                <FormButton label="One More Step" disabled={isSubmitting} />
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(HopesDreams);
