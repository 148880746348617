const Industries = [
  "Advocacy, Policy, & Politics",
  "Agriculture",
  "Caretaking",
  "Construction",
  "Education",
  "Engineering",
  "Entertainment & Sports",
  "Events Services",
  "Financial Services",
  "Fine Arts & Performing Arts",
  "Hospitality, Food & Beverage",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Medicine & Health Care",
  "Human Resources, Staffing, & Recruiting",
  "Information & Technology Services",
  "Insurance",
  "International Affairs, Trade & Development",
  "Law Practice & Legal Services",
  "Leisure, Travel & Tourism",
  "Marketing & Advertising",
  "Military",
  "Non-profit & Philanthropy Management",
  "Journalism & Media",
  "Public Relations & Communications",
  "Real Estate",
  "Religious Institutions",
  "Retail & Sales",
  "Student",
  "Transportation",
  "Veterinary",
];

export default Industries;
