import React from "react";
import { withRouter } from "react-router";
//--- Queries and Actions
import { getAllUsers } from "api/user.js";
import { pageParams } from "utils/parseQueryParams";
//--- Components
import Admin from "components/Admin";
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";

export default withRouter(function AdminWrapper(props) {
  //--- Get Query Params
  const urlParams = new URLSearchParams(props.location.search);
  //--- Pagination
  const pageParam = urlParams.get("page") ? urlParams.get("page") : "1";
  const perPage = "100";
  const page = pageParams(pageParam, perPage);

  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          const events = await getAllUsers(page);
          return events;
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          return (
            <Admin
              data={props.data ? props.data : {}}
              currentPage={pageParam}
              perPage={perPage}
            />
          );
        }}
      </DataLoader>
    </PageWrapper>
  );
});
