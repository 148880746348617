import React from "react";
import { connect } from "react-redux";

import styles from "components/Dashboard/Dashboard.module.scss";

import CommunityGuidelines from "components/Dashboard/CommunityGuidelines";
import DashboardHeader from "components/Dashboard/DashboardHeader";
// import Interviews from "components/Dashboard/Interviews";
import Latest from "components/Dashboard/TheLatest";
import SiteEvents from "components/Dashboard/SiteEvents";
import MorningTea from "components/Dashboard/MorningTea";
import Picks from "components/Dashboard/Picks";
import Spotlight from "components/Dashboard/Spotlight";
import Newsletters from "components/Dashboard/Newsletters";

function Dashboard({ cmsData = {}, user, user_id, events }) {
  return (
    <div className={styles.dashboard}>
      <DashboardHeader name={user ? user.first_name : "Lounger"} />
      <SiteEvents
        events={events}
        user_id={user_id}
        eventRSVPS={cmsData.eventRSVPS}
      />
      <MorningTea
        title={cmsData.morningtea_title}
        text={cmsData.morningtea_text}
        items={cmsData.morningtea_items}
        buttonLink={cmsData.morningtea_button_link}
        buttonText={cmsData.morningtea_button_text}
      />
      <Picks
        title={cmsData.picks_title}
        text={cmsData.picks_text}
        items={cmsData.picksEvents}
        buttonLink={cmsData.picks_button_link}
        buttonText={cmsData.picks_button_text}
      />
      <Spotlight
        buttonLink={cmsData.meetgreet_button_link}
        buttonText={cmsData.meetgreet_button_text}
        text={cmsData.meetgreet_text}
        title={cmsData.meetgreet_title}
        user={cmsData.meetgreet_lounger}
        loungerText={cmsData.meetgreet_lounger_text}
        queryOne={{
          text: cmsData.meetgreet_groupOne_text,
          link: cmsData.meetgreet_groupOne_link,
          count: cmsData.meetgreet_groupOne_count,
        }}
        queryTwo={{
          text: cmsData.meetgreet_groupTwo_text,
          link: cmsData.meetgreet_groupTwo_link,
          count: cmsData.meetgreet_groupTwo_count,
        }}
      />
      <Latest
        title={cmsData.latest_title}
        text={cmsData.latest_text}
        items={cmsData.latestItems}
        buttonLink={cmsData.latest_button_link}
        buttonText={cmsData.latest_button_text}
      />
      <Newsletters
        title={cmsData.newsletters_title}
        text={cmsData.newsletters_text}
        items={cmsData.newsletters_items}
        buttonLink={cmsData.newsletters_button_link}
        buttonText={cmsData.newsletters_button_text}
      />
      <CommunityGuidelines
        title={cmsData.comm_title}
        linkText={cmsData.comm_link_text}
        link={cmsData.comm_link}
        cards={cmsData.comm_cards}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Dashboard);
