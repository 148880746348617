import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";
import { SignupNameSchema } from "utils/validationSchema";

import styles from "components/SignupFlow/SignupFlow.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";

function BasicInfo(props) {
  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={props.cmsData.basic_title && props.cmsData.basic_title}
          text={props.cmsData.basic_subtitle && props.cmsData.basic_subtitle}
        />
        <div className={styles.signup__form}>
          <div className={styles.signup__form__wrapper}>
            <Formik
              initialValues={{
                first_name: props.user.first_name ? props.user.first_name : "",
                last_name: props.user.last_name ? props.user.last_name : "",
              }}
              validationSchema={SignupNameSchema}
              onSubmit={async (values, actions) => {
                const { first_name, last_name } = values;

                props.dispatch(
                  updateInfo({ ...props.user, first_name, last_name })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <Input label="First Name" name="first_name" type="text" />
                  <Input label="Last Name" name="last_name" type="text" />
                  <FormButton label="Continue" disabled={isSubmitting} />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BasicInfo);
