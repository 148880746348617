import React from "react";
import PropTypes from "prop-types";

import styles from "components/Shared/ProfileCard/ProfileCard.module.scss";

function ProfileCard({ id, image, name = "", blurb, membership = "member" }) {
  return (
    <a
      href={`/user/${id}`}
      className={styles.profilecard}
      aria-label={`view ${name}'s profile`}
    >
      <div
        className={styles.profilecard__image}
        style={{
          backgroundImage: `url('${
            image
              ? image + "?w=150&fix=max"
              : "/placeholder/placeholder-profile-image.png"
          }')`,
        }}
      >
        {membership === "host" && (
          <svg
            width="56"
            height="52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.588 22.316L5.09 11.504l2.77-.128.2 4.34 4.043-.186-.2-4.34 2.77-.127.497 10.812-2.77.128-.206-4.488-4.044.186.207 4.487-2.77.128zM23.098 24.06c-.86-.296-1.565-.693-2.114-1.19-.55-.498-.951-1.058-1.203-1.677a5.064 5.064 0 0 1-.362-1.999c.01-.713.14-1.429.387-2.148a6.318 6.318 0 0 1 1.041-1.908 5.194 5.194 0 0 1 1.573-1.332 4.948 4.948 0 0 1 2.01-.578c.741-.059 1.518.052 2.332.333.86.296 1.566.693 2.122 1.193.554.5.961 1.06 1.223 1.683.26.623.389 1.292.383 2.006a6.75 6.75 0 0 1-.38 2.151 6.622 6.622 0 0 1-1.032 1.927c-.44.564-.96 1.01-1.558 1.337a4.844 4.844 0 0 1-2.022.566c-.75.05-1.55-.071-2.4-.364zm.8-2.233c.804.277 1.503.22 2.098-.172.595-.39 1.055-1.058 1.38-2.002.328-.953.379-1.77.153-2.448-.226-.68-.731-1.154-1.516-1.425-.767-.264-1.456-.198-2.068.198-.612.396-1.08 1.065-1.405 2.009-.32.925-.376 1.727-.17 2.404.206.677.715 1.156 1.529 1.436zM31.37 25.286l2.882-.026a4.792 4.792 0 0 0 .274 1.718c.19.52.558 1.008 1.104 1.464.387.323.74.487 1.06.49a.947.947 0 0 0 .774-.348c.253-.303.259-.66.015-1.07-.243-.41-.663-.967-1.26-1.672a10.448 10.448 0 0 1-.93-1.232 3.96 3.96 0 0 1-.52-1.16 2.335 2.335 0 0 1 0-1.13c.092-.381.3-.764.623-1.151a3.03 3.03 0 0 1 1.044-.81c.403-.19.837-.285 1.3-.284.464.001.955.103 1.475.305.52.203 1.038.52 1.553.95.758.635 1.34 1.341 1.747 2.119.406.777.608 1.668.607 2.672l-2.829-.084a3.775 3.775 0 0 0-.187-1.626c-.177-.496-.524-.96-1.04-1.391-.41-.342-.775-.514-1.098-.513-.323.001-.573.107-.75.32-.248.295-.27.627-.07.995.202.369.587.875 1.157 1.518.822.958 1.356 1.834 1.603 2.625.246.793.045 1.576-.601 2.349-.64.766-1.447 1.144-2.42 1.136-.973-.008-1.955-.427-2.949-1.258-.947-.792-1.607-1.595-1.977-2.407a6.31 6.31 0 0 1-.587-2.5zM46.05 30.027l2.004-1.242 4.826 7.79-2.004 1.241-1.686-2.722-7.197 4.458-1.46-2.357 7.197-4.458-1.68-2.71z"
              fill="#8C93A0"
            />
          </svg>
        )}
      </div>
      <h4>{name}</h4>
      <p>{blurb}</p>
    </a>
  );
}

ProfileCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  blurb: PropTypes.string,
  image: PropTypes.string,
};

export default ProfileCard;
