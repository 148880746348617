import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "components/FormElements/FormElements.module.scss";

function SelectBox({ currentValue, icon, onclick, text, value }) {
  return (
    <div
      className={cx(styles.selectBoxLarge, {
        [styles.selectBoxLarge__active]: currentValue === value,
      })}
      onClick={() => onclick(value)}
    >
      {icon && <img src={icon} alt={value} />}
      <h3>{value}</h3>
      {text && <p>{text}</p>}
    </div>
  );
}

SelectBox.propTypes = {
  onclick: PropTypes.func,
  value: PropTypes.string,
  currentValue: PropTypes.string,
};

export default SelectBox;
