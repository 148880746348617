import React from "react";
import { connect } from "react-redux";
//--- Queries
import { getHostUser } from "api/user.js";
//--- Components
import CreateGathering from "components/HostEvents/CreateGathering";
import DataLoader from "components/DataLoader";
import HostEvents from "components/HostEvents";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";
import WantToHost from "components/HostEvents/WantToHost";

function Hosting(props) {
  if (
    props.user &&
    props.user.user_roles &&
    !props.user.user_roles.includes("host")
  ) {
    return (
      <PageWrapper>
        <WantToHost />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper>
        <DataLoader
          cache={props.user.user_id}
          load={async () => {
            const hostQuery = await getHostUser(props.user.user_id);
            return hostQuery;
          }}
          fallback={() => {
            return <Loading />;
          }}
        >
          {(props) => {
            if (!props.data || props.data[0].eventHosted.length === 0) {
              return <CreateGathering />;
            }
            return <HostEvents userData={props.data ? props.data[0] : {}} />;
          }}
        </DataLoader>
      </PageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Hosting);
