import React from "react";

import styles from "components/Logos/Logo.module.scss";

function FullLoungeLogo({ center, fillColor }) {
  return (
    <div
      className={styles.loungeLogo__full}
      style={{ margin: center ? "0 auto 20px" : "" }}
    >
      <svg fill="none" viewBox="0 0 234 90" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M36.717 6.732L36.631 0h17.84l.044 6.646h-5.573v15.136h-6.61V6.646l-5.615.086z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M36.717 6.732L36.631 0h17.84l.044 6.646h-5.573v15.136h-6.61V6.646l-5.615.086z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M76.415 15.736c-.648 2.358-1.814 6.26-4.967 6.389-1.383.043-3.11-1.5-3.11-4.16l.086-4.158c.043-2.273-.605-3.388-1.771-3.302-1.555.086-2.549 1.5-2.808 3.602l-.216 7.675h-7.214V.086h7.214l.043 13.078c1.08-5.317 2.462-7.761 6.48-7.933 1.425-.043 2.937.472 3.93 1.544.778.857 1.297 1.63 1.383 3.602.043.643 0 1.329-.086 1.972l-.39 3.173c-.129.729 0 .943.346.943.39 0 .994-1.543 1.08-1.886 0-.086.173 0 .173.042 0 .215-.043.558-.173 1.115z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M76.415 15.736c-.648 2.358-1.814 6.26-4.967 6.389-1.383.043-3.11-1.5-3.11-4.16l.086-4.158c.043-2.273-.605-3.388-1.771-3.302-1.555.086-2.549 1.5-2.808 3.602l-.216 7.675h-7.214V.086h7.214l.043 13.078c1.08-5.317 2.462-7.761 6.48-7.933 1.425-.043 2.937.472 3.93 1.544.778.857 1.297 1.63 1.383 3.602.043.643 0 1.329-.086 1.972l-.39 3.173c-.129.729 0 .943.346.943.39 0 .994-1.543 1.08-1.886 0-.086.173 0 .173.042 0 .215-.043.558-.173 1.115z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M94.99 10.976c-.129 4.331-.604 9.048-6.695 10.848-3.801 1.115-8.207-.085-10.151-4.287-1.253-2.659-1.167-5.789.043-8.061 1.166-2.144 2.764-3.516 5.615-4.631 4.061-1.63 7.776-.257 9.072 2.144 1.21 2.315-.13 4.93-4.363 6.217-1.296.386-2.894.815-3.801.815.82 2.058 2.937 2.658 5.658 1.543 2.55-.986 3.845-1.843 4.493-4.588h.13zm-10.496 2.702c.648 0 1.857-.258 2.591-.472 1.253-.386 2.29-1.243 1.815-2.1-.735-1.33-1.815-1.544-2.938-1.201-1.166.343-1.814 1.672-1.641 3.001.043.386.086.514.173.772z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M94.99 10.976c-.129 4.331-.604 9.048-6.695 10.848-3.801 1.115-8.207-.085-10.151-4.287-1.253-2.659-1.167-5.789.043-8.061 1.166-2.144 2.764-3.516 5.615-4.631 4.061-1.63 7.776-.257 9.072 2.144 1.21 2.315-.13 4.93-4.363 6.217-1.296.386-2.894.815-3.801.815.82 2.058 2.937 2.658 5.658 1.543 2.55-.986 3.845-1.843 4.493-4.588h.13zm-10.496 2.702c.648 0 1.857-.258 2.591-.472 1.253-.386 2.29-1.243 1.815-2.1-.735-1.33-1.815-1.544-2.938-1.201-1.166.343-1.814 1.672-1.641 3.001.043.386.086.514.173.772z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M12.182 43.393V15.78H0v39.748h29.029V43.393H12.182z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M12.182 43.393V15.78H0v39.748h29.029V43.393H12.182z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M46.134 25.384c-8.294 0-15.032 6.689-15.032 14.964s6.738 14.964 15.032 14.964S61.21 48.623 61.21 40.348s-6.739-14.964-15.076-14.964zm.26 21.01c-2.679 0-4.839-2.573-4.839-5.746 0-3.173 2.16-5.745 4.838-5.745 2.679 0 4.838 2.572 4.838 5.745s-2.16 5.746-4.838 5.746z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M46.134 25.384c-8.294 0-15.032 6.689-15.032 14.964s6.738 14.964 15.032 14.964S61.21 48.623 61.21 40.348s-6.739-14.964-15.076-14.964zm.26 21.01c-2.679 0-4.839-2.573-4.839-5.746 0-3.173 2.16-5.745 4.838-5.745 2.679 0 4.838 2.572 4.838 5.745s-2.16 5.746-4.838 5.746z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M133.005 28.128c-1.901-1.844-4.363-3.173-8.813-2.573-5.572.686-7.386 7.461-8.553 13.55 0 0 .13-3.516-.043-6.69-.043-1.286-.691-3.472-1.469-4.544-1.209-1.63-2.937-2.744-6.307-2.359-3.369.343-5.313 3.645-6.09 6.303-1.599 5.489-3.197 10.677-3.197 10.677-.993 3.645-2.505 3.216-2.678 1.586-.043-.471-.087-.814-.087-1.243V25.427H83.673v12.949c0 .986-.086 1.972-.259 2.958-.518 2.744-1.642 4.631-3.931 4.631-2.376 0-3.801-1.672-3.888-5.274V25.427L63.5 25.384v17.022s0 7.675 2.808 10.334c1.9 1.843 4.363 3.173 8.812 2.572 5.572-.686 7.387-6.946 8.553-13.034 0 0-.086 3.516.087 6.603.302 4.673 3.974 6.731 7.127 6.517 3.629-.257 5.832-2.83 6.998-7.032.26-.943 3.024-9.99 3.024-9.99.993-3.645 2.289-3.13 2.505-1.501.044.472.087.815.087 1.243v17.409h12.095V42.535c0-.986.086-1.973.259-2.959.519-2.744 1.642-4.63 3.931-4.63 2.376 0 3.802 1.672 3.888 5.273v15.265l12.095.043V38.46s0-7.675-2.764-10.333z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M133.005 28.128c-1.901-1.844-4.363-3.173-8.813-2.573-5.572.686-7.386 7.461-8.553 13.55 0 0 .13-3.516-.043-6.69-.043-1.286-.691-3.472-1.469-4.544-1.209-1.63-2.937-2.744-6.307-2.359-3.369.343-5.313 3.645-6.09 6.303-1.599 5.489-3.197 10.677-3.197 10.677-.993 3.645-2.505 3.216-2.678 1.586-.043-.471-.087-.814-.087-1.243V25.427H83.673v12.949c0 .986-.086 1.972-.259 2.958-.518 2.744-1.642 4.631-3.931 4.631-2.376 0-3.801-1.672-3.888-5.274V25.427L63.5 25.384v17.022s0 7.675 2.808 10.334c1.9 1.843 4.363 3.173 8.812 2.572 5.572-.686 7.387-6.946 8.553-13.034 0 0-.086 3.516.087 6.603.302 4.673 3.974 6.731 7.127 6.517 3.629-.257 5.832-2.83 6.998-7.032.26-.943 3.024-9.99 3.024-9.99.993-3.645 2.289-3.13 2.505-1.501.044.472.087.815.087 1.243v17.409h12.095V42.535c0-.986.086-1.973.259-2.959.519-2.744 1.642-4.63 3.931-4.63 2.376 0 3.802 1.672 3.888 5.273v15.265l12.095.043V38.46s0-7.675-2.764-10.333z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M170.111 53.511V25.341H157.93v7.418c-1.253-4.717-4.104-7.89-9.633-7.332-6.48.6-10.497 6.903-10.497 15.221 0 8.361 3.196 14.836 10.626 14.836.605 0 1.167-.043 1.728-.172-.864.258-3.024.944-5.831 1.63-8.424 2.015-7.258 8.275-4.407 10.205 3.327 2.23 8.554 3.301 15.033 2.915 6.393-.386 12.7-4.245 14.601-12.52 0 0 .389-1.33.518-2.959.043-.3.043-.643.043-.943v-.129zm-20.432-12.306c.389-3.344 1.728-5.874 4.277-6.217 2.246-.3 3.758.986 3.974 3.86v1.371c0 .215-.043.43-.043.643-.346 3.302-1.556 5.703-4.104 5.703-2.203 0-4.579-1.072-4.104-5.36zm5.313 18.31c-2.764 1.328-8.423 1.328-7.343-1.33.216-.557.993-1.243 1.857-1.758 1.21-.729 4.277-2.444 5.227-3.602 1.426-1.5 2.506-3.516 3.154-5.874v6.56c0 1.158-.303 4.76-2.895 6.003z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M170.111 53.511V25.341H157.93v7.418c-1.253-4.717-4.104-7.89-9.633-7.332-6.48.6-10.497 6.903-10.497 15.221 0 8.361 3.196 14.836 10.626 14.836.605 0 1.167-.043 1.728-.172-.864.258-3.024.944-5.831 1.63-8.424 2.015-7.258 8.275-4.407 10.205 3.327 2.23 8.554 3.301 15.033 2.915 6.393-.386 12.7-4.245 14.601-12.52 0 0 .389-1.33.518-2.959.043-.3.043-.643.043-.943v-.129zm-20.432-12.306c.389-3.344 1.728-5.874 4.277-6.217 2.246-.3 3.758.986 3.974 3.86v1.371c0 .215-.043.43-.043.643-.346 3.302-1.556 5.703-4.104 5.703-2.203 0-4.579-1.072-4.104-5.36zm5.313 18.31c-2.764 1.328-8.423 1.328-7.343-1.33.216-.557.993-1.243 1.857-1.758 1.21-.729 4.277-2.444 5.227-3.602 1.426-1.5 2.506-3.516 3.154-5.874v6.56c0 1.158-.303 4.76-2.895 6.003z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M201.128 38.29c-1.253 3.601-3.888 5.917-7.474 7.332-3.283 1.286-8.898.386-9.676-2.788 3.586-.514 7.301-1.157 10.108-2.658 1.987-1.072 3.975-2.53 5.011-4.588 1.858-3.773-.302-8.146-3.931-9.776-3.499-1.586-7.948-1.372-11.447-.086-5.097 1.844-8.337 5.146-9.979 9.176-1.684 4.16-1.166 9.776.562 13.507 3.067 6.517 11.836 8.618 18.359 6.088 8.553-3.301 9.547-10.162 9.158-16.379v-.9l-.691 1.071zm-8.467-2.187c1.814 4.545-6.134 5.53-8.856 5.96-1.771-7.418 7.56-9.219 8.856-5.96z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M201.128 38.29c-1.253 3.601-3.888 5.917-7.474 7.332-3.283 1.286-8.898.386-9.676-2.788 3.586-.514 7.301-1.157 10.108-2.658 1.987-1.072 3.975-2.53 5.011-4.588 1.858-3.773-.302-8.146-3.931-9.776-3.499-1.586-7.948-1.372-11.447-.086-5.097 1.844-8.337 5.146-9.979 9.176-1.684 4.16-1.166 9.776.562 13.507 3.067 6.517 11.836 8.618 18.359 6.088 8.553-3.301 9.547-10.162 9.158-16.379v-.9l-.691 1.071zm-8.467-2.187c1.814 4.545-6.134 5.53-8.856 5.96-1.771-7.418 7.56-9.219 8.856-5.96z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M125.53 77.823c-.086-.043-.086-.128-.086-.214l.346-.6c.043-.086.129-.086.216-.043l2.289 1.286c.13.086.173.171.13.257-.864 1.5-2.549 1.972-4.104 1.115-1.469-.815-2.03-2.53-1.21-3.988.821-1.457 2.635-1.886 4.061-1.072 1.037.6 1.685 1.673 1.555 2.788 0 .171-.086.214-.259.128l-.907-.514c-.13-.086-.173-.13-.173-.258a1.592 1.592 0 00-.778-1.114c-.777-.43-1.771-.172-2.246.686-.475.857-.216 1.843.562 2.272.648.343 1.252.343 1.771-.043l-1.167-.686z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M125.53 77.823c-.086-.043-.086-.128-.086-.214l.346-.6c.043-.086.129-.086.216-.043l2.289 1.286c.13.086.173.171.13.257-.864 1.5-2.549 1.972-4.104 1.115-1.469-.815-2.03-2.53-1.21-3.988.821-1.457 2.635-1.886 4.061-1.072 1.037.6 1.685 1.673 1.555 2.788 0 .171-.086.214-.259.128l-.907-.514c-.13-.086-.173-.13-.173-.258a1.592 1.592 0 00-.778-1.114c-.777-.43-1.771-.172-2.246.686-.475.857-.216 1.843.562 2.272.648.343 1.252.343 1.771-.043l-1.167-.686z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M130.368 83.055c-.086-.043-.086-.129-.043-.215l2.722-4.63c.043-.086.129-.086.216-.043l.864.514c.086.043.086.129.043.215l-2.722 4.63c-.043.086-.129.086-.216.043l-.864-.514z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M130.368 83.055c-.086-.043-.086-.129-.043-.215l2.722-4.63c.043-.086.129-.086.216-.043l.864.514c.086.043.086.129.043.215l-2.722 4.63c-.043.086-.129.086-.216.043l-.864-.514z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M139.268 84.041c.345.129.777-.043.95-.472.173-.428 0-.772-.389-.9l-1.08-.429c-.086-.043-.129 0-.173.086l-.432 1.115c-.043.085 0 .171.087.171l1.037.429zm-.389 3.216c-.087-.043-.087-.086-.13-.172l-.259-2.015c0-.129-.043-.171-.13-.214l-.604-.258c-.087-.043-.173 0-.216.086l-.648 1.587c-.043.085-.13.128-.216.085l-.951-.386c-.086-.042-.129-.128-.086-.214l2.03-4.974c.043-.085.13-.128.216-.085l2.247.9c1.209.472 1.727 1.372 1.252 2.444-.259.686-.777 1.029-1.512 1.072-.172 0-.172.086-.172.214l.345 2.101c0 .129-.086.215-.173.172l-.993-.343z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M139.268 84.041c.345.129.777-.043.95-.472.173-.428 0-.772-.389-.9l-1.08-.429c-.086-.043-.129 0-.173.086l-.432 1.115c-.043.085 0 .171.087.171l1.037.429zm-.389 3.216c-.087-.043-.087-.086-.13-.172l-.259-2.015c0-.129-.043-.171-.13-.214l-.604-.258c-.087-.043-.173 0-.216.086l-.648 1.587c-.043.085-.13.128-.216.085l-.951-.386c-.086-.042-.129-.128-.086-.214l2.03-4.974c.043-.085.13-.128.216-.085l2.247.9c1.209.472 1.727 1.372 1.252 2.444-.259.686-.777 1.029-1.512 1.072-.172 0-.172.086-.172.214l.345 2.101c0 .129-.086.215-.173.172l-.993-.343z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M145.098 88.972c-.086 0-.129-.086-.129-.172l.864-5.274c0-.086.086-.129.172-.129l.994.172c.086 0 .13.086.13.171l-.692 4.16c0 .085.044.171.13.171l2.333.386c.086 0 .129.086.129.172l-.129.814c0 .086-.087.129-.173.129l-3.629-.6z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M145.098 88.972c-.086 0-.129-.086-.129-.172l.864-5.274c0-.086.086-.129.172-.129l.994.172c.086 0 .13.086.13.171l-.692 4.16c0 .085.044.171.13.171l2.333.386c.086 0 .129.086.129.172l-.129.814c0 .086-.087.129-.173.129l-3.629-.6z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M156.806 86.141c-.13 0-.173-.043-.216-.171-.173-.429-.562-.686-1.123-.686-.519 0-.994.171-.994.514 0 .386.389.558 1.296.644 1.382.171 2.16.6 2.16 1.672 0 1.2-1.08 1.843-2.462 1.843-1.21 0-2.203-.6-2.376-1.8 0-.13.043-.172.173-.172h.907c.129 0 .173.043.216.129.129.386.561.729 1.166.729.562 0 1.037-.172 1.037-.6 0-.515-.519-.601-1.512-.73-1.166-.171-1.944-.514-1.944-1.543 0-1.072.95-1.801 2.376-1.801 1.296 0 2.203.729 2.376 1.758.043.129 0 .171-.13.171l-.95.043z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M156.806 86.141c-.13 0-.173-.043-.216-.171-.173-.429-.562-.686-1.123-.686-.519 0-.994.171-.994.514 0 .386.389.558 1.296.644 1.382.171 2.16.6 2.16 1.672 0 1.2-1.08 1.843-2.462 1.843-1.21 0-2.203-.6-2.376-1.8 0-.13.043-.172.173-.172h.907c.129 0 .173.043.216.129.129.386.561.729 1.166.729.562 0 1.037-.172 1.037-.6 0-.515-.519-.601-1.512-.73-1.166-.171-1.944-.514-1.944-1.543 0-1.072.95-1.801 2.376-1.801 1.296 0 2.203.729 2.376 1.758.043.129 0 .171-.13.171l-.95.043z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M162.118 81.04c.648-.086 1.123.385 1.21.986.216 1.586-.778 2.315-1.512 2.487l-.087-.73c.432-.128.864-.6.821-1.157-.043.043-.129.086-.259.086-.518.085-.907-.215-.994-.73-.086-.428.26-.9.821-.943z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M162.118 81.04c.648-.086 1.123.385 1.21.986.216 1.586-.778 2.315-1.512 2.487l-.087-.73c.432-.128.864-.6.821-1.157-.043.043-.129.086-.259.086-.518.085-.907-.215-.994-.73-.086-.428.26-.9.821-.943z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M181.04 83.054c-.043.043-.13.043-.216 0l-3.974-1.93h-.044l1.685 3.088c.043.085 0 .171-.086.214l-.821.429c-.086.043-.173 0-.216-.086l-2.505-4.76c-.044-.085 0-.17.043-.214l.821-.428c.086-.043.129 0 .259.042l3.931 1.93c.043 0 .043 0 .043-.043l-1.685-3.044c-.043-.086 0-.172.087-.215l.82-.428c.087-.043.173 0 .216.085l2.506 4.76c.043.086 0 .171-.043.214l-.821.386z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M181.04 83.054c-.043.043-.13.043-.216 0l-3.974-1.93h-.044l1.685 3.088c.043.085 0 .171-.086.214l-.821.429c-.086.043-.173 0-.216-.086l-2.505-4.76c-.044-.085 0-.17.043-.214l.821-.428c.086-.043.129 0 .259.042l3.931 1.93c.043 0 .043 0 .043-.043l-1.685-3.044c-.043-.086 0-.172.087-.215l.82-.428c.087-.043.173 0 .216.085l2.506 4.76c.043.086 0 .171-.043.214l-.821.386z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M185.704 80.782c-.086.043-.172 0-.216-.085l-2.16-4.889c-.043-.085 0-.171.087-.214l.907-.386c.086-.043.173 0 .216.086l2.16 4.888c.043.086 0 .171-.087.214l-.907.386z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M185.704 80.782c-.086.043-.172 0-.216-.085l-2.16-4.889c-.043-.085 0-.171.087-.214l.907-.386c.086-.043.173 0 .216.086l2.16 4.888c.043.086 0 .171-.087.214l-.907.386z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M192.616 76.237c-.086 0-.173-.043-.173-.128l-.172-.686c0-.086.043-.172.129-.172l2.549-.6c.129-.043.259 0 .259.129.389 1.672-.518 3.172-2.203 3.558-1.642.386-3.197-.471-3.629-2.1-.388-1.63.605-3.174 2.203-3.56 1.167-.3 2.376.086 3.067.987.087.128.044.214-.086.257l-1.037.257c-.173.043-.216 0-.302-.043-.389-.3-.864-.386-1.339-.3-.864.215-1.383 1.072-1.167 2.058.26.944 1.124 1.501 1.988 1.287.691-.172 1.166-.644 1.252-1.244l-1.339.3z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M192.616 76.237c-.086 0-.173-.043-.173-.128l-.172-.686c0-.086.043-.172.129-.172l2.549-.6c.129-.043.259 0 .259.129.389 1.672-.518 3.172-2.203 3.558-1.642.386-3.197-.471-3.629-2.1-.388-1.63.605-3.174 2.203-3.56 1.167-.3 2.376.086 3.067.987.087.128.044.214-.086.257l-1.037.257c-.173.043-.216 0-.302-.043-.389-.3-.864-.386-1.339-.3-.864.215-1.383 1.072-1.167 2.058.26.944 1.124 1.501 1.988 1.287.691-.172 1.166-.644 1.252-1.244l-1.339.3z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M203.026 77.008c-.086 0-.173-.085-.173-.171l-.086-1.973c0-.085-.086-.171-.173-.171l-1.944.086c-.086 0-.129.085-.129.171l.086 1.972c0 .086-.086.172-.173.172l-.993.043c-.087 0-.173-.086-.173-.172l-.259-5.36c0-.085.043-.17.129-.17l.994-.044c.086 0 .173.086.173.172l.086 1.93c0 .085.086.17.173.17l1.944-.085c.086 0 .129-.086.129-.171l-.086-1.93c0-.086.043-.171.13-.171l.993-.043c.086 0 .173.085.173.171l.259 5.36c0 .086-.086.171-.173.171l-.907.043z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M203.026 77.008c-.086 0-.173-.085-.173-.171l-.086-1.973c0-.085-.086-.171-.173-.171l-1.944.086c-.086 0-.129.085-.129.171l.086 1.972c0 .086-.086.172-.173.172l-.993.043c-.087 0-.173-.086-.173-.172l-.259-5.36c0-.085.043-.17.129-.17l.994-.044c.086 0 .173.086.173.172l.086 1.93c0 .085.086.17.173.17l1.944-.085c.086 0 .129-.086.129-.171l-.086-1.93c0-.086.043-.171.13-.171l.993-.043c.086 0 .173.085.173.171l.259 5.36c0 .086-.086.171-.173.171l-.907.043z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M209.592 77.223c-.087 0-.13-.086-.13-.172l.389-4.202c0-.086-.043-.171-.13-.171l-1.512-.129c-.086 0-.172-.086-.129-.171l.086-.815c0-.086.087-.172.173-.129l4.622.429c.087 0 .173.086.13.171l-.087.815c0 .086-.086.129-.172.129l-1.469-.129c-.087 0-.173.043-.173.129l-.389 4.202c0 .086-.086.171-.173.128l-1.036-.085z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M209.592 77.223c-.087 0-.13-.086-.13-.172l.389-4.202c0-.086-.043-.171-.13-.171l-1.512-.129c-.086 0-.172-.086-.129-.171l.086-.815c0-.086.087-.172.173-.129l4.622.429c.087 0 .173.086.13.171l-.087.815c0 .086-.086.129-.172.129l-1.469-.129c-.087 0-.173.043-.173.129l-.389 4.202c0 .086-.086.171-.173.128l-1.036-.085z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M222.206 79.968c-.087-.043-.13-.13-.087-.215l1.988-4.974c.043-.085.129-.128.216-.085l.95.385c.086.043.129.13.086.215l-1.987 4.974c-.043.085-.129.128-.216.085l-.95-.385z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M222.206 79.968c-.087-.043-.13-.13-.087-.215l1.988-4.974c.043-.085.129-.128.216-.085l.95.385c.086.043.129.13.086.215l-1.987 4.974c-.043.085-.129.128-.216.085l-.95-.385z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M230.284 83.955c-.043-.043-.086-.086-.086-.171l-.475-4.374c0-.043-.043-.043-.043 0l-1.685 3.044c-.043.086-.13.086-.216.043l-.821-.471c-.086-.043-.086-.13-.043-.215l2.678-4.63c.044-.086.13-.086.216-.043l.778.428c.086.043.086.129.129.215l.519 4.33c0 .043 0 .043.043 0l1.685-3.044c.043-.086.129-.086.216-.043l.82.472c.087.043.087.128.044.214l-2.679 4.631c-.043.086-.129.086-.216.043l-.864-.429z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
          <path
            d="M230.284 83.955c-.043-.043-.086-.086-.086-.171l-.475-4.374c0-.043-.043-.043-.043 0l-1.685 3.044c-.043.086-.13.086-.216.043l-.821-.471c-.086-.043-.086-.13-.043-.215l2.678-4.63c.044-.086.13-.086.216-.043l.778.428c.086.043.086.129.129.215l.519 4.33c0 .043 0 .043.043 0l1.685-3.044c.043-.086.129-.086.216-.043l.82.472c.087.043.087.128.044.214l-2.679 4.631c-.043.086-.129.086-.216.043l-.864-.429z"
            fill={fillColor ? fillColor : "#284C4A"}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill={fillColor ? fillColor : "#284C4A"} d="M0 0h234v90H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default FullLoungeLogo;
