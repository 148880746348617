import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import cx from "classnames";
//--- Queries and Actions
import { updateEvent } from "api/event";
import { createEventAction } from "state/actions/createEventActions.js";
//--- Styles
import styles from "components/CreateEvent/CreateEvent.module.scss";
//--- Comoonents
import CharacterLimitTextBox from "components/FormElements/TextBox/CharacterLimitTextBox.js";
import FormButton from "components/Button/FormButton";
import Loading from "components/Loading";
import SideBanner from "components/Shared/SideBanner";

function AskAttendees(props) {
  const [suggested, setSuggested] = useState("");
  const [loading, setLoading] = useState(false);
  const cmsData = props.cmsData ? props.cmsData : {};

  useEffect(() => {
    props.event.discussionQuestion &&
      setSuggested(props.event.discussionQuestion);
  }, [props]);

  return (
    <div className={styles.createEvent}>
      {loading && <Loading />}
      <div className={styles.createEvent__wrapper}>
        <SideBanner
          title={cmsData ? cmsData.ask_attendees_side_title : "Ask Attendees"}
          text={
            cmsData
              ? cmsData.ask_attendees_side_text
              : "Attendees will see and be able to answer your question prompt after they RSVP, and they’ll also see others’ answers before the event. This can help attendees get to know one another before the gathering!"
          }
        />
        <div className={styles.createEvent__body}>
          <div className={styles.createEvent__body__wrapper}>
            <Formik
              initialValues={{
                discussionQuestion: props.event.discussionQuestion
                  ? props.event.discussionQuestion
                  : "",
              }}
              onSubmit={async (values, actions) => {
                const { discussionQuestion } = values;
                setLoading(true);
                const fields = {
                  event_id: props.event.eventId ? props.event.eventId : "",
                  address_1: props.event.address_1 ? props.event.address_1 : "",
                  address_2: props.event.address_2 ? props.event.address_2 : "",
                  amenities: props.event.amenities ? props.event.amenities : [],
                  attendees_limit: props.event.availableSeats
                    ? props.event.availableSeats
                    : 1,
                  city: props.event.city ? props.event.city : "",
                  // community: props.user.community ? props.user.community : 'Washington D.C.',
                  // description: props.event.description ? props.event.description : '',
                  guest_limit: props.event.guest_limit
                    ? props.event.guest_limit
                    : 0,
                  // name: props.event.eventName ? props.event.eventName : '',
                  neighborhood: props.event.neighborhood
                    ? props.event.neighborhood
                    : "",
                  notes: props.event.attendeeNote
                    ? props.event.attendeeNote
                    : "",
                  pets: props.event.pets ? props.event.pets : "",
                  question_1:
                    discussionQuestion !== "" ? discussionQuestion : "",
                  state: props.event.state ? props.event.state : "",
                  type: props.event.eventType ? props.event.eventType : "",
                  url: props.event.url ? props.event.url : "",
                  venue_name: props.event.venueName
                    ? props.event.venueName
                    : "",
                  venue_type: props.event.eventLocation
                    ? props.event.eventLocation
                    : "",
                  zip: props.event.zip ? props.event.zip : "",
                };

                try {
                  const result = await updateEvent(fields);
                  if (result) {
                    props.dispatch(
                      createEventAction({ ...props.event, discussionQuestion })
                    );
                    setLoading(false);
                  }
                } catch (e) {
                  const res = await e.response.json();
                  console.warn("ERROR:", res, res.title);
                  setLoading(false);
                  actions.setSubmitting(false);
                }
                return props.updateFn(props.updateValue);
              }}
              render={({ isSubmitting, setFieldValue }) => (
                <Form>
                  <section>
                    <CharacterLimitTextBox
                      initialValue={props.event.discussionQuestion}
                      placeholder="Do you have any book discussion questions for the group?"
                      name="discussionQuestion"
                      limit={225}
                    />
                    <div className={styles.createEvent__suggestions}>
                      <h3>Quick Suggestions</h3>
                      <div className={styles.createEvent__suggestions__wrapper}>
                        {props.suggestedQuestions &&
                          props.suggestedQuestions.map((question, i) => (
                            <div
                              key={i}
                              className={cx(
                                styles.createEvent__suggestions__item,
                                {
                                  [styles.createEvent__suggestions__item__active]:
                                    suggested === question,
                                }
                              )}
                              onClick={() => {
                                setFieldValue("discussionQuestion", question);
                                setSuggested(question);
                              }}
                            >
                              {question}
                            </div>
                          ))}
                      </div>
                    </div>
                  </section>
                  <FormButton label="Preview Event" disabled={isSubmitting} />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(AskAttendees);
