import React from "react";
import cx from "classnames";
// Styles
import styles from "components/OurStory/OurStory.module.scss";
// Components
import OurStoryGatherings from "components/OurStory/OurStoryGatherings";
import OurStoryHeader from "components/OurStory/OurStoryHeader";
import OurStoryLetter from "components/OurStory/OurStoryLetter";
import MembershipQuotes from "components/Membership/MembershipQuotes";
import ReadyToLoungeCTA from "components/Membership/ReadyToLoungeCTA";

function OurStory({ content }) {
  return (
    <div className={styles.story}>
      <OurStoryHeader
        headerTitle={content.header_title}
        backgroundImage={content.header_image}
      />
      <OurStoryLetter
        letterAttributionAffiliation={content.letter_attribution_affiliation}
        letterAttributionName={content.letter_attribution_name}
        letterAttributionTitle={content.letter_attribution_title}
        letterHeading={content.letter_heading}
        letterTitle={content.letter_title}
        rowOne={content.rowOne}
        rowTwo={content.rowTwo}
      />
      <OurStoryGatherings
        events={content.events}
        gatheringsText={content.gatherings_text}
        gatheringsTitle={content.gatherings_title}
      />
      <MembershipQuotes quotes={content.quotes} />
      <ReadyToLoungeCTA
        readyTitle={content.readyToLounge_title}
        readySubtitle={content.readyToLounge_subtitle}
      />
    </div>
  );
}

export default OurStory;
