import React from "react";
import { Redirect } from "react-router-dom";

import AskAttendees from "components/CreateEvent/AskAttendees";
import EventFinal from "components/CreateEvent/EventFinal";
import EventPreview from "components/CreateEvent/EventPreview";
import Loading from "components/Loading";
import IRLURL from "components/CreateEvent/IRLURL";
import TheBasics from "components/CreateEvent/TheBasics";
import TheDetails from "components/CreateEvent/TheDetails";
import TheTopic from "components/CreateEvent/TheTopic";
import TheVenue from "components/CreateEvent/TheVenue";
import TheURL from "../../components/CreateEvent/TheURL";

function ComponentHandler({
  cmsData,
  createEventStep,
  progressFlow,
  eventId,
  suggestedQuestions,
}) {
  switch (createEventStep) {
    case "irl-url":
      return (
        <IRLURL
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="event-topic"
        />
      );
    case "event-topic":
      return (
        <TheTopic
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="the-basics"
        />
      );
    case "the-basics":
      return (
        <TheBasics
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="the-venue"
        />
      );
    case "the-url":
      return (
        <TheURL
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="venue-info"
        />
      );
    case "the-venue":
      return (
        <TheVenue
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="venue-info"
        />
      );
    case "venue-info":
      return (
        <TheDetails
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="ask-attendees"
        />
      );
    case "ask-attendees":
      return (
        <AskAttendees
          cmsData={cmsData && cmsData[0] ? cmsData[0] : false}
          updateFn={progressFlow}
          updateValue="event-preview"
          suggestedQuestions={suggestedQuestions}
        />
      );
    case "event-preview":
      return <EventPreview updateFn={progressFlow} updateValue="event-final" />;
    case "event-final":
      return (
        <EventFinal
          updateFn={progressFlow}
          updateValue="process-event"
          eventId={eventId}
        />
      );
    case "process-event":
      return <Redirect to="/dashboard" />;
    default:
      return <Loading active={true} />;
  }
}

export default ComponentHandler;
