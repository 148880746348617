import React from "react";
import BlockContent from "@sanity/block-content-to-react";
//--- Styles
import styles from "components/Announcements/Announcements.module.scss";
//--- Components
import AnnouncementHeader from "components/Announcements/AnnouncementHeader";

export default function AnnouncementArticle({ article }) {
  return (
    <div className={styles.announcement}>
      <AnnouncementHeader
        title={article.announcement_title}
        createdAt={article._createdAt}
        image={article.announceImage}
        updatedAt={article._updatedAt}
      />
      <div className={styles.announcement__body}>
        <BlockContent blocks={article.announcement_text} />
      </div>
    </div>
  );
}
