import React from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

import Button from "components/Button/index.js";

function Spotlight({
  buttonText = "",
  buttonLink = "",
  loungerText = "",
  text = "",
  title = "",
  user = {},
  queryOne = {},
  queryTwo = {},
}) {
  return (
    <div className={styles.dashboard__spotlight}>
      <div className={styles.dashboard__spotlight__wrapper}>
        <div className={styles.dashboard__spotlight__header}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>

        <div className={styles.dashboard__spotlight__feature}>
          <div className={styles.dashboard__spotlight__feature__profile}>
            <div
              className={styles.dashboard__spotlight__feature__profile__image}
            >
              <img src={`${user.hostImage}?w=500`} alt={user.name} />
            </div>
            <div
              className={styles.dashboard__spotlight__feature__profile__info}
            >
              <div>
                <h3>Spotlight</h3>
                <h4>{user.name}</h4>
                <p>{loungerText}</p>
              </div>
              <div>
                <p>
                  <a href={`/user/${user._id}`}>View Profile</a>
                </p>
              </div>
              <div
                className={
                  styles.dashboard__spotlight__feature__profile__info__icon
                }
              >
                <img src="/icons/candle-colored.png" alt="candle" />
              </div>
            </div>
          </div>
          <div className={styles.dashboard__spotlight__feature__getToKnow}>
            <img src="/icons/Say-Hey.png" alt="word bubble with Say hey" />
            <a
              className={styles.dashboard__spotlight__feature__getToKnow__item}
              href={queryOne.link}
            >
              <span>{queryOne.text}</span>
              <span>{queryOne.count} Loungers</span>
            </a>
            <a
              className={styles.dashboard__spotlight__feature__getToKnow__item}
              href={queryTwo.link}
            >
              <span>{queryTwo.text}</span>
              <span>{queryTwo.count} Loungers</span>
            </a>
          </div>
        </div>
        <div className={styles.dashboard__spotlight__button}>
          <Button
            label={buttonText}
            link={buttonLink}
            additionalClass="button__white"
          />
        </div>
      </div>
    </div>
  );
}

export default Spotlight;
