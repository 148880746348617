import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";

import styles from "components/FormElements/FormElements.module.scss";

function Selector({ error, label, name, placeholder, values }) {
  return (
    <div className={cx(styles.input, { [styles.input__error]: error })}>
      {label !== "" && <label htmlFor={name}>{label}</label>}
      <div className={styles.input__select}>
        <svg
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L7.5 7L14 0.999999" stroke="#284C4A" strokeWidth="2" />
        </svg>
        <Field name={name} component="select" aria-label={name}>
          <option value="" label={placeholder} disabled>
            {placeholder}
          </option>
          {values.map((item, i) => (
            <option key={i} value={item} label={item}>
              {item}
            </option>
          ))}
        </Field>
      </div>
    </div>
  );
}

Selector.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.array,
  placeholder: PropTypes.string,
};

export default Selector;
