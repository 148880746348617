import React, { useEffect, useState } from "react";
//--- Utils
import parseDate from "utils/parseDate";
//--- Styles
import styles from "components/Article/Articles.module.scss";

export default function AnnouncementCard({
  _id,
  title = "",
  image,
  previewText = "",
  createdAt,
  type,
}) {
  const [textPreview, setTextPreview] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    let cutDesc = truncateDesc(previewText);
    let morphedDate = dateMorph(createdAt);
    setTextPreview(cutDesc);
    setDate(morphedDate);
  }, [createdAt, previewText]);

  function dateMorph(date) {
    let formattedDate = parseDate(date);
    return `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year} `;
  }

  function truncateDesc(val) {
    const characterLimit = 175;
    if (val.length > characterLimit) {
      return val.substring(0, characterLimit) + "...";
    }
    return val;
  }

  return (
    <div className={styles.articleCard}>
      <p className={styles.articleCard__type}>{type}</p>
      <h2>{title}</h2>
      <p className={styles.articleCard__date}>{date}</p>
      {image && <img src={image + "?w=570&fix=max"} alt={title} />}
      <p>{textPreview}</p>
      <p className={styles.articleCard__readMore}>
        <a href={`/post/${_id}`}>Read more...</a>
      </p>
    </div>
  );
}
