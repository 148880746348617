import cookie from "js-cookie";
import sanitizeUserId from "utils/sanitizeUserId";

const user = (() => {
  const raw = cookie.getJSON("gni_user") || {};
  const user_id = raw.profile ? sanitizeUserId(raw.user_id) : null;
  return { user_id, ...raw };
})();

export default {
  user,
};
