import React, { useEffect, useState } from "react";
//--- Utils
import parseDate from "utils/parseDate";
//--- Styles
import styles from "components/Announcements/Announcements.module.scss";

export default function AnnouncementHeader({ title = "", updatedAt, image }) {
  const [date, setDate] = useState("");

  useEffect(() => {
    let morphedDate = dateMorph(updatedAt);
    setDate(morphedDate);
  }, [updatedAt]);

  function dateMorph(date) {
    let formattedDate = parseDate(date);
    return `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year} `;
  }

  return (
    <div className={styles.announcement__header}>
      <h1>Announcements</h1>
      <h2>{title}</h2>
      <p>{date}</p>
      {image && <img src={image + "?w=570&fix=max"} alt={title} />}
    </div>
  );
}
