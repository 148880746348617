import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { updateInfo } from "state/actions/userActions.js";

import styles from "components/Onboarding/Onboarding.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import SideBanner from "components/Shared/SideBanner";

function Interests(props) {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__wrapper}>
        <SideBanner
          title={props.cmsData.downtime_title && props.cmsData.downtime_title}
          text={
            props.cmsData.downtime_subtitle && props.cmsData.downtime_subtitle
          }
          cmsImage={props.cmsData.downtime_image}
          cmsImageWidth={500}
        />
        <div className={styles.profile__body}>
          <div className={styles.profile__body__section}>
            <h3>
              {props.cmsData.downtime_input_title &&
                props.cmsData.downtime_input_title}
            </h3>
            <Formik
              initialValues={{
                currently_reading: props.user.currently_reading
                  ? props.user.currently_reading
                  : "",
                currently_watching: props.user.currently_watching
                  ? props.user.currently_watching
                  : "",
                currently_listening: props.user.currently_listening
                  ? props.user.currently_listening
                  : "",
                currently_instagram: props.user.currently_instagram
                  ? props.user.currently_instagram
                  : "",
                currently_irl: props.user.currently_irl
                  ? props.user.currently_irl
                  : "",
                currently_eating: props.user.currently_eating
                  ? props.user.currently_eating
                  : "",
              }}
              onSubmit={async (values, actions) => {
                const {
                  currently_reading,
                  currently_watching,
                  currently_listening,
                  currently_instagram,
                  currently_irl,
                  currently_eating,
                } = values;
                props.dispatch(
                  updateInfo({
                    ...props.user,
                    currently_reading,
                    currently_watching,
                    currently_listening,
                    currently_instagram,
                    currently_irl,
                    currently_eating,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <Input
                    label="Reading?"
                    name="currently_reading"
                    placeholder="Re-reading Harry Potter"
                    type="text"
                  />
                  <Input
                    label="Watching?"
                    name="currently_watching"
                    placeholder="Big Little Lies"
                    type="text"
                  />
                  <Input
                    label="Listening to (i.e. podcasts, audiobooks, music)?"
                    name="currently_listening"
                    placeholder="Still Processing"
                    type="text"
                  />
                  <Input
                    label="Loving following on Instagram?"
                    name="currently_instagram"
                    placeholder="@mindykaling is amazing"
                    type="text"
                  />
                  <Input
                    label="Frequenting IRL (cafes, restaurants, bookstores)?"
                    name="currently_irl"
                    placeholder="Lucky Buns in AdMo!!!"
                    type="text"
                  />
                  <Input
                    label="Cooking or Eating"
                    name="currently_eating"
                    placeholder="A great new sandwich spot or a recipe obessesion"
                    type="text"
                  />
                  <FormButton label="Next up: Social" disabled={isSubmitting} />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Interests);
