import React from "react";
//--- Queries
import { getPerksData } from "api/cms.js";
//---Components
import DataLoader from "components/DataLoader.js";
import Loading from "components/Loading";
import PageWrapper from "components/PageWrapper";
import Perks from "components/Perks";

export default function PerksWrapper(props) {
  return (
    <PageWrapper>
      <DataLoader
        load={async () => {
          try {
            const perks = await getPerksData();
            return perks;
          } catch (e) {
            const res = await e.response.json();
            console.warn("errors", res); // { errors; [ ... ] }
          }
        }}
        fallback={() => {
          return <Loading />;
        }}
      >
        {(props) => {
          return <Perks pageData={props.data} />;
        }}
      </DataLoader>
    </PageWrapper>
  );
}
