import React, { useState } from "react";
import { Formik, Form } from "formik";

import { UpdateAccountEmailSchema } from "utils/validationSchema";
import { updateUserEmail } from "api/user.js";

import styles from "components/EditAccount/EditAccount.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

function EditEmail(props) {
  const [buttonText, setButtonText] = useState("Update Email");
  const [hasUpdated, setHasUpdated] = useState(false);

  return (
    <Formik
      initialValues={{
        email: props.user.email || "",
        current_password: "",
      }}
      validationSchema={UpdateAccountEmailSchema}
      onSubmit={(values, actions) => {
        const { setFieldError, setSubmitting } = actions;
        const { email: new_email, current_password } = values;
        const currentEmail = props.user.email;

        if (currentEmail === new_email) return;

        setButtonText("Updating...");

        updateUserEmail(currentEmail, current_password, new_email)
          .then(async function onSuccess() {
            setButtonText("Updated!");
            setSubmitting(false);
            props.updateUserInfo({ email: new_email });
            setHasUpdated(true);
          })
          .catch(async function onError(err) {
            setButtonText("Update Email");
            setSubmitting(false);
            console.warn(
              `%c EMAIL err`,
              "color: #221; background: #F40;padding: 2px 8px; font-weight: bold",
              err
            );

            try {
              const { errors } = await err.response.json();
              const message = errors[0].details;

              if (message === "Missing required parameter: password") {
                setFieldError(
                  "current_password",
                  "Required when updating email"
                );
              } else if (message === "Wrong email or password.") {
                setFieldError("current_password", "Incorrect password");
              } else {
                setFieldError("email", message);
                setFieldError("current_password", message);
              }
            } catch (err2) {
              setTimeout(() => setFieldError("email", "Could not change"));
            }
          });
      }}
      render={({ errors, isSubmitting, dirty, values }) => {
        const disabled =
          isSubmitting || !dirty || props.user.email === values.email;

        return (
          <Form className={styles.form}>
            <section>
              <h3>Change your email</h3>
              <Input
                label="Email"
                name="email"
                placeholder="Email Address"
                type="text"
              />
              <Input
                label="Current Password"
                name="current_password"
                placeholder="*********"
                type="password"
              />
            </section>

            <FormButton
              label={
                disabled || !hasUpdated ? buttonText : "Update Email Again"
              }
              disabled={disabled}
            />
          </Form>
        );
      }}
    />
  );
}

export default EditEmail;
