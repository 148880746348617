import React, { useEffect, useState } from "react";
import cx from "classnames";
//--- Queries
import { hostCancelRSVP } from "api/event";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import Button from "components/Button";

export default function ManageAttendeesModal({
  attendees,
  closeModal,
  eventId,
  eventName = "",
  hostImage,
  setLoading,
}) {
  async function cancelRSVPFunc(user_id) {
    setLoading(true);
    try {
      const result = await hostCancelRSVP({ event_id: eventId, user_id });
      window.location = `/event/${eventId}`;
    } catch (e) {
      const res = await e.response.json();
      setLoading(false);
      console.warn("errors", res);
    }
  }

  return (
    <div className={cx(styles.event__modal, styles.event__modal__active)}>
      <div className={styles.event__modal__body__wrapper}>
        <div className={styles.event__modal__body}>
          <div
            className={styles.event__modal__close}
            onClick={() => closeModal("")}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>{eventName}</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <div className={styles.event__modal__left}>
              <div className={styles.event__modal__left__wrapper}>
                <div
                  className={styles.event__modal__left__image}
                  style={{ backgroundImage: `url('${hostImage}')` }}
                ></div>
                <h2>Manage Attendees</h2>
                <p>Manage your attendees</p>
              </div>
            </div>
            <div className={styles.event__modal__right}>
              <div className={styles.event__modal__right__button}>
                <p>{attendees.length} Going</p>
              </div>
              <div className={styles.manageAtt}>
                {attendees.map((att, i) => (
                  <div key={i} className={styles.manageAtt__item}>
                    <div
                      className={styles.manageAtt__item__image}
                      style={{
                        backgroundImage: `url('${
                          att.attendee.attendeeImage
                            ? att.attendee.attendeeImage + "?w=100&fit=max"
                            : "/placeholder/placeholder-profile-image.png"
                        }')`,
                      }}
                    />
                    <div className={styles.manageAtt__item__name}>
                      <h4>
                        {att.attendee.first_name} {att.attendee.last_name}
                      </h4>
                      <p>Member</p>
                    </div>
                    <div
                      className={styles.manageAtt__item__remove}
                      onClick={() => cancelRSVPFunc(att.attendee._id)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
