import React from "react";

import styles from "components/Dashboard/Dashboard.module.scss";

function DashboardHeader({ name = "Lounger" }) {
  return (
    <div className={styles.dashboard__header}>
      <div className={styles.dashboard__header__wrapper}>
        <h1>Hey, {name}.</h1>
        <div className={styles.dashboard__header__illo}>
          <img src="/icons/Chaise-colored.png" alt="A chair" />
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
