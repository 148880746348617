import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'

function AdminOnlyRoute ({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => {
      if(!rest.user.user_roles || !rest.user.user_roles.includes('admin')) {
        return (
          <Redirect to={{
            pathname: '/dashboard',
            state: { from: props.location }
          }} />
        );
      } else {
        return (
          <Component {...props} />
        );
      }
    }} />
  );
}

export default connect(state => ({ user: state.user }))(AdminOnlyRoute)
