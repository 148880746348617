export function initLoading () {
  return {
    type: 'INIT_LOADING'
  }
}

export function stopLoading () {
  return {
    type: 'STOP_LOADING'
  }
}
