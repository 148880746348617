import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";

import styles from "../FormElements.module.scss";

function Checkbox({ name = "", label, labelHtml }) {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <>
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                checked={field.value}
                name={name}
                aria-label={name}
                onChange={() => {
                  form.setFieldValue(name, !field.value);
                }}
              />
              {label && <label htmlFor={name}>{label}</label>}
              {labelHtml && (
                <label
                  htmlFor={name}
                  dangerouslySetInnerHTML={{ __html: labelHtml }}
                ></label>
              )}
            </div>
            {error && (
              <p
                className={cx(
                  styles.input__error__message,
                  styles.checkbox__error
                )}
              >
                {form.errors[field.name]}
              </p>
            )}
          </>
        );
      }}
    </Field>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default Checkbox;
