import React, { useMemo } from "react";
import cx from "classnames";
//--- Utils
import parseDate from "utils/parseDate";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import Button from "components/Button";
import GoogleCalButton from "components/Button/GoogleCalButton";
import Note from "components/Shared/Note";

function CongratsModal({
  modalOpen,
  closeModal,
  eventData,
  startdate,
  startdateGMT,
  enddate,
  enddateGMT,
  hostNote,
  isWaitlist,
}) {
  function congratsClose() {
    window.location = `/event/${eventData._id}`;
  }

  const { host = {} } = useMemo(() => {
    const host = eventData.host || {};
    return {
      host,
    };
  }, [eventData]);

  const isVirtual = eventData.venue_type === "Virtual" ? true : false;
  return (
    <div
      className={cx(styles.event__modal, {
        [styles.event__modal__active]: modalOpen,
      })}
    >
      <div className={styles.event__modal__body__wrapper}>
        <div className={styles.event__modal__body}>
          <div
            className={styles.event__modal__close}
            onClick={() => congratsClose()}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles.event__modal__top}>
            <p>{eventData.name}</p>
          </div>
          <div className={styles.event__modal__wrapper}>
            <div
              className={cx(
                styles.event__modal__left,
                styles.event__congratsModal__left
              )}
            >
              <div className={styles.event__modal__left__wrapper}>
                <div
                  className={styles.event__modal__left__image}
                  style={{
                    backgroundImage: `url('${
                      host.hostImage
                        ? host.hostImage + "?w=150&h=150&fit=max"
                        : "/placeholder/placeholder-profile-image.png"
                    }')`,
                  }}
                ></div>
                {!isWaitlist && <h2>Woo! You're Going!</h2>}
                {isWaitlist && <h2>You're on the Waitlist</h2>}
                {!isWaitlist && hostNote && (
                  <p className={styles.event__modal__left__noteTitle}>
                    Note from the host {host ? host.first_name : ""}
                  </p>
                )}
                {!isWaitlist && hostNote && <Note text={hostNote} />}
                {isWaitlist && (
                  <p className={styles.event__modal__left__noteTitle}>
                    We will let you know if a spot opens up!
                  </p>
                )}
              </div>
            </div>
            <div
              className={cx(
                styles.event__modal__right,
                styles.event__congratsModal__right
              )}
            >
              <p>
                {startdate.dayOfWeek}, {startdate.month} {startdate.day}
                <br />
                {`${startdate.hour}:${startdate.minutes}${startdate.ampm}`} -{" "}
                {`${enddate.hour}:${enddate.minutes}${enddate.ampm}`}
              </p>
              {!isWaitlist && (
                <GoogleCalButton
                  name={eventData.name}
                  address_1={eventData.address_1}
                  address_2={eventData.address_2}
                  city={eventData.city}
                  state={eventData.state}
                  zip={eventData.zip}
                  eventId={eventData._id}
                  startdateGMT={startdateGMT}
                  enddateGMT={enddateGMT}
                />
              )}
              {!isWaitlist && !isVirtual && (
                <p>
                  {eventData.address_1} {eventData.address_2} <br />
                  {eventData.city}, {eventData.state} {eventData.zip}
                </p>
              )}
              {!isWaitlist && !isVirtual && (
                <Button
                  link={`https://maps.google.com/?q=${eventData.address_1} ${eventData.address_2} ${eventData.city} ${eventData.state} ${eventData.zip}`}
                  linkTarget="_blank"
                  label="View in Google Maps"
                  additionalClass="button__transparent"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CongratsModal;
