import React, { useMemo } from "react";
import { connect } from "react-redux";
// Styles
import styles from "components/UserProfile/UserProfile.module.scss";
// Components
import EventsAttended from "components/UserProfile/EventsAttended";
import EventsHosted from "components/UserProfile/EventsHosted";
import EmailIcon from "components/Shared/Icons/EmailIcon";
import InstagramIcon from "components/Shared/Icons/InstagramIcon";
import LinkedinIcon from "components/Shared/Icons/LinkedinIcon";
import TwitterIcon from "components/Shared/Icons/TwitterIcon";
import WebsiteIcon from "components/Shared/Icons/WebsiteIcon";

function UserProfile({ userData, user = {} }) {
  const { profileData, noRSVPs } = useMemo(() => {
    const profileData = userData && userData.data[0] ? userData.data[0] : {};
    const noRSVPs =
      (profileData.eventHosted && profileData.eventHosted.length > 0) ||
      (profileData.eventRSVPS && profileData.eventRSVPS.length > 0)
        ? true
        : false;
    return {
      profileData,
      noRSVPs,
    };
  }, [userData]);

  return (
    <div className={styles.profile}>
      {user.user_id === profileData._id && (
        <div className={styles.profile__edit}>
          <p>Hey, it's you!</p>
          <p>
            <a href="/edit-account">Edit your profile</a>
          </p>
        </div>
      )}
      <div className={styles.profile__wrapper}>
        <div className={styles.profile__top}>
          <div className={styles.profile__top__image}>
            <div
              className={styles.profile__top__image__image}
              style={{
                backgroundImage: `url('${
                  profileData.userImage
                    ? profileData.userImage + "?w=400&h=400"
                    : "/placeholder/placeholder-profile-image.png"
                }')`,
              }}
            >
              {profileData.user_roles &&
                profileData.user_roles.includes("host") && (
                  <svg
                    width="82"
                    height="69"
                    viewBox="0 0 82 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.0149 65.4698L10.4817 57.373L12.5558 53.6485L18.3898 56.8986L21.4178 51.4639L15.5837 48.2138L17.6581 44.4903L32.1916 52.5882L30.1186 56.3124L24.0862 52.9515L21.0571 58.3865L27.0896 61.7474L25.0149 65.4698Z"
                      fill="#8C93A0"
                    />
                    <path
                      d="M40.5416 42.1835C39.6368 43.2493 38.6752 44.0367 37.656 44.5481C36.6363 45.0573 35.6119 45.32 34.5836 45.3348C33.5553 45.3496 32.5333 45.1504 31.518 44.7382C30.5041 44.3268 29.55 43.7424 28.6569 42.9849C27.7761 42.2382 27.0594 41.3861 26.506 40.4302C25.9525 39.4742 25.6093 38.4749 25.4757 37.4348C25.3421 36.3947 25.4394 35.3269 25.7704 34.2331C26.1 33.1384 26.6937 32.0875 27.5477 31.0788C28.4514 30.0132 29.4158 29.2227 30.4406 28.7063C31.4647 28.1912 32.4964 27.9207 33.5348 27.8949C34.5728 27.8681 35.6018 28.0583 36.6217 28.4658C37.6417 28.8732 38.5982 29.4533 39.4899 30.2101C40.3817 30.9668 41.1097 31.817 41.6735 32.7631C42.2369 33.7081 42.5856 34.7012 42.7192 35.7414C42.8528 36.7815 42.747 37.8527 42.4012 38.9529C42.0548 40.0509 41.4352 41.1288 40.5416 42.1835ZM37.79 39.7909C38.6342 38.7943 38.9425 37.7608 38.7147 36.6905C38.4869 35.6202 37.7873 34.5901 36.6163 33.5967C35.4346 32.5944 34.2938 32.066 33.1949 32.0113C32.0961 31.9565 31.1346 32.4147 30.3091 33.3886C29.5044 34.3392 29.2137 35.3632 29.438 36.4604C29.6624 37.5576 30.3611 38.602 31.5307 39.5946C32.6785 40.5671 33.7944 41.0951 34.88 41.1794C35.9649 41.2615 36.9345 40.7987 37.79 39.7909Z"
                      fill="#8C93A0"
                    />
                    <path
                      d="M46.6928 30.6655L47.5018 26.3102C48.4424 26.5053 49.333 26.5356 50.1736 26.4013C51.0144 26.268 51.8586 25.8566 52.7083 25.1664C53.3101 24.6784 53.6602 24.1929 53.7591 23.7122C53.8577 23.2304 53.7582 22.8064 53.4621 22.441C53.0785 21.9692 52.5423 21.8569 51.8524 22.1043C51.1625 22.3517 50.1979 22.8201 48.9596 23.5129C48.2122 23.9421 47.5007 24.2896 46.826 24.5541C46.1513 24.8187 45.5172 24.9662 44.9248 24.9963C44.3335 25.0262 43.7636 24.9158 43.2173 24.6646C42.671 24.4134 42.1533 23.987 41.6652 23.3852C41.2246 22.8414 40.9201 22.2376 40.7513 21.5726C40.5826 20.9076 40.5669 20.224 40.7044 19.5265C40.8414 18.8268 41.1393 18.1158 41.599 17.3922C42.057 16.6666 42.6881 15.9796 43.4903 15.3281C44.6698 14.3715 45.9068 13.7003 47.1995 13.3161C48.4925 12.9331 49.8951 12.8888 51.4097 13.1873L50.45 17.4296C49.5803 17.1777 48.7441 17.1127 47.9426 17.2343C47.1414 17.357 46.3399 17.7428 45.5377 18.3943C44.9012 18.9116 44.5357 19.4142 44.4418 19.9007C44.3483 20.3882 44.4354 20.7966 44.7032 21.1272C45.0767 21.5875 45.5709 21.7193 46.1852 21.524C46.7997 21.3299 47.6758 20.8969 48.815 20.226C50.5022 19.2666 51.9798 18.7185 53.2473 18.5794C54.5152 18.4414 55.637 18.9737 56.6142 20.1771C57.5809 21.368 57.9159 22.6975 57.6169 24.1614C57.3195 25.6273 56.3976 26.9857 54.8522 28.2409C53.3775 29.4383 51.9725 30.1958 50.6381 30.5168C49.3023 30.837 47.9871 30.8862 46.6928 30.6655Z"
                      fill="#8C93A0"
                    />
                    <path
                      d="M56.7535 10.2842L54.9804 7.12336L67.2636 0.231532L69.0366 3.39241L64.7434 5.80037L71.1112 17.1492L67.3936 19.2354L61.0269 7.88628L56.7535 10.2842Z"
                      fill="#8C93A0"
                    />
                  </svg>
                )}
            </div>
            <h1>
              {profileData.first_name}
              <br />
              {profileData.last_name}
            </h1>
          </div>
          <div className={styles.profile__top__desc}>
            <div className={styles.profile__top__social}>
              {profileData.website && (
                <a href={profileData.website} target="_blank" rel="noreferrer">
                  <WebsiteIcon />
                </a>
              )}
              {profileData.show_email && profileData.social_email && (
                <a href={"mailto:" + profileData.social_email}>
                  <EmailIcon />
                </a>
              )}
              {profileData.instagram && (
                <a
                  href={`https://www.instagram.com/${profileData.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              )}
              {profileData.linkedin && (
                <a href={profileData.linkedin} target="_blank" rel="noreferrer">
                  <LinkedinIcon />
                </a>
              )}
              {profileData.twitter && (
                <a
                  href={`http://twitter.com/${profileData.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              )}
            </div>
            <h3>{profileData.tagline}</h3>
            <p className={styles.profile__top__desc__info}>
              {profileData.pronouns && profileData.show_pronouns && (
                <span>{profileData.pronouns}</span>
              )}
              <span>
                {profileData.neighborhoods && profileData.neighborhoods[0]}
                {profileData.neighborhoods && profileData.community && ","}{" "}
                {profileData.community}
              </span>
              {profileData.industry && <span>{profileData.industry}</span>}
              {profileData.sign && <span>{profileData.sign}</span>}
            </p>
            <p className={styles.profile__top__desc__desc}>{profileData.bio}</p>
          </div>

          <div className={styles.profile__top__events}>
            {noRSVPs && (
              <div>
                <h3>Gatherings</h3>
                <div className={styles.profile__top__gatherings}>
                  {profileData.eventHosted &&
                    profileData.eventHosted.length > 0 && (
                      <h4>
                        <span>
                          {profileData.eventHosted.length > 9
                            ? profileData.eventHosted.length
                            : "0" + profileData.eventHosted.length}
                        </span>
                        <br />
                        Hosted
                      </h4>
                    )}
                  {profileData.eventRSVPS && profileData.eventRSVPS.length > 0 && (
                    <h4>
                      <span>
                        {profileData.eventRSVPS.length > 9
                          ? profileData.eventRSVPS.length
                          : "0" + profileData.eventRSVPS.length}
                      </span>
                      <br />
                      Attended
                    </h4>
                  )}
                </div>
              </div>
            )}
            {profileData.membership_offering &&
              profileData.membership_offering.length > 0 && (
                <div>
                  <h3>Bringing to the table</h3>
                  <p>
                    {profileData.membership_offering.map((item, i, arr) => {
                      if (arr.length - 1 === i) {
                        return <span key={i}>{item}</span>;
                      } else {
                        return <span key={i}>{`${item}, `}</span>;
                      }
                    })}
                  </p>
                </div>
              )}
          </div>
        </div>
        <div className={styles.profile__currently}>
          <h2>Currently</h2>
          <div className={styles.currently}>
            {profileData.currently_reading && (
              <div className={styles.currently__item}>
                <h3>Reading</h3>
                <p>{profileData.currently_reading}</p>
              </div>
            )}
            {profileData.currently_watching && (
              <div className={styles.currently__item}>
                <h3>Watching</h3>
                <p>{profileData.currently_watching}</p>
              </div>
            )}
            {profileData.currently_listening && (
              <div className={styles.currently__item}>
                <h3>Listening</h3>
                <p>{profileData.currently_listening}</p>
              </div>
            )}
            {profileData.currently_eating && (
              <div className={styles.currently__item}>
                <h3>Eating/Cooking</h3>
                <p>{profileData.currently_eating}</p>
              </div>
            )}
            {profileData.currently_instagram && (
              <div className={styles.currently__item}>
                <h3>Following</h3>
                <p>{profileData.currently_instagram}</p>
              </div>
            )}
            {profileData.currently_irl && (
              <div className={styles.currently__item}>
                <h3>Frequenting</h3>
                <p>{profileData.currently_irl}</p>
              </div>
            )}
          </div>
        </div>
        {profileData.eventHosted && profileData.eventHosted.length > 0 && (
          <EventsHosted
            first_name={profileData.first_name}
            eventHosted={profileData.eventHosted}
          />
        )}
        {profileData.eventRSVPS && profileData.eventRSVPS.length > 0 && (
          <EventsAttended
            first_name={profileData.first_name}
            eventRSVPS={profileData.eventRSVPS}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(UserProfile);
