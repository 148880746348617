import React, { createRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import cx from "classnames";
//--- Query
import { uploadImage } from "api/image";
//--- Styles
import styles from "components/Shared/ImageUpload/ImageUpload.module.scss";

function ImageUpload({
  imagePreview,
  imageSource,
  loading,
  setImagePreview,
  setImageSource,
}) {
  const inputRef = createRef();
  const canvasRef = createRef();
  const [imageCaptured, setImageCaptured] = useState(false);

  useEffect(() => {
    if (imagePreview) {
      console.log("yes", imagePreview);
      handleImageUrl(imagePreview, false);
    } else {
      console.log("no");
      handleImageUrl("/placeholder/placeholder-profile-image.png", true);
    }
  }, [handleImageUrl, imagePreview]);

  console.log({ imagePreview });

  async function handleImageUrl(url, shouldntLoad) {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const img = new Image();
    if (url.includes("https")) img.crossOrigin = "anonymous";
    img.onload = function () {
      context.clearRect(0, 0, 550, 550);
      context.drawImage(img, 0, 0, 550, 550);
      !shouldntLoad && setImageCaptured(true);
    };
    img.src = url;
  }

  async function uploadImageBlob(base64Image) {
    loading(true);
    try {
      const result = await uploadImage(base64Image);
      setImagePreview(result.document.url);
      setImageSource(result.document._id);
      return loading(false);
    } catch (e) {
      const res = await e.response.json();
      loading(false);
      console.warn("errors", res);
    }
  }

  function handleImage(file) {
    var reader = new FileReader();
    reader.onload = function (event) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const img = new Image();

      img.onload = function () {
        context.clearRect(0, 0, 550, 550);
        if (isMobile) {
          // if (!imageSource) {
          //   context.rotate(Math.PI / 2);
          //   context.translate(-50, -550);
          // }
          context.drawImage(img, 0, 0, 725, 550);
          // setImageSource(canvas.toDataURL());
          uploadImageBlob(canvas.toDataURL());
          setImageCaptured(true);
        } else {
          let imageHeight = img.height;
          let imageWidth = img.width;
          let hRatio = 550 / imageWidth;
          let vRatio = 550 / imageHeight;
          let ratio = Math.max(hRatio, vRatio);
          let centerShift_x = (550 - imageWidth * ratio) / 2;
          let centerShift_y = (550 - imageHeight * ratio) / 2;
          context.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            imageWidth * ratio,
            imageHeight * ratio
          );
          uploadImageBlob(canvas.toDataURL());
          setImageCaptured(true);
        }
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  function imageChange(e) {
    let fileList = e.target.files;
    let file = null;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }
    if (file !== null) {
      handleImage(file);
    }
  }

  function clearCanvas() {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, 550, 550);
    handleImageUrl("/placeholder/placeholder-profile-image.png", true);
    setImageCaptured(false);
  }

  function fileUploadToggle() {
    inputRef.current.click();
  }

  return (
    <div className={styles.imageUpload}>
      <div
        className={cx(styles.imageUpload__photo, {
          [styles.imageUpload__captured]: imageCaptured,
        })}
      >
        <input
          onChange={(e) => imageChange(e)}
          type="file"
          name="image"
          capture="user"
          accept="image/*"
          hidden
          ref={inputRef}
        />
        <canvas
          onClick={() => fileUploadToggle()}
          ref={canvasRef}
          width="550"
          height="550"
        />
      </div>

      <button
        className={styles.imageUpload__button}
        onClick={() => fileUploadToggle()}
      >
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="10.8334"
            y1="16"
            x2="10.8334"
            y2="2"
            stroke="#F5C38F"
            strokeWidth="2"
          />
          <path
            d="M6 7.0085L11 2.00293L16 7.0085"
            stroke="#F5C38F"
            strokeWidth="2"
          />
          <path
            d="M1 19.4829V22.0001H21V19.4829"
            stroke="#F5C38F"
            strokeWidth="2"
          />
        </svg>{" "}
        Upload a photo
      </button>

      {imageCaptured && (
        <button
          className={styles.imageUpload__button}
          onClick={() => clearCanvas()}
        >
          Clear Image
        </button>
      )}
    </div>
  );
}

export default ImageUpload;
