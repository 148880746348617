const Cities = [
  "Atlanta",
  "Austin",
  "Boston",
  "Chicago",
  "Los Angeles",
  "New York City",
  "Philadelphia",
  "San Francisco",
  "Toronto",
  "Washington D.C.",
  "Another city not listed here",
];

export default Cities;
