import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";

import { UpdateAccountPasswordSchema } from "utils/validationSchema";
import { updateUserPassword } from "api/user.js";
import { updateInfo } from "state/actions/userActions.js";

import styles from "components/EditAccount/EditAccount.module.scss";

import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";

function EditPassword(props) {
  const [buttonText, setButtonText] = useState("Update Password");
  const [hasUpdated, setHasUpdated] = useState(false);

  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password: "",
      }}
      validationSchema={UpdateAccountPasswordSchema}
      onSubmit={(values, actions) => {
        const { setFieldError, setSubmitting, resetForm } = actions;
        const { current_password, new_password } = values;
        const { email } = props.user;

        setButtonText("Updating...");

        updateUserPassword(email, current_password, new_password)
          .then(async function onSuccess() {
            setButtonText("Updated!");
            setSubmitting(false);
            setHasUpdated(true);
            resetForm();
          })
          .catch(async function onError(err) {
            setButtonText("Update Password");
            setSubmitting(false);
            console.warn(
              `%c PASSWORD err`,
              "color: #221; background: #F40;padding: 2px 8px; font-weight: bold",
              err
            );

            try {
              const { errors } = await err.response.json();
              const message = errors[0].details;

              if (message === "Wrong email or password.") {
                setFieldError("current_password", "Incorrect password");
              }
            } finally {
              setFieldError("new_password", "Could not change");
            }
          });
      }}
      render={({ errors, isSubmitting, dirty, values }) => {
        const disabled = isSubmitting || !dirty || !values.new_password;

        return (
          <Form className={styles.form}>
            <section>
              <h3>Change your password</h3>
              <Input
                label="Current Password"
                name="current_password"
                placeholder="*********"
                type="password"
              />
              <Input
                label="New Password"
                name="new_password"
                placeholder="*********"
                type="password"
              />
            </section>

            <FormButton
              label={disabled || !hasUpdated ? buttonText : "Update Again"}
              disabled={disabled}
            />
          </Form>
        );
      }}
    />
  );
}

export default EditPassword;
