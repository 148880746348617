import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";
import Timekeeper from "react-timekeeper";

import useOutsideClickListener from "utils/useOutsideClickListener";
import styles from "components/FormElements/FormElements.module.scss";

function TimePicker({ label, name, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useOutsideClickListener(containerRef, (clickedOutside) => {
    if (isOpen && clickedOutside) setIsOpen(false);
  });

  return (
    <Field name={name}>
      {({ field, form }) => {
        const error = form.touched[field.name] && form.errors[field.name];
        return (
          <div
            className={cx(styles.input, { [styles.input__error]: error })}
            ref={containerRef}
          >
            {label !== "" && <label htmlFor={name}>{label}</label>}
            <input
              aria-label={name}
              value={field.value}
              name={field.name}
              onBlur={field.onBlur}
              placeholder={placeholder}
              ref={inputRef}
              onFocus={() => setIsOpen(true)}
              readOnly
            />

            {isOpen && (
              <Timekeeper
                time={field.value}
                onChange={(value) => form.setFieldValue(name, value.formatted)}
                onDoneClick={() => setIsOpen(false)}
                switchToMinuteOnHourSelect
                closeOnMinuteSelect
                config={{
                  FONT_FAMILY: '"Beatrice-Regular", sans-serif',
                  TIME_SELECTED_COLOR: "#F5C38F",
                  DROPDOWN_SELECTED_COLOR: "#F5C38F",
                  CLOCK_WRAPPER_MERIDIEM_BACKGROUND_COLOR_SELECTED: "#F5C38F",
                  CLOCK_WRAPPER_MERIDIEM_TEXT_COLOR_SELECTED: "#FFF",
                  CLOCK_HAND_INTERMEDIATE_CIRCLE_BACKGROUND: "#F5C38F",
                  CLOCK_HAND_ARM: "#F5C38F",
                  CLOCK_HAND_CIRCLE_BACKGROUND: "#F5C38F",
                  CLOCK_NUMBER_COLOR: "#666",
                }}
              />
            )}

            {error && (
              <p className={styles.input__error__message}>
                {form.errors[field.name]}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
}

TimePicker.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default TimePicker;
