export function setCreateAccountContent (data) {
  return {
    type: 'CMS_CREATE_ACCOUNT',
    ...data
  }
}

export function setEventsContent (data) {
  return {
    type: 'CMS_EVENTS',
    ...data
  }
}

export function setOnboardingContent (data) {
  return {
    type: 'CMS_ONBOARDING',
    ...data
  }
}

export function setPerksContent (data) {
  return {
    type: 'CMS_PERKS',
    ...data
  }
}
