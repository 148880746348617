import React from "react";
import cx from "classnames";

import styles from "components/Shared/UpcomingPast/UpcomingPast.module.scss";

function UpcomingPast({ eventType, onclick, link, includeDraft }) {
  return (
    <div className={styles.upcomingPast}>
      {onclick && (
        <p
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "upcoming",
          })}
          onClick={() => onclick("upcoming")}
        >
          Upcoming
        </p>
      )}
      {link && (
        <a
          name="Upcoming Gatherings"
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "upcoming",
          })}
          href={`${link}upcoming`}
        >
          Upcoming
        </a>
      )}
      {includeDraft && onclick && (
        <p
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "draft",
          })}
          onClick={() => onclick("draft")}
        >
          Drafts
        </p>
      )}
      {includeDraft && link && (
        <a
          name="Draft Gatherings"
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "drafts",
          })}
          href={`${link}drafts`}
        >
          Drafts
        </a>
      )}
      {onclick && (
        <p
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "past",
          })}
          onClick={() => onclick("past")}
        >
          Past
        </p>
      )}
      {link && (
        <a
          name="Past Gatherings"
          className={cx(styles.upcomingPast__item, {
            [styles.upcomingPast__item__active]: eventType === "past",
          })}
          href={`${link}past`}
        >
          Past
        </a>
      )}
    </div>
  );
}

export default UpcomingPast;
