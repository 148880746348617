import React, { useMemo, useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";
//--- Utils
import parseDate from "utils/parseDate";
import parseDateGMT from "utils/parseDateGMT";
//--- Styles
import styles from "components/Events/Events.module.scss";
//--- Components
import Button from "components/Button";
import CancelledMessage from "components/Events/CancelledMessage";
import CongratsModal from "components/Events/CongratsModal";
import ErrorBanner from "components/Shared/ErrorBanner";
import GoogleCalButton from "components/Button/GoogleCalButton";
import HostEditBar from "components/Events/HostEditBar";
import Loading from "components/Loading";
import ProfileCard from "components/Shared/ProfileCard";
import ProfileCardAnswer from "components/Shared/ProfileCard/ProfileCardAnswer";
import ProfileCardMini from "components/Shared/ProfileCard/ProfileCardMini";
import RSVPModal from "components/Events/RSVPModal";
import RSVPTaskBar from "components/Events/RSVPTaskBar";
import UnpublishedEventBar from "components/Events/UnpublishedEventBar";
import WhatToExpect from "components/Events/WhatToExpect";

function SingleEvent({ eventData = {}, user }) {
  const [openRSVPModal, toggleRSVPModal] = useState(false);
  const [congratsModal, toggleCongratsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  //--- Set UI Data
  const {
    host,
    isHostViewing,
    isEventCancelled,
    isEventPublished,
    totalAttendees,
    waitlistRSVP,
    isOnWaitlist,
    startdate,
    enddate,
    startdateGMT,
    enddateGMT,
    isRSVPd,
    isVirtual,
  } = useMemo(() => {
    const host = eventData.host || {};
    const isHostViewing = host._id === user.user_id;
    const isEventCancelled = eventData.cancelled === true;
    const isEventPublished = eventData.published === true;
    const totalAttendees = eventData.attendees ? eventData.attendees.length : 0;
    const waitlistRSVP = totalAttendees >= eventData.attendees_limit;
    const waitlistData = eventData.waitlist ? eventData.waitlist : [];
    const isOnWaitlist = checkWaitlisted(waitlistData, user.user_id);
    const startdate = parseDate(
      eventData.start_date ? eventData.start_date : {}
    );
    const enddate = parseDate(eventData.end_date ? eventData.end_date : {});
    const startdateGMT = parseDateGMT(
      eventData.start_date ? eventData.start_date : {}
    );
    const enddateGMT = parseDateGMT(
      eventData.end_date ? eventData.end_date : {}
    );
    const isRSVPd = eventData.attendees
      ? checkRSVPd(eventData.attendees, user.user_id)
      : false;
    const isVirtual = eventData.venue_type === "Virtual";

    return {
      host,
      isHostViewing,
      isEventCancelled,
      isEventPublished,
      totalAttendees,
      waitlistRSVP,
      waitlistData,
      isOnWaitlist,
      startdate,
      enddate,
      startdateGMT,
      enddateGMT,
      isRSVPd,
      isVirtual,
    };
  }, [eventData, user]);

  const showRSVPButton =
    //--- Check if RSVP Button should be seen
    !isHostViewing &&
    !isRSVPd &&
    !isEventCancelled &&
    isEventPublished &&
    !isOnWaitlist
      ? true
      : false;
  const showInviteGuests = isRSVPd && eventData.canInviteGuests ? true : false;

  function checkRSVPd(attendees, userId) {
    for (let i = 0; i < attendees.length; i++) {
      if (
        attendees &&
        attendees[i].attendee &&
        attendees[i].attendee._id === userId
      ) {
        return true;
      }
    }
    return false;
  }

  function checkWaitlisted(waitlist, userId) {
    for (let i = 0; i < waitlist.length; i++) {
      if (waitlist && waitlist[i]._ref === userId) {
        return true;
      }
    }
    return false;
  }
  return (
    <div
      className={cx(styles.event, {
        [styles.event__isCancelled]: eventData.cancelled,
      })}
    >
      {loading && <Loading />}
      {errorMessage && <ErrorBanner message={errorMessage} />}
      {isHostViewing && !isEventCancelled && isEventPublished && (
        <HostEditBar
          eventId={eventData._id}
          setLoading={setLoading}
          setErrorMessage={setErrorMessage}
          canUnpublish={eventData.attendees.length === 0}
        />
      )}
      {isHostViewing && !isEventPublished && (
        <UnpublishedEventBar
          eventId={eventData._id}
          setLoading={setLoading}
          setErrorMessage={setErrorMessage}
        />
      )}
      {isRSVPd && !isEventCancelled && (
        <RSVPTaskBar
          hostFirstName={host && host.first_name}
          guestAllowed={eventData.guest_limit}
          eventId={eventData._id}
          hostNote={eventData.notes}
          eventName={eventData.name}
          setLoading={setLoading}
          showInviteGuests={showInviteGuests}
          remainingAttendees={
            eventData.attendees_limit - eventData.attendees.length
          }
        />
      )}
      {isEventCancelled && <CancelledMessage first_name={host.first_name} />}
      <CongratsModal
        modalOpen={congratsModal}
        closeModal={toggleCongratsModal}
        eventData={eventData}
        startdateGMT={startdateGMT}
        enddateGMT={enddateGMT}
        startdate={startdate}
        enddate={enddate}
        hostNote={eventData.notes}
        isWaitlist={waitlistRSVP}
      />
      <RSVPModal
        eventData={eventData}
        openRSVPModal={openRSVPModal}
        toggleRSVPModal={toggleRSVPModal}
        toggleCongratsModal={toggleCongratsModal}
      />

      <div className={styles.event__wrapper}>
        <div className={styles.event__image}>
          <div className={styles.event__image__wrapper}>
            {eventData.imageUrl && (
              <img
                src={`${eventData.imageUrl}?w=460&fit=max`}
                alt={eventData.name}
              />
            )}
            <div className={styles.event__image__type}>
              {eventData.type === "Book club" && (
                <img src="/icons/bookclub-icon-peach.svg" alt="book" />
              )}
              {eventData.type === "Cookbook club" && (
                <img src="/icons/cookbook-icon-peach.svg" alt="cook book" />
              )}
              {eventData.type !== "Cookbook club" &&
                eventData.type !== "Book club" && (
                  <img src="/icons/othergathering-icon-peach.svg" alt="sofa" />
                )}
            </div>
          </div>
        </div>
        <div className={styles.event__body}>
          <div className={styles.event__info__wrapper}>
            <section className={styles.event__info}>
              {!isVirtual && (
                <p className={styles.event__info__location}>
                  {eventData.community} - {eventData.type}
                </p>
              )}
              {isVirtual && (
                <p className={styles.event__info__location}>
                  <span className={styles.virtualFlag}>Virtual</span>{" "}
                  {eventData.type}
                </p>
              )}
              <h1>{eventData.name}</h1>
              {eventData.description && (
                <div className={styles.event__info__text}>
                  <p>{eventData.description}</p>
                </div>
              )}
              {eventData.amenities && eventData.amenities.length > 0 && (
                <WhatToExpect amenities={eventData.amenities} />
              )}
            </section>
          </div>
        </div>
        <div className={styles.event__details}>
          {host && (
            <ProfileCardMini
              first_name={host.first_name && host.first_name}
              last_name={host.last_name && host.last_name}
              blurb={host.tagline && host.tagline}
              membership="host"
              id={host._id}
              location={host.community && host.community}
              image={host.hostImage}
            />
          )}
          <div className={styles.event__details__wrapper}>
            <h3>
              Details<span></span>
            </h3>
            <p>
              {startdate.dayOfWeek}, {startdate.month} {startdate.day}
              <br />
              {`${startdate.hour}:${startdate.minutes}${startdate.ampm}`} -{" "}
              {`${enddate.hour}:${enddate.minutes}${enddate.ampm}`}
            </p>
            {isRSVPd && !isEventCancelled && !isVirtual && (
              <p>
                {eventData.address_1 && eventData.address_1}{" "}
                {eventData.address_2 && eventData.address_2}
                <br />
                {eventData.city}, {eventData.state} {eventData.zip}
              </p>
            )}
            {!isRSVPd && !isVirtual && (
              <p>
                {eventData.neighborhood}
                {eventData.neighborhood && eventData.community && `, `}
                {eventData.community}
              </p>
            )}

            {isVirtual && (
              <p style={{ textDecoration: "underline" }}>Online - Zoom</p>
            )}

            <p>
              {eventData.attendees_limit <= totalAttendees
                ? 0
                : eventData.attendees_limit - totalAttendees}{" "}
              of {eventData.attendees_limit} seats left
              <br />
              Free for members{" "}
              {eventData.guest_limit !== 0 &&
                ` +${eventData.guest_limit} guest`}
            </p>
            {showRSVPButton && (
              <Button
                onclick={toggleRSVPModal}
                onclickvalue={true}
                label={waitlistRSVP ? "Join Waitlist" : "RSVP"}
              />
            )}
            {isOnWaitlist && (
              <Button
                onclick={() => null}
                onclickvalue={""}
                label="On Waitlist"
                disabled={true}
              />
            )}
            {isRSVPd && (
              <GoogleCalButton
                name={eventData.name}
                address_1={eventData.address_1}
                address_2={eventData.address_2}
                city={eventData.city}
                state={eventData.state}
                zip={eventData.zip}
                eventId={eventData._id}
                startdateGMT={startdateGMT}
                enddateGMT={enddateGMT}
              />
            )}
            {(isRSVPd || isHostViewing) && isVirtual && (
              <Button label="Join Zoom" link={eventData.url} />
            )}
          </div>
        </div>
      </div>

      {isEventPublished && !isRSVPd && (
        <div className={styles.event__attendees}>
          {eventData.attendees.length > 0 && <h2>Meet your fellow Loungers</h2>}
          <div className={styles.event__attendees__wrapper}>
            {eventData.attendees &&
              eventData.attendees.map((attendee, i) => (
                <ProfileCard
                  id={attendee.attendee && attendee.attendee._id}
                  name={`${
                    attendee.attendee ? attendee.attendee.first_name : ""
                  } ${attendee.attendee ? attendee.attendee.last_name : ""}`}
                  blurb={attendee.attendee && attendee.attendee.tagline}
                  image={attendee.attendee && attendee.attendee.attendeeImage}
                  key={i}
                />
              ))}
          </div>
        </div>
      )}
      {isEventPublished && (isRSVPd || isHostViewing) && (
        <div className={styles.event__question}>
          <div className={styles.event__question__top}>
            <div
              className={styles.event__question__top__hostImage}
              style={{
                backgroundImage: `url('${
                  host.hostImage
                    ? host.hostImage + "?w=150&h=150&fit=max"
                    : "/placeholder/placeholder-profile-image.png"
                }')`,
              }}
            >
              <svg
                width="56"
                height="52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.588 22.316L5.09 11.504l2.77-.128.2 4.34 4.043-.186-.2-4.34 2.77-.127.497 10.812-2.77.128-.206-4.488-4.044.186.207 4.487-2.77.128zM23.098 24.06c-.86-.296-1.565-.693-2.114-1.19-.55-.498-.951-1.058-1.203-1.677a5.064 5.064 0 0 1-.362-1.999c.01-.713.14-1.429.387-2.148a6.318 6.318 0 0 1 1.041-1.908 5.194 5.194 0 0 1 1.573-1.332 4.948 4.948 0 0 1 2.01-.578c.741-.059 1.518.052 2.332.333.86.296 1.566.693 2.122 1.193.554.5.961 1.06 1.223 1.683.26.623.389 1.292.383 2.006a6.75 6.75 0 0 1-.38 2.151 6.622 6.622 0 0 1-1.032 1.927c-.44.564-.96 1.01-1.558 1.337a4.844 4.844 0 0 1-2.022.566c-.75.05-1.55-.071-2.4-.364zm.8-2.233c.804.277 1.503.22 2.098-.172.595-.39 1.055-1.058 1.38-2.002.328-.953.379-1.77.153-2.448-.226-.68-.731-1.154-1.516-1.425-.767-.264-1.456-.198-2.068.198-.612.396-1.08 1.065-1.405 2.009-.32.925-.376 1.727-.17 2.404.206.677.715 1.156 1.529 1.436zM31.37 25.286l2.882-.026a4.792 4.792 0 0 0 .274 1.718c.19.52.558 1.008 1.104 1.464.387.323.74.487 1.06.49a.947.947 0 0 0 .774-.348c.253-.303.259-.66.015-1.07-.243-.41-.663-.967-1.26-1.672a10.448 10.448 0 0 1-.93-1.232 3.96 3.96 0 0 1-.52-1.16 2.335 2.335 0 0 1 0-1.13c.092-.381.3-.764.623-1.151a3.03 3.03 0 0 1 1.044-.81c.403-.19.837-.285 1.3-.284.464.001.955.103 1.475.305.52.203 1.038.52 1.553.95.758.635 1.34 1.341 1.747 2.119.406.777.608 1.668.607 2.672l-2.829-.084a3.775 3.775 0 0 0-.187-1.626c-.177-.496-.524-.96-1.04-1.391-.41-.342-.775-.514-1.098-.513-.323.001-.573.107-.75.32-.248.295-.27.627-.07.995.202.369.587.875 1.157 1.518.822.958 1.356 1.834 1.603 2.625.246.793.045 1.576-.601 2.349-.64.766-1.447 1.144-2.42 1.136-.973-.008-1.955-.427-2.949-1.258-.947-.792-1.607-1.595-1.977-2.407a6.31 6.31 0 0 1-.587-2.5zM46.05 30.027l2.004-1.242 4.826 7.79-2.004 1.241-1.686-2.722-7.197 4.458-1.46-2.357 7.197-4.458-1.68-2.71z"
                  fill="#8C93A0"
                />
              </svg>
            </div>
            <h2>{host.first_name} Asks</h2>
          </div>
          {eventData.question_1 && <h3>"{eventData.question_1}"</h3>}
          <div className={styles.event__question__answers}>
            {eventData.attendees &&
              eventData.attendees.map((attendee, i) => (
                <ProfileCardAnswer
                  id={attendee.attendee && attendee.attendee._id}
                  name={`${attendee.attendee && attendee.attendee.first_name} ${
                    attendee.attendee && attendee.attendee.last_name
                  }`}
                  blurb={attendee.attendee && attendee.attendee.tagline}
                  image={attendee.attendee && attendee.attendee.attendeeImage}
                  answer={attendee.attendee && attendee.attendee_answer}
                  key={i}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(SingleEvent);
