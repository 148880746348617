import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "components/Button";
import styles from "components/Shared/Stripe/Stripe.module.scss";
import ErrorBanner from "components/Shared/ErrorBanner";

const stripeStyle = {
  base: {
    color: "#284C4A",
    fontFamily: '"Beatrice-Regular", sans-serif',
    fontSize: "18px",

    "::placeholder": {
      color: "#8C93A0",
      fontFamily: '"Beatrice-Regular", sans-serif',
    },
  },
  invalid: {
    color: "#284C4A",
  },
};

const CheckoutForm = ({
  user = {},
  buttonText,
  confirmationFunction,
  selectedProduct,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    // Use elements.getElement to get a reference to the mounted Element.
    const cardElement = elements.getElement(CardElement);
    // Create source element
    stripe
      .createSource(cardElement, {
        type: "card",
        currency: "usd",
        owner: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
        },
      })
      .then(function (result) {
        if (result.error) {
          return setError(error.message || "This card could not be processed");
        }
        return confirmationFunction(result);
      })
      .catch(function (error) {
        console.warn("Stripe error:", error);
        return setIsSubmitting(false);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.stripeWrapper}>
        <CardElement options={{ style: stripeStyle }} />
      </div>
      <Button
        label={buttonText}
        onclick={() => null}
        disabled={isSubmitting || selectedProduct === ""}
        additionalClass="button__center"
      />
      {error && <ErrorBanner message={error} />}
    </form>
  );
};

function StripeForm({
  confirmationFunction,
  selectedProduct,
  buttonText = "Submit",
  user,
  children,
}) {
  return (
    <>
      {children}
      <CheckoutForm
        buttonText={buttonText}
        confirmationFunction={confirmationFunction}
        selectedProduct={selectedProduct}
        user={user}
      />
    </>
  );
}

StripeForm.defaultProps = {
  buttonText: "Submit",
  confirmationFunction: () => null,
  selectedProduct: "",
  user: {},
};

StripeForm.props = {
  user: PropTypes.object.isRequired,
  confirmationFunction: PropTypes.func.isRequired,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  selectedProduct: PropTypes.string,
};

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(StripeForm);
