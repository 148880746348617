import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
//--- Styles
import styles from "components/Header/Header.module.scss";
//--- Components
import FullLoungeLogo from "components/Logos/FullLoungeLogo";
import Navigation from "components/Header/Navigation";

function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const loggedIn = props.user.access_token ? true : false;

  return (
    <header className={styles.header}>
      <div className={styles.header__wrapper}>
        <div className={styles.header__logo}>
          <a aria-label="home" href="/">
            <FullLoungeLogo
              fillColor={props.headerColor ? props.headerColor : "#284C4A"}
            />
          </a>
        </div>
        <Navigation
          menuOpen={menuOpen}
          loggedIn={loggedIn}
          user={props.user ? props.user : null}
          headerColor={props.headerColor}
          currentPath={props.location.pathname ? props.location.pathname : ""}
        />
        <div
          className={styles.header__menuicon}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen && (
            <span className={styles.navigation__closeicon}>
              <svg
                width="23"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  transform="rotate(45 -1.914 2.207)"
                  stroke="#DD5D39"
                  strokeWidth="2"
                  d="M0-1h28.284"
                />
                <path
                  transform="rotate(-45 27.473 8.787)"
                  stroke="#DD5D39"
                  strokeWidth="2"
                  d="M0-1h28.284"
                />
              </svg>
            </span>
          )}
          <svg
            width="20"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#DD5D39" strokeWidth="2" d="M0 1h20M0 9h20M0 17h20" />
          </svg>
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(withRouter(Header));
