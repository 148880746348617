export default function getISODate(time, eventDate) {
  const getHours = (ds) => {
    let hours = Number.parseInt(ds);
    if (ds.split(' ')[1] === 'pm') hours += 12;
    return hours;
  }
  const getMinutes = (ds) => {
    const minutes = ds.split(' ')[0].split(':')[1];
    return minutes;
  }
  let date = new Date(eventDate);
  date.setHours(getHours(time))
  date.setMinutes(getMinutes(time))

  return new Date(date).toISOString();
}