import React from "react";

import styles from "components/Shared/ProfileCard/ProfileCard.module.scss";

export default function ProfileCardAnswer({ id, name, blurb, image, answer }) {
  return (
    <a
      href={id ? `/user/${id}` : null}
      className={styles.profilecardAnswer}
      aria-label={`view ${name}'s profile`}
    >
      {image ? (
        <div
          className={styles.profilecardMini__image}
          style={{ backgroundImage: `url('${image}?w=150&h=150&fit=max')` }}
        ></div>
      ) : (
        <div
          className={styles.profilecardMini__image}
          style={{
            backgroundImage: `url('/placeholder/placeholder-profile-image.png')`,
          }}
        ></div>
      )}
      <h4>{name}</h4>
      {blurb && <p>{blurb}</p>}
      {answer && answer !== "" && (
        <div className={styles.profilecardAnswer__answer}>{answer}</div>
      )}
    </a>
  );
}
