import React from "react";

import styles from "components/Shared/Note/Note.module.scss";

export default function Note({ text }) {
  return (
    <div className={styles.note}>
      <p>{text}</p>
    </div>
  );
}
