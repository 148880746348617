import imageUrlBuilder from "@sanity/image-url";
import sanityClientFE from "utils/sanityClientFE";
import sanityClientFEPublic from "utils/sanityClientFEPublic";

export default function urlFor(source, isPublic) {
  const builder = imageUrlBuilder(
    isPublic ? sanityClientFEPublic : sanityClientFE
  );
  return builder.image(source);
}
