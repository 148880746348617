import * as Yup from "yup";

//--- Signup Validation

export const SignupNameSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
});

export const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
});

export const CreateAccountSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 characters minimum."),
});

//--- Update Account Validation

export const UpdateAccountInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  community: Yup.string()
    .notRequired()
    .min(2, "Too Short!"),
});

export const UpdateAccountAddressSchema = Yup.object().shape({
  address_1: Yup.string()
    .notRequired()
    .min(2, "Too Short!"),
  address_2: Yup.string().notRequired(),
  city: Yup.string()
    .notRequired()
    .min(2, "Too Short!"),
  state: Yup.string().notRequired(),
  zip: Yup.string()
    .notRequired()
    .min(5, "Only 5 digit zip codes")
    .max(10, "Enter a valid zipcode"),
});

export const UpdateAccountPasswordSchema = Yup.object().shape({
  current_password: Yup.string().test(
    "is-current-password-required",
    "You must enter your current password.",
    function(value) {
      return !this.parent.new_password || value;
    }
  ),
  new_password: Yup.string()
    .notRequired()
    .min(8, "Password is too short - should be 8 characters minimum.")
    .when("current_password", {
      is: (val) => !!val,
      then: Yup.string().notOneOf(
        [Yup.ref("current_password")],
        "Passwords must not match"
      ),
      otherwise: Yup.string().notRequired(),
    }),
});

export const UpdateAccountEmailSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email address"),
  current_password: Yup.string().when(["email"], {
    is: (email) => email,
    then: Yup.string().required("You must enter your current password."),
    otherwise: Yup.string().notRequired(),
  }),
});

//--- Create Events Validation

export const createEventBasic = Yup.object().shape({
  eventName: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  eventBlurb: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  eventDate: Yup.string().required("Required"),
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
});

export const createEventVenue = Yup.object().shape({
  venueName: Yup.string().required("Required"),
  neighborhood: Yup.string().required("Required"),
  address_1: Yup.string().required("Required"),
  city: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string()
    .min(5, "Only 5 digit zip codes")
    .max(10, "Enter a valid zipcode")
    .required("Required"),
});

//--- Onboarding Validation

export const shipping = Yup.object().shape({
  address_1: Yup.string()
    .required()
    .min(2, "Too Short!"),
  address_2: Yup.string().notRequired(),
  city: Yup.string()
    .required()
    .min(2, "Too Short!"),
  state: Yup.string().required(),
  zip: Yup.string()
    .min(5, "Only 5 digit zip codes")
    .max(10, "Enter a valid zipcode")
    .required("Required"),
});

export const onboardingBasic = Yup.object().shape({
  tagline: Yup.string().required(),
});

export const onboardingSocial = Yup.object().shape({
  twitter: Yup.string().notRequired(),
  instagram: Yup.string().notRequired(),
  linkedin: Yup.string().notRequired(),
  website: Yup.string().notRequired(),
  social_email: Yup.string()
    .notRequired()
    .email("Please enter a valid email address"),
});

//--- Add Guest Validation

export const AddGuestSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .required("Required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
});
