import React from "react";

import styles from "components/Announcements/Announcements.module.scss";

// import AnnouncementCard from "components/Announcements/AnnouncementCard";
import ArticleCard from "components/Article/ArticleCard";
import Pagination from "components/Shared/Pagination";

export default function Announcements(props) {
  const totalArticles = props.announcements && props.announcements[0].count;
  const currentPage = props.currentPage ? props.currentPage : "1";
  const perPage = props.perPage ? props.perPage : 10;

  return (
    <div className={styles.announcements}>
      <div className={styles.announcements__wrapper}>
        <div className={styles.announcements__header}>
          <h1>Announcements & Articles</h1>
        </div>
        <div className={styles.announcements__body}>
          {props.announcements &&
            props.announcements.map((item, i) => {
              const title = item.announcement_title
                ? item.announcement_title
                : item.article_title;
              const previewText = item.announcement_text_preview
                ? item.announcement_text_preview
                : item.article_text_preview;
              const image = item.announceImage
                ? item.announceImage
                : item.articleImage;

              return (
                <ArticleCard
                  _id={item._id}
                  key={i}
                  createdAt={item._createdAt}
                  type={item.article_type}
                  title={title}
                  previewText={previewText}
                  image={image}
                  updatedAt={item._updatedAt}
                />
              );
            })}
        </div>
        <div className={styles.announcements__paginationWrapper}>
          <Pagination
            totalItems={totalArticles}
            currentPage={Number(currentPage)}
            isOnDarkBg={false}
            itemsPerPage={Number(perPage)}
            baseLink="announcements?"
            additionalItemStyles="pagination__extraItem"
            additionalArrowStyles="pagination__extraArrow"
          />
        </div>
      </div>
    </div>
  );
}
