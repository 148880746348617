import React, { useState } from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
//--- Queries & Actions
import { login } from "api/auth.js";
// import { getUser, getStripeSubscription, updateUser } from "api/user.js";
import { getUser } from "api/user.js";
import { setCurrentUser } from "state/actions/userActions.js";
//--- Utils
import { isAuthenticated } from "utils/isAuthenticated";
//--- Styles
import styles from "containers/Login/Login.module.scss";
//--- Components
import ErrorBanner from "components/Shared/ErrorBanner";
import ForgotPassword from "containers/Login/ForgotPassword";
import FormButton from "components/Button/FormButton";
import Input from "components/FormElements/Input";
import FullLoungeLogo from "components/Logos/FullLoungeLogo";

function Login(props) {
  const [generalError, setGeneralError] = useState("");
  const [forgotPassModal, toggleForgotPassModal] = useState(false);

  function triggerForgotPassword(toggleVal) {
    toggleForgotPassModal(toggleVal);
  }

  if (isAuthenticated()) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className={styles.login}>
        {forgotPassModal && (
          <ForgotPassword triggerForgotPassword={triggerForgotPassword} />
        )}
        <div className={styles.login__wrapper}>
          <div className={styles.login__sidebar}>
            <img
              src="/icons/Lounge_Login.png"
              alt="Women sitting around drinking tea"
            />
          </div>
          <div className={styles.login__body}>
            <div className={styles.login__body__wrapper}>
              <FullLoungeLogo center fillColor="#F5C38F" />
              <p>Your cozy corner of the internet</p>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={async (values, actions) => {
                  const { email, password } = values;
                  actions.setSubmitting(true);

                  try {
                    const { errors, ...user } = await login({
                      email,
                      password,
                    });

                    const sanityUser = await getUser(user.user_id);
                    await props.dispatch(
                      setCurrentUser({
                        ...user,
                        ...sanityUser[0],
                        email,
                      })
                    );

                    if (
                      sanityUser &&
                      sanityUser[0] &&
                      sanityUser[0].subscription_status === "pending"
                    ) {
                      await props.history.push({ pathname: `/signup` });
                      // } else if (
                      //   // Check if user is cancelled or delinquent and forward accordingly
                      //   sanityUser &&
                      //   sanityUser[0] &&
                      //   (sanityUser[0].subscription_status === "cancelled" ||
                      //     sanityUser[0].subscription_status === "delinquent")
                      // ) {
                      //   await props.history.push({
                      //     pathname: `/update-membership`,
                      //   });
                    } else {
                      await props.history.push({ pathname: `/dashboard` });
                    }

                    if (errors) {
                      console.warn(errors);
                      actions.setSubmitting(false);
                      setGeneralError(errors);
                    }
                  } catch (e) {
                    let res = await e.response;
                    if (res) {
                      res = res ? res.json() : res;
                      setGeneralError("Unauthorized");
                      actions.setSubmitting(false);
                    } else {
                      setGeneralError("Error Logging In");
                      actions.setSubmitting(false);
                    }
                  }
                }}
                render={({ isSubmitting }) => (
                  <Form>
                    {generalError !== "" && (
                      <ErrorBanner message={generalError} />
                    )}
                    <Input type="email" label="Email Address" name="email" />
                    <Input type="password" name="password" label="Password" />
                    <FormButton
                      label="Log In"
                      disabled={isSubmitting}
                      additionalClass="button__peach"
                    />
                  </Form>
                )}
              />
              <p>
                <a onClick={() => triggerForgotPassword(true)}>
                  Forgot your password?
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(Login));
