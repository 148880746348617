import React, { useState, useEffect } from "react";

import styles from "components/Perks/Perks.module.scss";
import cx from "classnames";

function Perks({ pageData }) {
  const [cmsData, setCmsData] = useState({});
  const [perksData, setPerksData] = useState([]);
  const [modal, setModal] = useState(<></>);

  useEffect(() => {
    let perksContent = pageData;
    for (let i = 0; i < perksContent.length; i++) {
      if (
        perksContent[i]._id === "perksContent" ||
        perksContent[i]._id === "drafts.perksContent"
      ) {
        setCmsData(perksContent[i]);
        perksContent.splice(i, 1);
      }
    }
    setPerksData(perksContent);
  }, []);

  return (
    <div className={styles.perks}>
      <div className={styles.perks__wrapper}>
        <div className={styles.perks__header}>
          <div className={styles.perks__header__title}>
            <h1>{cmsData.title}</h1>
          </div>
          <div className={styles.perks__header__content}>
            <p>{cmsData.text}</p>
          </div>
        </div>
        <div className={styles.perks__cards}>
          {perksData &&
            perksData.map((perk, i) => (
              <div className={styles.perks__card} key={i}>
                <div
                  className={styles.perks__card__image}
                  style={{
                    backgroundImage: `url('${perk.perkImage}?w=1080&fit=max')`,
                  }}
                />
                <div className={styles.perks__card__body}>
                  <h2>{perk.brand_name}</h2>
                  <p className={styles.perks__card__subTitle}>OUR TAKE</p>
                  <p className={styles.perks__card__content}>
                    {perk.brand_description}
                  </p>
                </div>
                <div className={styles.perks__card__buttons}>
                  <div
                    className={styles.perks__card__buttons__popup}
                    onClick={() => {
                      setModal(
                        <div className={styles.perks__modal}>
                          <div className={styles.perks__modal__body}>
                            <div className={styles.perks__modal__body__wrapper}>
                              <div
                                className={styles.perks__modal__cross}
                                onClick={() => {
                                  setModal(<></>);
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.8333 1.34169L10.6583 0.166687L5.99996 4.82502L1.34163 0.166687L0.166626 1.34169L4.82496 6.00002L0.166626 10.6584L1.34163 11.8334L5.99996 7.17502L10.6583 11.8334L11.8333 10.6584L7.17496 6.00002L11.8333 1.34169Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <div className={styles.perks__modal__brandLogo}>
                                <img
                                  src={`${perk.perkLogo}?w=490&fit=max`}
                                  alt={perk.brand_name}
                                />
                              </div>
                              <div className={styles.perks__modal__subTitle}>
                                Your Exclusive Perk
                              </div>
                              <div className={styles.perks__modal__content}>
                                <div className={styles.perks__modal__info}>
                                  {perk.perk_info}
                                </div>
                                <div
                                  className={styles.perks__modal__button}
                                  onClick={() => {
                                    setModal(<></>);
                                  }}
                                >
                                  Got it
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.perks__modal__opacityLayer}
                            onClick={() => {
                              setModal(<></>);
                            }}
                          />
                        </div>
                      );
                    }}
                  >
                    <svg
                      width="21"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.252 6.299C20.075 6.042 15.841 0 10.174 0 4.506 0 .272 6.042.094 6.299a.535.535 0 0 0 0 .608c.178.257 4.412 6.3 10.08 6.3 5.667 0 9.9-6.043 10.078-6.3a.535.535 0 0 0 0-.608zm-10.078 5.016a4.711 4.711 0 1 1 0-9.424 4.712 4.712 0 0 1 0 9.424z"
                        fill="#284C4A"
                      />
                      <path
                        d="M10.174 3.82a2.784 2.784 0 1 0 0 5.568 2.784 2.784 0 0 0 0-5.568z"
                        fill="#284C4A"
                      />
                    </svg>
                    See Perk
                  </div>
                  <a
                    className={styles.perks__card__buttons__viewSite}
                    href={perk.site_link}
                  >
                    View Site
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
                        fill="#F4C292"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
      {modal}
    </div>
  );
}

export default Perks;
