import React, { useState } from "react";
import cx from "classnames";
//--- Styles
import styles from "components/Header/Header.module.scss";
//--- Components
import FullLoungeLogo from "components/Logos/FullLoungeLogo";
import MembershipNavigation from "components/Header/MembershipNavigation";

export default function MembershipHeader(props) {
  // const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header className={cx(styles.header, styles.header__membership)}>
      <div className={styles.header__wrapper}>
        <div
          className={cx(styles.header__logo, styles.header__logo__membership)}
        >
          {/* <a aria-label="home" href="/"> */}
          <FullLoungeLogo
            fillColor={props.headerColor ? props.headerColor : "#F9F3EB"}
          />
          {/* </a> */}
        </div>
        <div className={styles.header__nav}>
          {/* <MembershipNavigation
            menuOpen={menuOpen}
            headerColor={props.headerColor}
            currentPath={
              props.location && props.location.pathname
                ? props.location.pathname
                : ""
            }
            isAuthenticated={props.isAuthenticated}
          /> */}
        </div>

        {/* <div
          className={styles.header__menuicon}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen && (
            <span className={styles.navigation__closeicon}>
              <svg
                width="23"
                height="22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  transform="rotate(45 -1.914 2.207)"
                  stroke="#DD5D39"
                  strokeWidth="2"
                  d="M0-1h28.284"
                />
                <path
                  transform="rotate(-45 27.473 8.787)"
                  stroke="#DD5D39"
                  strokeWidth="2"
                  d="M0-1h28.284"
                />
              </svg>
            </span>
          )}
          <svg
            width="20"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#DD5D39" strokeWidth="2" d="M0 1h20M0 9h20M0 17h20" />
          </svg>
        </div> */}
      </div>
    </header>
  );
}
