import React from "react";
import PropTypes from "prop-types";

import styles from "components/Button/Button.module.scss";

function FormButton({ label, additionalClass, disabled }) {
  return (
    <div
      className={`
        ${styles.button} 
        ${additionalClass ? styles[additionalClass] : ""}
      `}
    >
      <button type="submit" disabled={disabled ? true : false}>
        {label}
      </button>
    </div>
  );
}

FormButton.propTypes = {
  label: PropTypes.string,
  additionalClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormButton;
