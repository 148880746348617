import React, { useState } from "react";
import cx from "classnames";
//--- Queries & Actions
import { adminUpdateUser } from "api/user";
//--- Utils
import formatDate from "utils/formatDate";
//--- Styles
import styles from "components/Admin/Admin.module.scss";
//--- Components
import AddHost from "components/Admin/AddHost";
import Button from "components/Button";
import Loading from "components/Loading";
import Pagination from "components/Shared/Pagination";

export default function Admin({ data, currentPage, perPage }) {
  const totalUsers = data[0] ? data[0].userCount : 0;
  const [loading, setLoading] = useState(false);
  const [addHostModal, addHostModalOpen] = useState(false);

  function setUserRole(roleArray) {
    if (roleArray.includes("admin")) {
      return "admin";
    } else if (roleArray.includes("host")) {
      return "host";
    } else {
      return "member";
    }
  }

  async function setMemberRole(e, user_id) {
    setLoading(true);
    const newRole = e.currentTarget.value;
    let rolesArray = [];
    if (newRole === "member") {
      rolesArray = ["member"];
    } else if (newRole === "host") {
      rolesArray = ["member", "host"];
    } else if (newRole === "admin") {
      rolesArray = ["member", "host", "admin"];
    }

    try {
      const result = await adminUpdateUser(user_id, { user_roles: rolesArray });
      setLoading(false);
    } catch (e) {
      const res = await e.response.json();
      console.warn("ERROR: Admin update roles", res);
      setLoading(false);
    }
  }

  async function setSubscriptionStatus(user_id, newStatus, rolesArray) {
    setLoading(true);
    try {
      const result = await adminUpdateUser(user_id, {
        subscription_status: newStatus,
        user_roles: rolesArray,
      });
      window.location = "/admin";
      setLoading(false);
    } catch (e) {
      const res = await e.response.json();
      console.warn("ERROR: Admin update roles", res);
      setLoading(false);
    }
  }

  return (
    <div className={styles.admin}>
      {loading && <Loading />}
      <h1>Manage Members</h1>
      <div className={styles.admin__addHost}>
        <Button
          onclick={addHostModalOpen}
          onclickvalue={true}
          label="Add Host"
        />
      </div>
      {addHostModal && (
        <AddHost setLoading={setLoading} modalOpen={addHostModalOpen} />
      )}
      <div className={cx(styles.admin__row, styles.admin__row__top)}>
        <p>Member</p>
        <p>Role/Status</p>
        <p>City</p>
        <p>Member Since</p>
        <p># Attended</p>
        <p>Manage Membership</p>
      </div>
      {data &&
        data.map((member, i) => {
          let joinDate = formatDate(member._createdAt);
          let defaultRole = setUserRole(member.user_roles);

          return (
            <div className={styles.admin__row} key={i}>
              <div className={styles.admin__row__user}>
                <a
                  href={`/user/${member._id}`}
                  className={styles.admin__member}
                >
                  {member.first_name && member.first_name}{" "}
                  {member.last_name && member.last_name}
                </a>
              </div>
              <div>
                <select
                  className={styles.admin__role}
                  defaultValue={defaultRole}
                  onChange={(e) => {
                    setMemberRole(e, member._id);
                  }}
                >
                  <option>member</option>
                  <option>host</option>
                  <option>admin</option>
                </select>
              </div>
              <p className={styles.admin__city}>{member.community}</p>
              <p className={styles.admin__joinDate}>{joinDate}</p>
              <p className={styles.admin__eventsAttended}>
                {member.eventRSVPS ? member.eventRSVPS.length : 0}
              </p>
              <div className={styles.admin__manage}>
                {member.subscription_status === "paused" && (
                  <a
                    onClick={() =>
                      setSubscriptionStatus(
                        member._id,
                        "active",
                        member.user_roles
                      )
                    }
                  >
                    Reactivate Membership
                  </a>
                )}
                {member.subscription_status === "suspended" && (
                  <a
                    onClick={() =>
                      setSubscriptionStatus(
                        member._id,
                        "active",
                        member.user_roles
                      )
                    }
                  >
                    Unsuspend User Membership
                  </a>
                )}
                {member.subscription_status === "active" && (
                  <a
                    onClick={() =>
                      setSubscriptionStatus(
                        member._id,
                        "paused",
                        member.user_roles
                      )
                    }
                  >
                    Deactivate Membership
                  </a>
                )}
              </div>
            </div>
          );
        })}
      <Pagination
        totalItems={totalUsers}
        currentPage={Number(currentPage)}
        itemsPerPage={Number(perPage)}
        baseLink="admin?"
      />
    </div>
  );
}
