const DCNeighborhoods = [
'Adams Morgan',
'Anacostia',
'Arboretum',
'Bloomingdale',
'Brentwood',
'Brookland',
'Cathedral Heights',
'Capitol Hill',
'Capitol Waterfront',
'Central Northeast',
'Chevy Chase',
'Chinatown/Penn Quarter',
'Cleveland Park',
'Columbia Heights',
'Congress Heights',
'Downtown',
'Dupont',
'Douglas Park',
'Eastern Market',
'Eckington',
'Edgewood',
'Fairfax Village',
'Federal Triangle',
'Foggy Bottom',
'Fort Lincoln',
'Fort Totten',
'Friendship Heights',
'Georgetown',
'Glover Park',
'H Street Corridor',
'Ivy City',
'Judiciary Square',
'Kalorama',
'Ledroit Park',
'Logan Circle',
'Mayfair',
'Metro Center',
'Michigan Park',
'Mount Pleasant',
'Mount Vernon Square',
'Navy Yard',
'Noma',
'Park View',
'Penn Quarter',
'Petworth',
'Pleasant Plains',
'Shaw',
'Southwest Waterfront & The Wharf',
'16th Street Heights',
'Takoma',
'Tenleytown',
'Trinidad',
'Truxton Circle',
'Union Station',
'U Street Corridor',
'West End',
'Woodland',
'Woodley Park'
];

export default DCNeighborhoods;