import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

function SignupRoute ({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => {
      return rest.user.access_token && rest.user.subscription_status == 'active' ? (
        <Redirect to={{
          pathname: '/dashboard',
          state: { from: props.location }
        }} />
      ) : (
        <Component {...props} />
      )
    }} />
  );
}

export default connect(state => ({ user: state.user }))(SignupRoute);
