import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "formik";

import styles from "components/FormElements/FormElements.module.scss";

function StateSelector({ error, label, name, placeholder, type }) {
  return (
    <div className={cx(styles.input, { [styles.input__error]: error })}>
      {label !== "" && <label htmlFor={name}>{label}</label>}
      <div className={styles.input__select}>
        <svg
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L7.5 7L14 0.999999" stroke="#284C4A" strokeWidth="2" />
        </svg>
        <Field name={name} component="select" aria-label={"Select State"}>
          <option value="" label="Select State" disabled>
            Select State
          </option>
          <option value="AL" label="Alabama">
            Alabama
          </option>
          <option value="AK" label="Alaska">
            Alaska
          </option>
          <option value="AZ" label="Arizona">
            Arizona
          </option>
          <option value="AR" label="Arkansas">
            Arkansas
          </option>
          <option value="CA" label="California">
            California
          </option>
          <option value="CO" label="Colorado">
            Colorado
          </option>
          <option value="CT" label="Connecticut">
            Connecticut
          </option>
          <option value="DE" label="Delaware">
            Delaware
          </option>
          <option value="DC" label="Washington D.C.">
            Washington D.C.
          </option>
          <option value="FL" label="Florida">
            Florida
          </option>
          <option value="GA" label="Georgia">
            Georgia
          </option>
          <option value="HI" label="Hawaii">
            Hawaii
          </option>
          <option value="ID" label="Idaho">
            Idaho
          </option>
          <option value="IL" label="Illinois">
            Illinois
          </option>
          <option value="IN" label="Indiana">
            Indiana
          </option>
          <option value="IA" label="Iowa">
            Iowa
          </option>
          <option value="KS" label="Kansas">
            Kansas
          </option>
          <option value="KY" label="Kentucky">
            Kentucky
          </option>
          <option value="LA" label="Louisiana">
            Louisiana
          </option>
          <option value="MA" label="Massachusetts">
            Massachusetts
          </option>
          <option value="ME" label="Maine">
            Maine
          </option>
          <option value="MD" label="Maryland">
            Maryland
          </option>
          <option value="MI" label="Michigan">
            Michigan
          </option>
          <option value="MN" label="Minnesota">
            Minnesota
          </option>
          <option value="MS" label="Mississippi">
            Mississippi
          </option>
          <option value="MO" label="Missouri">
            Missouri
          </option>
          <option value="MT" label="Montana">
            Montana
          </option>
          <option value="NE" label="Nebraska">
            Nebraska
          </option>
          <option value="NV" label="Nevada">
            Nevada
          </option>
          <option value="NH" label="New Hampshire">
            New Hampshire
          </option>
          <option value="NJ" label="New Jersey">
            New Jersey
          </option>
          <option value="NM" label="New Mexico">
            New Mexico
          </option>
          <option value="NY" label="New York">
            New York
          </option>
          <option value="NC" label="North Carolina">
            North Carolina
          </option>
          <option value="ND" label="North Dakota">
            North Dakota
          </option>
          <option value="OH" label="Ohio">
            Ohio
          </option>
          <option value="OK" label="Oklahoma">
            Oklahoma
          </option>
          <option value="OR" label="Oregon">
            Oregon
          </option>
          <option value="PA" label="Pennsylvania">
            Pennsylvania
          </option>
          <option value="RI" label="Rhode Island">
            Rhode Island
          </option>
          <option value="SC" label="South Carolina">
            South Carolina
          </option>
          <option value="SD" label="South Dakota">
            South Dakota
          </option>
          <option value="TN" label="Tennessee">
            Tennessee
          </option>
          <option value="TX" label="Texas">
            Texas
          </option>
          <option value="UT" label="Utah">
            Utah
          </option>
          <option value="VT" label="Vermont">
            Vermont
          </option>
          <option value="VA" label="Virginia">
            Virginia
          </option>
          <option value="WA" label="Washington">
            Washington
          </option>
          <option value="WV" label="West Virgina">
            West Virgina
          </option>
          <option value="WI" label="Wisconsin">
            Wisconsin
          </option>
          <option value="WY" label="Wyoming">
            Wyoming
          </option>
        </Field>
      </div>
    </div>
  );
}

StateSelector.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default StateSelector;
