import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
//--- Queries & Actions
import { updateInfo } from "state/actions/userActions.js";
//--- Styles
import styles from "components/SignupFlow/SignupFlow.module.scss";
//--- Components
import AutocompleteInput from "components/FormElements/AutocompleteInput";
import Checkbox from "components/FormElements/Checkbox";
import FormButton from "components/Button/FormButton";
import SideBanner from "components/Shared/SideBanner";
//--- Autocomplete Data
import DCNeigborhoods from "components/Shared/AutocompleteData/DCNeighborhoods";
import NYCCNeigborhoods from "components/Shared/AutocompleteData/NYC";

function Neighborhood(props) {
  const [community, selectCommunity] = useState("");
  const [listValue, setListValue] = useState([]);

  function changeCommunity(val) {
    selectCommunity(val);
    if (val === "Washington D.C.") {
      return setListValue(DCNeigborhoods);
    } else if (val === "New York City") {
      return setListValue(NYCCNeigborhoods);
      //--- When more cities open up uncomment code below:
      // } else if (val = 'Los Angeles') {
      //   return setListValue(LANeigborhoods);
      // } else if (val = 'Chicago') {
      //   return setListValue(ChiNeigborhoods);
      // } else if (val = 'Toronto') {
      //   return setListValue(TorontoCNeigborhoods);
    } else {
      //--- Hide Neighborhood Input
      return setListValue([]);
    }
  }

  useEffect(() => {
    props.user.community && changeCommunity(props.user.community);
  }, []);

  return (
    <div className={styles.signup}>
      <div className={styles.signup__wrapper}>
        <SideBanner
          title={
            props.cmsData.neighborhood_title
              ? props.cmsData.neighborhood_title
              : props.cmsData.location_title && props.cmsData.location_title
          }
          text={
            props.cmsData.neighborhood_subtitle
              ? props.cmsData.neighborhood_subtitle
              : "We’re so excited to have you as a member of The Lounge – but first, we need to know a little bit more about you to create the best experience. "
          }
        />
        <div className={styles.signup__form}>
          <div className={styles.signup__locations}>
            <Formik
              initialValues={{
                neighborhood: props.user.neighborhoods
                  ? props.user.neighborhoods[0]
                  : "",
                show_neighborhoods: props.user.show_neighborhoods
                  ? props.user.show_neighborhoods
                  : true,
              }}
              onSubmit={async (values, actions) => {
                const { neighborhood, show_neighborhoods } = values;
                props.dispatch(
                  updateInfo({
                    ...props.user,
                    neighborhoods: [neighborhood],
                    show_neighborhoods,
                  })
                );
                return props.updateFn(props.updateValue);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                  <h2>
                    {props.cmsData.location__neighborhood_question &&
                      props.cmsData.location__neighborhood_question}
                  </h2>
                  <p className={styles.signup__locations__labelText}>
                    {props.cmsData.location__neighborhood_text &&
                      props.cmsData.location__neighborhood_text}
                  </p>
                  <AutocompleteInput
                    initialValue={
                      props.user.neighborhoods && props.user.neighborhoods[0]
                        ? props.user.neighborhoods[0]
                        : ""
                    }
                    label=""
                    name="neighborhood"
                    placeholder="Enter Neighborhood"
                    type="text"
                    listValues={listValue}
                  />
                  <Checkbox
                    name="show_neighborhoods"
                    label="Show my neighborhood on my profile"
                  />
                  <FormButton label="Continue" disabled={isSubmitting} />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Neighborhood);
